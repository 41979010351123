import {Button, Form, Icon, Table} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";
import appStore from "_/stores";


/**
 * @component
 * @description Displays Amazon Advertising account info;s allows user to link Ad account to Ad Astronaut
 */
const AmazonAdContainer = () => {
    const { settingsStore, userStore } = appStore;
    
    const handleClick = () => {
        settingsStore.registerWithAmazonAd();
    };

    const buildRegionRows = () => {
        return Object.entries(userStore.user.regions).map(([region, marketplaces]) => {
            const countries = marketplaces.filter(marketplace => {
                return marketplace.api === "amazon_ad";
            }).map(filtered_marketplace => {
                return filtered_marketplace.country;
            }).join(", ");
            return (
                <Table.Row>
                    <Table.Cell>{region}</Table.Cell>
                    <Table.Cell>{countries}</Table.Cell>
                </Table.Row>
            );
        });
    };
    
    return (
        <Form>
            <Form.Field>
                <label>Current Regions</label>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Region</Table.HeaderCell>
                            <Table.HeaderCell>Marketplaces</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {buildRegionRows()}
                    </Table.Body>
                </Table>

                {/*<Button type="button" fluid primary onClick={handleClick}>*/}
                {/*    <Icon name="plus" /> Add Marketplace Region*/}
                {/*</Button>*/}
            </Form.Field>
        </Form>
    );
};

export default observer(AmazonAdContainer);
