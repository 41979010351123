/* global BASE_URL */

class API {
    constructor() {
        this.csrftoken = null;

        const fetchData = (uri, requestType = "GET", data, withCors = true) => {
            // default fetch options
            uri = `${BASE_URL}/${uri}`;
            const init = withCors ? {
                method: requestType,
                credentials: "include",
                mode: "cors",
                headers: {"Content-Type": "application/json"},
            } : {
                method: requestType,
                headers: {"Content-Type": "application/json"},
            };

            init.redirect = "follow";

            // add data if it exists
            if (data) {
                // console.log(data, "this data is passed from dashbaord");
                init.body = JSON.stringify(data);
            }

            // add csrftoken header for some request types
            if (withCors && ["POST", "PUT", "PATCH", "DELETE"].indexOf(requestType) >= 0) {
                init.headers["X-CSRFTOKEN"] = this.csrftoken;
            }

            // fetch data
            return fetch(uri, init)
                .then((response) => {
                    this.csrftoken = response.headers.get("X-CSRFTOKEN");

                    // throw error if response is not ok
                    if (!response.ok) {
                        throw response;
                    }

                    // throw error if response data is not in JSON format
                    const contentType = response.headers.get("content-type");
                    if (!contentType || !contentType.includes("application/json")) {
                        throw new TypeError("Returned data is not in JSON format.");
                    }
                    if (response.status === 204) {
                        return response;
                    }
                    // return a Promise of response data in JSON format
                    return response.json();
                });
        };

        this.user = {
            checkAuth: () => {
                return fetchData("users/auth", "GET");
            },
            login: (credentials) => {
                return fetchData("users/login", "POST", credentials);
            },
            logout: () => {
                return fetchData("users/auth", "DELETE");
            },
            register: (user) => {
                return fetchData("users/register", "POST", user);

            },
            resetPasswordEmail: (data) => {
                return fetchData("users/resetpasswordlink", "POST", data);
            },
            resetPassword: (data) => {
                return fetchData("users/resetpassword", "POST", data);
            },
            googleAuth: () => {
                return fetchData("users/googleauth", "GET");
            },
            oauthCallback: (resp) => {
                ``;
                return fetchData("users/oauthcallback", "POST", resp);
            },
            shopifyAuth: (store) => {
                return fetchData(`users/shopifyoauth?store=${store}`, "GET");
            },
            amazonAdAuth: (region) => {
                return fetchData(`users/amazonadoauth?region=${region}`, "GET");
            },
            amazonSPAuth: () => {
                return fetchData(`users/amazonspoauth`, "GET");
            },
            getActivationProcess: () => {
                return fetchData("users/activationprocess", "GET");
            },
            activateNewUser: () => {
                return fetchData("users/activationprocess", "PUT");
            },
            deleteAccount: () => {
                return fetchData("users/deleteaccount", "GET");
            },
            updatePassword: (data) => {
                return fetchData("users/updatepassword", "POST", data);
            },
            updateEmail: (data) => {
                return fetchData("users/updateemail", "POST", data);
            },
            saveNewEmail: (data) => {
                return fetchData("users/savenewemail", "POST", data);
            },
            updateName: (data) => {
                return fetchData("users/updatename", "POST", data);
            },


        };

        this.softwarepartner = {
            register: (user) => {
                return fetchData("softwarepartner/register-partner", "POST", user);
            },
        };



        this.account = {
            getAccount: () => {
                return fetchData("users/auth", "GET");
            },
        };

        this.subscription = {
            checkoutSession: (resp) => {
                return fetchData("subscriptions/checkoutsession", "POST", resp);
            },
            paymentIntent: (resp) => {
                return fetchData("subscriptions/paymentintent", "POST", resp);
            },
            paymentKey: () => {
                return fetchData("subscriptions/paymentkey", "GET");
            },
            customer: (resp) => {
                return fetchData("subscriptions/customer", "POST", resp);
            },
            updatePaymentMethod: (resp) => {
                return fetchData("subscriptions/paymentmethod", "POST", resp);
            },
            getCard: () => {
                return fetchData("subscriptions/card", "GET");
            },
            // upgrade plan api
            upgradePlan: () => {
                return fetchData("subscriptions/upgradeplan", "GET");
            },
            cancelSubscription: () => {
                return fetchData("subscriptions/cancelsubscription", "GET");
            },
            checkStripeCustomer: () => {
                return fetchData("subscriptions/checkstripecustomer", "GET");
            },
            getqualifiedplan: () => {
                return fetchData("subscriptions/getqualifiedplan", "GET");
            },
            reactivePlan:() => {
                return fetchData("subscriptions/reactiveplan", "GET");
            },
        };

        this.marketplace = {
            getAmazonAdCampaigns: () => {
                return fetchData("marketplaces/amazon-ad-list-campaigns", "GET");
            },
            getAmazonAdGroups: () => {
                return fetchData("marketplaces/amazon-ad-list-ad-groups", "GET");
            },
            getExports: () => {
                return fetchData("marketplaces/export", "GET", );
            },
        };


        this.metric = {
            getDashboard: () => {
                return fetchData("metrics/dashboard", "GET");
            },
            getSoftwarePartner: () => {
                const endpoint = `marketplaces/export-to-software-partners`;
                return fetchData(`${endpoint}`, "GET");
            },
            exportData: () => {
                return fetchData("marketplaces/export-to-software-partners", "POST", );
            },
        };

        this.constants = {
            getConstants: () => {
                return fetchData("constants/", "GET");
            },
        };
    }

}

export default new API();
