import React from "react";
import { observer } from "mobx-react-lite";
import { useStripe, useElements, CardNumberElement } from "@stripe/react-stripe-js";
import {Button, Form, Divider, Segment, Checkbox, Grid} from "semantic-ui-react";
import appStore from "_/stores";
import "./ChangeCard.less";
import CardSection from "../Billing/CardSection.jsx";

const ChangeCard = () => {
    const { userStore, upgradeStore, loaderStore, registrationStore } = appStore;
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {

        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const result = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
            billing_details: {
                email: userStore.user.email,
                name: upgradeStore.cardName,
            },
        });
        upgradeStore.changeCard(result);
    };

    // close the checkout model and reset all payment values
    const handleClose = () => {
        upgradeStore.togglePaymentModal(false);
        upgradeStore.resetPayment();
    };


    // handle button click for selected frequency also submit the form
    const handleClick = (e, { value }) => {
        upgradeStore.selectedFrequency = value;
    };


    return (

        <div className="checkout-form">
            <Form onSubmit={handleSubmit}>
                <Segment loading={!stripe}>
                    <CardSection />
                </Segment>
                <Segment>
                    <Form.Field className="checkout-button_position" width={5}>
                        <Button
                            fluid
                            disabled={!stripe}
                            className="blue"
                            size="small"
                            loading={loaderStore.isLoaderActive("paymentLoader")}
                        >
                            Save
                        </Button>
                    </Form.Field>
                </Segment>
            </Form>
            <div onClick={() => handleClose()} className="cancel">Cancel</div>
        </div>

    );
};

export default observer(ChangeCard);