import { observable, action } from "mobx";
import api from "../data-loader/api";

import { DateTime } from "luxon";


class DashboardStore {
    @observable dashboard = {};
    @observable sofwarePartner = {};
    @observable exports = [];


    constructor(appStore) {
        this.appStore = appStore;
    }

    @action
    setDashboard(data) {
        this.dashboard = data;
    }

    @action
    setSofwarePartner(data) {
        this.sofwarePartner = data;
    }

    @action
    setExports(data) {
        this.exports.replace(data);
    }

    reports = {
        sb: {
            name: "Sponsored Brands",
            value: "sb",
        },
        sp: {
            name: "Sponsored Products",
            value: "sp",
        },
        sd: {
            name: "Sponsored Display",
            value: "sd",
        },
        sb_video: {
            name: "Sponsored Brands Video",
            value: "sb_video",
        },
    }

    @action
    async fetchData() {
        try {
            const { data } = await api.metric.getDashboard();
            this.setDashboard(data);
        } catch (err) {
            console.log(err);
        }
    }

    @action
    async fetchExports() {
        try {
            const { data } = await api.marketplace.getExports();
            this.setExports(data);
        } catch (err) {
            console.log(err);
        }
    }

    @action
    async fetchSoftwarePartner() {
        try {
            const { data } = await api.metric.getSoftwarePartner(data);
            this.setSofwarePartner(data);
        } catch (err) {
            console.log(err);
        }
    }
    @action
    async preload() {
        try {
            await this.fetchData();
            await this.fetchSoftwarePartner();
            await this.fetchExports();
        } catch (err) {
            console.log(err);
        }
    }
}

export default DashboardStore;
  