import React from "react";
import {CardElement, CardNumberElement, CardExpiryElement, CardCvcElement} from "@stripe/react-stripe-js";
import {Form, Grid} from "semantic-ui-react";

import appStore from "_/stores";

import "./CardSection.less";

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

function CardSection() {
    const { upgradeStore } = appStore;
    return (
        <div id="stripe-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label className="card-label">
                                Name on Card
                            </label>
                            <div className="card-form">
                                <input
                                    className="card-name"
                                    placeholder="Name on Card"
                                    onChange={(e) => upgradeStore.setCardName(e.target.value)}
                                />
                            </div>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label className="card-label">
                                Card Number
                            </label>
                            <div className="card-form huge">
                                <div className="card-input">
                                    <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                                </div>
                            </div>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Form.Field>
                            <label className="card-label">
                                Expiration Date
                            </label>
                            <div className="card-form">
                                <div className="card-input">

                                    <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                                </div>
                            </div>
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Form.Field>
                            <label className="card-label">
                                CVC
                            </label>
                            <div className="card-form">
                                <div className="card-input">
                                    <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                                </div>
                            </div>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default CardSection;
