import { RouteTree, RouteView } from "mr-router5";
// static pages
import Partners from "../components/Website/Partners/Partners.jsx";
import Pricing from "../components/Website/Pricing/Pricing.jsx";
import About from "../components/Website/About/About.jsx";
import Home from "../components/Website/Home/Home.jsx";
import Login from "../components/Website/Login/Login.jsx";
import Register from "../components/Website/Register/Register.jsx";
import Subscribe from "../components/Website/Subscribe/Subscribe.jsx";
import PasswordReset from "../components/Website/Account/ResetPassword.jsx";
import NewPassword from "../components/Website/Account/NewPassword.jsx";
import ResetConfirm from "../components/Website/Account/ResetConfirm.jsx";
import ResetEmailConfirm from "../components/Webapp/Account/Settings/Email/ResetEmailConfirm.jsx";
import EmailConfirm from "../components/Website/Account/EmailConfirm.jsx";
import Welcome from "../components/Website/Account/Welcome.jsx";
import Terms from "../components/Website/Terms/Terms.jsx";
import Privacy from "../components/Website/Privacy/Privacy.jsx";
// webapp pages
import Webapp from "../components/Webapp/Webapp.jsx";
import Account from "../components/Webapp/Account/Account.jsx";
import UpgradePlan from "../components/Webapp/Account/UpgradePlan.jsx";
import Dashboard from "../components/Webapp/Dashboard/Dashboard.jsx";
import Setup from "../components/Webapp/Activation/Setup.jsx";
import ErrorPage from "../components/Webapp/Error/Error.jsx";
import ErrorRegistrationPage from "../components/Webapp/Error/ErrorNoCampaign.jsx";
import TierPlan from "../components/Webapp/Activation/PlanTiers.jsx";

const routeTree = new RouteTree([
    new RouteView({ name: "home", path: "/" }, Home),
    new RouteView({ name: "partners", path: "/partners" }, Partners),
    new RouteView({ name: "pricing", path: "/pricing" }, Pricing),
    new RouteView({ name: "about", path: "/about" }, About),
    new RouteView({ name: "terms", path: "/terms" }, Terms),
    new RouteView({ name: "privacy", path: "/privacy" }, Privacy),
    // new RouteView({ name: "login", path: "/login" }, Login),
    // new RouteView({ name: "register", path: "/register" }, Register),
    // new RouteView({ name: "subscribe", path: "/subscribe" }, Subscribe),
    new RouteView(
        { name: "passwordreset", path: "/passwordreset" },
        PasswordReset
    ),
    new RouteView({ name: "newpassword", path: "/newpassword" }, NewPassword),
    new RouteView(
        { name: "resetconfirm", path: "/resetconfirm" },
        ResetConfirm
    ),
    new RouteView(
        { name: "emailconfirm", path: "/emailconfirm" },
        EmailConfirm
    ),
    new RouteView(
        { name: "emailresetconfirm", path: "/emailresetconfirm" },
        ResetEmailConfirm
    ),
    new RouteView({ name: "welcome", path: "/welcome" }, Welcome),
    new RouteView({ name: "app", path: "/app" }, Webapp),
    new RouteView({ name: "setup", path: "/app/setup" }, Setup),
    new RouteView({ name: "account", path: "/app/account" }, Account),
    new RouteView({ name: "dashboard", path: "/app/dashboard" }, Dashboard),
    new RouteView({ name: "error", path: "/app/error" }, ErrorPage),
    new RouteView({ name: "error-registration", path: "/app/error/registration" }, ErrorRegistrationPage),
    new RouteView({ name: "upgrade", path: "/app/upgrade" }, UpgradePlan),
    new RouteView({ name: "tierplan", path: "/app/tierplan" }, TierPlan),

    // new RouteView(
    //     { name: "app.account.upgrade", path: "/upgrade" },
    //     UpgradePlan
    // ),
]);

export default routeTree;

// export default websiteRoutes;
