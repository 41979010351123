import * as React from "react";
import { routerStore} from "mr-router5";
import { Container, List, Icon, Card, Divider, Button, Grid } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";

import PlanetFooter from "../../Common/PlanetFooter/PlanetFooter.jsx";
import Faqs from "./Faqs.jsx";
import Tier from "./Tier.jsx";

import appStore from "../../../stores";
import "./Pricing.less";


const PricingContainer = () => {
    const { upgradeStore } = appStore;
    const { enterpriseTier } = upgradeStore;

    const buildMainCards = () => {
        return upgradeStore.getMainTiers().map(tier => {
            return (
                <Tier tier={tier} highlight={ tier.name === ""} />
            );
        });
    };

    const buildFeaturesList = (features) => {
        return features.map(feature => {
            return (
                <List.Item key={uuidv4()} className="feature-item">
                    <Icon name="check" color="teal" /> {feature}
                </List.Item>
            );
        });
    };

    const buildEnterpriseCard = () => {
        return (
            <Card key={uuidv4()} className="tier-card enterprise">
                <Card.Content textAlign="center">
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column className="plan-name-col">
                                <h3>{enterpriseTier.name}</h3>
                            </Grid.Column>
                            <Grid.Column>
                                <h3 style={{display: "inline"}}>
                                    {`$${enterpriseTier.cost.yearly.toFixed(2)}/mo`}
                                </h3>
                                <span style={{marginLeft: "0.4em"}}>{enterpriseTier.cost.term ? `(${enterpriseTier.cost.term})` : null}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <div style={{fontSize: "1.2rem", marginTop: "8px"}}>
                                    {enterpriseTier.description}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider section />
                    <Grid>
                        <Grid.Row columns={3}>
                            <Grid.Column>
                                <div className="feature-list">
                                    {buildFeaturesList(enterpriseTier.features.slice(0, 3))}
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div className="feature-list">
                                    {buildFeaturesList(enterpriseTier.features.slice(3, 7))}
                                </div>
                            </Grid.Column>
                            <Grid.Column className="plan-fees-col">
                                <Button
                                    fluid size="huge"
                                    className="blue"
                                    onClick={() => routerStore.router.navigate("subscribe")}
                                >
                                    {enterpriseTier.callToAction}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
            </Card>
        );
    };

    return (
        <section id="pricing">
            <Container fluid className="pricing-container">
                <Container textAlign="center" className="section-title">
                    <div className="section-header">
                        <h1>Simple and fair pricing.</h1>
                        <h3>Every plan comes with every feature.</h3>
                    </div>
                </Container>
                <Container className="section-tiers">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={2}></Grid.Column>
                            <Grid.Column width={12}>
                                <Card.Group stackable className="pricing-chart" itemsPerRow={2}>
                                    {buildMainCards()}
                                </Card.Group>
                            </Grid.Column>
                            <Grid.Column width={2}></Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Container>
            <Container fluid className="faq-section">
                <Container>
                    <Grid stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <h2>Frequently asked questions</h2>
                            </Grid.Column>
                            <Grid.Column>
                                <Faqs />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Container>

            <PlanetFooter bg="dark" />
        </section>
    );
};

export default PricingContainer;
