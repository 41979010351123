import { observable } from "mobx";
import api from "../data-loader/api";

class ReportStore {
    @observable campaigns = [];
    @observable adGroups = [];

    setReports(data) {
        this.reports.replace(data);
    }

    fetchData() {
        try {
            const data = api.marketplace.getAmazonAdCampaigns();
            this.setReports(data);
        } catch (err) {
            console.log(err);
        }
    }
}

export default ReportStore;
