import * as React from "react";
import {observer} from "mobx-react-lite";
import {Grid, Modal, Header, List, Icon, Message} from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import CheckoutForm from "../Billing/CheckoutForm.jsx";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import appStore from "_/stores";
import "./StripeModal.less";
import api from "../../../../../data-loader/api";
const StripeModal = () => {
    const { upgradeStore } = appStore;


    // this is test stripe key
    // const stripePromise = loadStripe("pk_test_51IsQe6IqpwAWNQV0K4sV6Vv441tSwBTh0BPK22kmiINItqRe6OZdNklN0UAVyXDFXYzRtHJ7RFulRnOgKiO7g4Ox00N5R3LPqW");
    const stripePromise = loadStripe(upgradeStore.stripePK);
    const close = () => {
        upgradeStore.togglePaymentModal(false);
        upgradeStore.resetPayment();
    };


    const displayFeatures = (features) => {
        return features.map(feature => {
            return (
                <List.Item key={uuidv4()} className="feature-item">
                    <Icon name="check" color="teal" /> {feature}
                </List.Item>
            );
        });
    };

    const displayPaymentError = () => {
        if (upgradeStore.paymentErrors.length > 0) {
            return (
                <Message negative>
                    {upgradeStore.paymentErrors[0]}
                </Message>
            );
        }
        return null;
    };
    return (
        <React.Fragment>
            {
                upgradeStore.selectedTier ? (
                    <Modal
                        id="stripe-modal"
                        size="large"
                        closeOnDimmerClick={false}
                        open={upgradeStore.showPaymentModal}
                        onClose={() => close()}
                    >
                        <Header content="Payment" className="payment-header" />
                        <Modal.Content className="payment-body">
                            <Grid stackable className="payment-grid">
                                <Grid.Column width={8} className="features-col">
                                    <h1>{upgradeStore.selectedTier.name} Plan </h1>
                                    <div className="feature-list">
                                        {displayFeatures(upgradeStore.getBillingFeatures(upgradeStore.selectedTier))}
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={8} className="stripe-col">
                                    { displayPaymentError() }
                                    <Elements stripe={stripePromise}>
                                        <CheckoutForm />
                                    </Elements>
                                </Grid.Column>
                            </Grid>
                        </Modal.Content>
                    </Modal>
                ) : null
            }
        </React.Fragment>
    );
};

export default observer(StripeModal);
