import { Container } from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";
import { RouteComponent, routerStore } from "mr-router5";
import Settings from "../Account/Settings/Settings.jsx";
import "./account.less";
import appStore from "../../../stores";
import {useEffect, useState} from "react";
import api from "../../../data-loader/api";




const AccountContainer = () => {
    const { name } = routerStore.route;

    return (
        <section id="account">
            <Container fluid className="account-container">
                <Container className="account-segment">
                    <Settings/>
                </Container>
            </Container>
        </section>
    );
};

export default observer(AccountContainer);
