import * as React from "react";
import {render} from "react-dom";
import {initMrRouter5} from "mr-router5";
import createRouter from "../routing/router";
import routeTree from "../routing/routes";
import Layout from "_/components/Layout/Layout.jsx";
import Loader from "_/components/Common/Loader/Loader.jsx";

// import '_/assets/less/main.less';


// create router
const router = createRouter(routeTree.getRoutes());
initMrRouter5(router, routeTree);

// renderer
const renderApp = () => {
    render((
        <div>
            <Loader />
            <Layout />
        </div>
    ), document.getElementById("app"));
};

export {
    renderApp,
    router,
};
