import * as React from "react";
import {observer} from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
import {Button, Card, Divider, Icon, List, Grid, Segment, Table} from "semantic-ui-react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import StripeModal from "_/components/Webapp/Account/Settings/Billing/StripeModal.jsx";

import appStore from "_/stores";

import "./PlanTiers.less";


/**
 * @component
 * @description Component for syncing with Amazon APIs
 */
const PlanTiers = () => {
    const { userStore, registrationStore, upgradeStore } = appStore;

    const qualifiedTier = upgradeStore[`${registrationStore.userRegistration.plan?.name}Tier`];

    const buildFeaturesList = (features) => {
        return features.map(feature => {
            return (
                <List.Item key={uuidv4()} className="feature-item">
                    <Icon name="check" color="teal" /> {feature}
                </List.Item>
            );
        });
    };

    const getUpFrontCost = (tier, schedule) => {
        return tier.cost[schedule] * 12;
    };

    const getSavings = (tier) => {
        const monthlyCost = getUpFrontCost(tier, "monthly");
        const yearlyCost = getUpFrontCost(tier, "yearly");
        return ((monthlyCost - yearlyCost) / monthlyCost * 100).toFixed(2);
    };

    const selectPayment = (tier, frequency) => {
        upgradeStore.selectedTier = tier;
        upgradeStore.selectedFrequency = frequency;
        if (upgradeStore.selectedTier) {
            upgradeStore.togglePaymentModal(true);
        }
    };

    const handleFreeTier = () => {
        registrationStore.activateFreeUser();
    };

    // sets the stripe promise if we were successful in getting the publish key from the server
    const stripePromise = () => {
        if (upgradeStore.stripePK) {
            return loadStripe(upgradeStore.stripePK);
        }
        return null;
    };

    // const regionsTable = () => {
    //     const buildRegionRows = () => {
    //         return Object.entries(userStore.user.regions).map(([region, marketplaces]) => {
    //             const countries = marketplaces.filter(marketplace => {
    //                 return marketplace.api === "amazon_ad";
    //             }).map(filtered_marketplace => {
    //                 return filtered_marketplace.country;
    //             }).join(", ");
    //             return (
    //                 <Table.Row>
    //                     <Table.Cell>{region}</Table.Cell>
    //                     <Table.Cell>{countries}</Table.Cell>
    //                 </Table.Row>
    //             );
    //         });
    //     };
    //     return (
    //         <Table>
    //             <Table.Header>
    //                 <Table.Row>
    //                     <Table.HeaderCell>Region</Table.HeaderCell>
    //                     <Table.HeaderCell>Marketplaces</Table.HeaderCell>
    //                 </Table.Row>
    //             </Table.Header>
    //             <Table.Body>
    //                 {buildRegionRows()}
    //             </Table.Body>
    //         </Table>
    //     );
    // };

    const buildPlanCard = () => {
        return (
            <Card key={uuidv4()} className="setup-tier-card" fluid>
                <Card.Content className="card-header-container">
                    <Card.Header className="card-header">
                        <h3>{qualifiedTier.name}</h3>
                    </Card.Header>
                </Card.Content>
                <Card.Content className="card-body-container">
                    <Grid stackable columns="equal">
                        <Grid.Column width={7} className="plan-fees-col">
                            <div className="feature-list">
                                {buildFeaturesList(upgradeStore.getBillingFeatures(qualifiedTier))}
                            </div>
                        </Grid.Column>
                        {
                            qualifiedTier.name === "Free Forever" ? (
                                <Grid.Column className="plan-fees" width={9} textAlign="center">
                                    <div className="frequency">Monthly</div>
                                    <div className="fees">${qualifiedTier.cost.monthly.toFixed(2)}<span>/mo</span></div>
                                    <p>&nbsp;</p>
                                    <Button
                                        fluid
                                        size="huge"
                                        className="blue select-free"
                                        onClick={() => handleFreeTier()}
                                    >
                                        Select
                                    </Button>
                                </Grid.Column>
                            ) : (
                                <React.Fragment>
                                    <Grid.Column className="plan-fees">
                                        <div className="frequency">Monthly</div>
                                        <div className="fees">${qualifiedTier.cost.monthly.toFixed(2)}<span>/mo</span></div>
                                        <p>&nbsp;</p>
                                        <Button
                                            fluid size="huge"
                                            className="blue select-tier"
                                            onClick={() => selectPayment(qualifiedTier, "monthly")}
                                        >
                                            Select
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column className="plan-fees">
                                        <div className="frequency">Yearly</div>
                                        <div className="fees">${qualifiedTier.cost.yearly.toFixed(2)}<span>/mo</span></div>
                                        <p>save <strong>{getSavings(qualifiedTier)}%</strong></p>
                                        <Button
                                            fluid size="huge"
                                            className="blue select-tier"
                                            onClick={() => selectPayment(qualifiedTier, "yearly")}
                                        >
                                            Select
                                        </Button>
                                    </Grid.Column>
                                </React.Fragment>
                            )
                        }
                    </Grid>
                </Card.Content>
            </Card>
        );
    };

    const buildPlanMessage = () => {
        if (registrationStore.userRegistration.plan?.name === "enterprise") {
            return (
                <p>
                    We're connected! Based on your average monthly ad spend (over $20,000) from the last 60 days, you qualify for the
                    <strong> { registrationStore.userRegistration.plan?.name }</strong> plan.
                </p>
            );
        }
        return (
            <p>
                We're connected! Based on your average monthly ad spend of <strong>${ registrationStore.userRegistration.plan?.ad_spend }</strong> from the last 60 days, you qualify for the
                <strong> { registrationStore.userRegistration.plan?.name }</strong> plan.
            </p>
        );
    };

    return (
        <Segment id="plan-tier">
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={2} />
                    <Grid.Column width={12}>
                        <div className="sub-header">
                            <h3>Your Plan</h3>
                            { buildPlanMessage() }
                        </div>
                    </Grid.Column>
                    <Grid.Column width={3} />
                </Grid.Row>
            </Grid>

            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        { buildPlanCard() }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Elements stripe={stripePromise()}>
                <StripeModal />
            </Elements>
        </Segment>
    );
};

export default observer(PlanTiers);
