import * as React from "react";
import { Container } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

import AmazonSync from "_/components/Webapp/Activation/AmazonSync.jsx";
import PlanTiers from "_/components/Webapp/Activation/PlanTiers.jsx";
import Confirmation from "_/components/Webapp/Activation/Confirmation.jsx";
import Steps from "_/components/Webapp/Activation/Steps.jsx";
import appStore from "_/stores";

import "./Setup.less";


/**
 * @component
 * @description Component for displaying new user activation
 */
const SetupContainer = () => {
    const { registrationStore: { userRegistration, currentStep } } = appStore;

    const showCurrentStep = () => {
        if (currentStep === 1) {
            return (
                <AmazonSync />
            );
        } else if (currentStep === 2) {
            return (
                <PlanTiers />
            );
        }
        return <Confirmation />;
    };

    const displayHeader = () => {
        if (currentStep === 1) {
            return "Set up your new account.";
        } else if (currentStep === 2) {
            return "Almost done!";
        }
        return "Setup complete.";
    };

    return (
        <section id="setup">
            <Container fluid className="setup-container">
                <Container textAlign="center" className="section-title">
                    <div className="section-header">
                        <h1>{displayHeader()}</h1>
                    </div>
                </Container>
                <Container className="setup-segment">
                    <div className="steps-container">
                        <Steps activeStep={currentStep} />
                    </div>
                    { showCurrentStep() }
                </Container>
            </Container>
        </section>
    );
};

export default observer(SetupContainer);
