import * as React from "react";
import { routerStore } from "mr-router5";
import { Container, Grid, Button, List, Link, Image, Card, Divider, Visibility } from "semantic-ui-react";
import PlanetFooter from "../../Common/PlanetFooter/PlanetFooter.jsx";

import "./about.less";
import appStore from "../../../stores";

const AboutContainer = () => {
    const { homeStore } = appStore;

    const handleVisibility = (calculations) => {
        // looks like 86 is the starting pixel point for the container
        if (calculations.pixelsPassed > 66) {
            homeStore.setShowSolidNav(true);
        } else {
            homeStore.setShowSolidNav(false);
        }
    };

    const handleCallToAction = () => {
        routerStore.router.navigate("subscribe");
    };

    return (
        <Visibility
            fireOnMount={(e, { calculations }) => handleVisibility(calculations)}
            onUpdate={(e, { calculations }) => handleVisibility(calculations)}
        >
            <section id="about">
                <Container fluid className="jumbo-section-wrapper intro">
                    <Container className="intro-container">
                        <Grid className="intro-grid" stackable>
                            <Grid.Row>
                                <Grid.Column width={2}/>
                                <Grid.Column width={12} className="intro-mobile">
                                    <Grid.Row className="about-header">
                                        <h4 className="about-tag">About Us</h4>
                                        <h1>We’re all-in on data portability</h1>
                                        <h3>(and Free Forever for 99% of sellers as of 2023)</h3>
                                    </Grid.Row>
                                    <Grid.Row className="spacer">
                                        <p>
                                            Picture this. You’re an Amazon seller, ready to ramp up your ad spend for a new season. You sign up for a leading PPC automation software. But when you log in to your new dashboard, you realize this amazing new software can only access the last 60 days of your Amazon Ads data.
                                        </p>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <p>
                                            Your performance data from last year’s holidays is unusable. You can’t even see data from 3 months ago.
                                        </p>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <p>
                                            Upgrading to a new Amazon PPC software is painful.  Whether you're spending $5,000 or $500,000 a month on Amazon ads, you’re investing a lot of money in your Ads data. So why don’t you save yourself some pain and make your data portable?
                                        </p>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <p>
                                            Ad Astronaut lets you own, manage, and share your ad data with any Amazon PPC software. <span style={{fontSize: "1.4rem", fontWeight: 900}}>And as of 2023, 99% of Amazon sellers can use our service completely free under our <a href="http://adastronaut.com/pricing">Free Forever</a> plan</span>. All features included, zero hidden fees, total data privacy, and no credit card required.
                                        </p>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <p>Thanks for stopping by and we hope you join our mission for Amazon Ads data portability. The more sellers that sign up and demand software companies ingest their historical data to earn their business, the more data portability becomes an industry norm (instead of a special feature for big brands). We'd love to hear about who you are and how we can help you on your journey. Send us an email at<a href="mailto:hi@adastronaut.com">{" "}hi@adastronaut.com</a>.</p>
                                        <p>
                                            - Abe, David, and Ray
                                        </p>
                                    </Grid.Row>
                                </Grid.Column>
                                <Grid.Column width={2}/>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Container>

                <Container fluid className="light-spacer" />

                <PlanetFooter bg="white" />
            </section>
        </Visibility>
    );
};

export default AboutContainer;