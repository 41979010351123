import * as React from "react";
import PropTypes from "prop-types";
import { routerStore } from "mr-router5";
import { Container, Divider, Button, Grid } from "semantic-ui-react";

import "./PlanetFooter.less";

const PlanetFooter = ({ bg }) => {
    const handleCallToAction = () => {
        routerStore.router.navigate("subscribe");
    };
    return (
        <React.Fragment>
            <Container fluid className={`planet-spacer ${bg}`} />
            <Container fluid className={`planet ${bg}`}>
                <Grid>
                    <Grid.Row columns="equal" textAlign="center">
                        <Grid.Column>
                            <h1 className="ownership">Stop massive<br />data loss today.</h1>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="equal" textAlign="center" className="button-row">
                        <Grid.Column>
                            <Divider hidden />
                            <Button
                                size="huge"
                                as="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="white mobile-spacer"
                                onClick={handleCallToAction}
                            >
                                Start with Free Forever
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

PlanetFooter.propTypes = {
    bg: PropTypes.string,
};

PlanetFooter.defaultProps = {
    bg: "white",
};

export default PlanetFooter;


