import {Container, Segment, Grid, Divider, Modal, Form, Button, Header, Input} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";
import appStore from "../../../../../stores";
import {useState} from "react";
import api from "../../../../../data-loader/api";

import "./Delete.less";

const DeleteContainer = () => {
    const [confirmation, setConfirmation] = useState("delete account");
    const handleInput = (event, data) => {
        const inputData = data.value;
        setConfirmation(inputData);
    };
    const handleCancelUpgrade = async () => {
        if (confirmation === "delete account") {
            await api.user.deleteAccount();
            setOpen(false);
            alert("Your account has been deleted.");

        }
    };
    const [open, setOpen] = React.useState(false);
    const confirmationModal = () => {
        return (
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
            >
                <Modal.Header >Delete Account</Modal.Header>
                <Modal.Content>
                    <p>
                        This action will delete your account and all data associated with this account. Ad Astronaut will retain your data for 30 days
                        should you change your mind.
                    </p>
                    <p style={{"marginBottom": "4rem"}}>
                        After 30 days, the data will be permanently deleted from our servers.
                    </p>
                    <Form.Field>
                        <label>Type to <span style={{"fontStyle": "italic", "fontWeight": 500}}>'delete account'</span> to confirm deletion </label>
                        <Input
                            autoFocus
                            onChange={handleInput}
                            placeholder="delete account"
                            size="huge"
                        />
                    </Form.Field>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="black" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                    <Button color="red" onClick={() => handleCancelUpgrade()}>
                        Confirm
                    </Button>

                </Modal.Actions>
            </Modal>
        );
    };


    return (
        <React.Fragment>
            {confirmationModal()}
            <Button
                className="bottom-aligned color"
                fluid
                onClick={() => setOpen(true)}
            >
                Delete Account
            </Button>
        </React.Fragment>
    );
};

export default observer(DeleteContainer);
