import * as React from "react";
import { observer } from "mobx-react-lite";
import {Button, Container, Grid, Form, Segment, Input, Label, Icon, Message} from "semantic-ui-react";
import appStore from "../../../stores";
import "./passwordreset.less";


const reset = (e) => {
    e.preventDefault();
    appStore.userStore.resetPasswordEmail()
        .then(() => {
            console.log("password reset");
        },
        (error) => {
            console.log(error);
        });
};

const handleChange = (field, val) => {
    appStore.userStore.newEmail[field] = val;
};

const PasswordResetContainer = () => (
    <section id="reset-password">
        <Container fluid textAlign="center" className="reset-password-container">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={5} />
                    <Grid.Column width={6} center>
                        <Segment.Group className="segment-group">
                            <Segment className="reset-password-segment">
                                <div className="header">
                                    Reset Password
                                </div>

                                {
                                    appStore.userStore.newEmail.sent ? (
                                        <Message info>
                                            <p>Check your email for instructions to reset your password. If you did not receive an email, and it's not in your spam folder, this could mean you signed up with a different email.</p>
                                        </Message>
                                    ) : (
                                        <React.Fragment>
                                            <p>
                                                Enter the email address associated with your account and we'll send you a link to reset your password.
                                            </p>
                                            <Form onSubmit={(e) => reset(e)}>
                                                <Form.Field>
                                                    <Input iconPosition="left" placeholder="Email">
                                                        <Icon name="mail" />
                                                        <input
                                                            autoFocus
                                                            onChange={(e) => handleChange("email", e.target.value)}
                                                            placeholder="Email"
                                                        />
                                                    </Input>
                                                </Form.Field>
                                                <Button type="submit" fluid primary>Submit</Button>
                                            </Form>
                                        </React.Fragment>
                                    )
                                }
                            </Segment>
                            <Segment className="signup-segment">
                                <a className="login-link" href="/login">Log In</a> or <a className="signup-link" href="/subscribe">Sign Up</a>
                            </Segment>
                        </Segment.Group>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </section>
);


export default observer(PasswordResetContainer);
