import { Segment, Grid, Button, Divider, Icon, Image, Dropdown, Form, Table } from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";

import RegionAuth from "_/components/WebApp/Activation/RegionAuth.jsx";

import appStore from "_/stores";

import "./AmazonSync.less";


/**
 * @component
 * @description Component for syncing with Amazon APIs
 */
const AmazonSync = () => {
    const { settingsStore, registrationStore, loaderStore, userStore } = appStore;

    const handleContinue = () => {
        registrationStore.setCurrentStep(2);
        loaderStore.start("plans", true);
        setTimeout(() => loaderStore.stop("plans"), 700);
    };

    const displayRegions = () => {
        if (registrationStore.userRegistration.regions.length === 0) {
            return (
                <RegionAuth region={registrationStore.getNewRegion()} />
            );
        }
        // show the first region only
        return (
            <React.Fragment>
                <RegionAuth region={registrationStore.userRegistration.regions[0]} />
            </React.Fragment>
        );
    };

    const handleAddMarketplace = () => {
        const newRegion = registrationStore.getNewRegion();
        registrationStore.userRegistration.regions.push(newRegion);
        registrationStore.setSelectedRegion(null);
    };

    return (
        <Segment id="amazon-sync">
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={3} />
                    <Grid.Column width={10}>
                        <div className="sub-header">
                            <h3>Add Your Amazon Account</h3>
                            <p>
                                Take ownership of your Amazon data. Let Ad Astronaut do the heavy lifting and securely collect and store your data.
                            </p>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={3} />
                </Grid.Row>
            </Grid>

            {displayRegions()}

            {
                registrationStore.showNextStep() ? (
                    <Grid>
                        <Grid.Row>
                            <Grid.Column textAlign="center">
                                <Button
                                    className="blue"
                                    size="big"
                                    onClick={handleContinue}
                                >
                                    Continue to Plans <Icon name="right arrow" />
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                ) : null
            }
        </Segment>
    );
};

export default observer(AmazonSync);
