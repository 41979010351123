import * as React from "react";
import PropTypes from "prop-types";
import { Icon, Segment, Divider, Button, Grid } from "semantic-ui-react";

import "./AnnouncementBar.less";

const AnnouncementBar = ({ announcement }) => {

    return (
        <React.Fragment>
            <Segment className="announcement-bar">
                <Icon name="announcement" /> Free Forever for 99% of Amazon sellers as of 2023
            </Segment>
        </React.Fragment>
    );
};

AnnouncementBar.propTypes = {
    bg: PropTypes.string,
};

AnnouncementBar.defaultProps = {
    bg: "white",
};

export default AnnouncementBar;


