import * as React from "react";
import { Container, Grid, Segment, List, Divider, Visibility } from "semantic-ui-react";
import appStore from "_/stores";
import "./Privacy.less";


const PrivacyContainer = () => {
    const { homeStore } = appStore;

    const handleVisibility = (calculations) => {
        // looks like 86 is the starting pixel point for the container
        if (calculations.pixelsPassed > 66) {
            homeStore.setShowSolidNav(true);
        } else {
            homeStore.setShowSolidNav(false);
        }
    };

    return (
        <Visibility
            fireOnMount={(e, { calculations }) => handleVisibility(calculations)}
            onUpdate={(e, { calculations }) => handleVisibility(calculations)}
        >
            <section id="privacy">
                <Container fluid className="privacy-container">
                    <Container textAlign="center" className="section-title">
                        <div className="section-header">
                            <h1>Our Privacy Policy.</h1>
                        </div>
                    </Container>
                    <Container>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment className="privacy-segment">
                                        <div className="title-container">
                                            <div className="circle-icon teal"></div>
                                            <div className="title">Introduction</div>
                                        </div>
                                        <p>
                                            Ad Astronaut is a software service that provides a
                                            fully-managed cloud repository for your digital
                                            advertising data. DearData Inc. owns, develops, and
                                            manages Ad Astronaut.
                                        </p>
                                        <p>
                                            Your data security and privacy are important to us -
                                            we’re committed to protecting both. This Privacy Policy
                                            (along with our Terms and Conditions and any documents
                                            referred to in it) explains how we collect personal data
                                            from you, and how we protect your data.
                                        </p>
                                        <p>
                                            Please read this policy and our terms of service
                                            carefully to understand how we handle information that
                                            is related to you (‘personal data’). According to the
                                            Data Protection Act (2018) and the European Union’s
                                            General Data Protection Regulation 2016/679 (GDPR),
                                            which outline your data protection rights, we have
                                            responsibilities to ensure that your personal data is
                                            stored and managed properly.
                                        </p>
                                        <p>
                                            By visiting our website at www.adastronaut.com (our
                                            "Site") and using our software service (the "Platform"),
                                            you are accepting and consenting to the practices
                                            described in this policy.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon orange"></div>
                                            <div className="title">Information we may collect from you</div>
                                        </div>
                                        <div className="sub-header">Information you give us</div>
                                        <p>
                                            You give us information when you fill in
                                            forms on our platform and when you
                                            contact us through email, chat, phone,
                                            or other methods. This includes (but is
                                            not limited to) information you provide
                                            when you register to use our Platform.
                                            Common examples of this information may
                                            include your name, email, phone number,
                                            address, personal descriptions,
                                            geographic locations, financial and
                                            payment information, and images.
                                        </p>
                                        <p>
                                            To fulfill our Platform's service to
                                            you, our Platform may allow you to
                                            integrate various third-party platforms
                                            ('Third Party Platforms') with your
                                            account. You may need to authenticate,
                                            register for, or log into the
                                            Third-Party Platforms through the
                                            Platform or on the websites of their
                                            respective providers. When you enable
                                            these features, we will collect relevant
                                            information necessary to enable the
                                            Platform to access the Third Party
                                            Platforms and your data contained within
                                            that Third-Party Platform
                                            ("Authorization Tokens"). We store your
                                            Authorization Tokens long enough to
                                            enable these features and integrate with
                                            the Third Party Platforms. We may also
                                            store data and related metadata from the
                                            Third Party Platforms as necessary to
                                            fulfill our contractual obligations to
                                            you, or provide a verifiably reliable
                                            Platform and appropriate support to you.
                                        </p>
                                        <div className="sub-header">Information we collect from you</div>
                                        <p>
                                            When you visit our Platform, we may
                                            automatically collect information such
                                            as usage information, which includes IP
                                            addresses used to connect your devices
                                            to the Internet, your login information,
                                            browser and browser plug-in information,
                                            time zone setting, and operating system
                                            and platform.
                                        </p>
                                        <p>
                                            We may also collect information about
                                            your visit, including the full Uniform
                                            Resource Locators (URL) clickstream to,
                                            through and from our Platform (including
                                            date and time); what you viewed or
                                            searched for; page response times,
                                            download errors, length of visits to
                                            certain pages, page interaction
                                            information (such as scrolling, clicks,
                                            and mouse-overs), and methods used to
                                            browse away from the page and any phone
                                            number used to call our customer service
                                            number.
                                        </p>
                                        <div className="sub-header">Information we receive from other sources</div>
                                        <p>
                                            We may receive information about you if
                                            you use any of the other websites we
                                            operate or the other services we
                                            provide. We also work closely with third
                                            parties (such as business partners,
                                            contractors) and may receive information
                                            about you from them.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon blue"></div>
                                            <div className="title">How we process payments</div>
                                        </div>
                                        <p>
                                            We use a third party payment processor to process
                                            payments made to us. In connection with the processing
                                            of such payments, we do not retain any personally
                                            identifiable information or any financial information
                                            such as credit card numbers. Rather, all such
                                            information is provided directly to our third party
                                            processor, Stripe, whose use of your personal
                                            information is governed by their privacy policy, which
                                            may be viewed at https://stripe.com/us/privacy.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon yellow"></div>
                                            <div className="title">Cookies</div>
                                        </div>
                                        <p>
                                            Our Platform uses cookies to help us distinguish you
                                            from other users of our Platform. This allows us to
                                            provide additional functionality to our Platform, and
                                            help us analyze Platform usage to improve our Platform
                                            and your user experience. For instance, our Platform may
                                            set a cookie on your browser that allows you to access
                                            the Platform without entering a password more than once
                                            during to access the Platform during the same session.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon teal"></div>
                                            <div className="title">How we use collected information</div>
                                        </div>
                                        <div className="sub-header privacy-list">Information you give us</div>
                                        We use this information:
                                        <List bulleted>
                                            <List.Item>
                                                to carry out our obligations arising from any
                                                contracts entered into between you and us (including
                                                the Website Terms and Conditions) and to provide you
                                                with the information, products and services that you
                                                request from us
                                            </List.Item>
                                            <List.Item>
                                                to provide you with information about other goods
                                                and services we offer that are similar to those that
                                                you have already purchased or inquired about
                                            </List.Item>
                                            <List.Item>
                                                to provide you with information about goods or
                                                services we feel may interest you
                                            </List.Item>
                                            <List.Item>
                                                to notify you about changes to our service
                                            </List.Item>
                                            <List.Item>
                                                to ensure that content from our Platform is
                                                presented in the most effective manner for you and
                                                for your computer or mobile device
                                            </List.Item>
                                        </List>

                                        <div className="sub-header privacy-list">Information we collect from you</div>
                                        We use this information:
                                        <List bulleted>
                                            <List.Item>
                                                to administer our Platform and for internal
                                                operations, including troubleshooting, data
                                                analysis, testing, research, statistical and survey
                                                purposes
                                            </List.Item>
                                            <List.Item>
                                                to improve our Platform to ensure that content is
                                                presented in the most effective manner for you and
                                                for your computer or mobile device
                                            </List.Item>
                                            <List.Item>
                                                to allow you to participate in interactive features
                                                of our service, when you choose to do so
                                            </List.Item>
                                            <List.Item>
                                                as part of our efforts to keep our Platform safe and
                                                secure
                                            </List.Item>
                                            <List.Item>
                                                to make suggestions and recommendations to you and
                                                other users of our Platform about goods or services
                                                that may interest you or them
                                            </List.Item>
                                        </List>
                                        <div className="sub-header">Information we receive from other sources</div>
                                        <p>
                                            We may combine this information with information you
                                            give to us and information we collect about you. We may
                                            use this information and the combined information for
                                            the purposes set out above (depending on the types of
                                            information we receive).
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon orange"></div>
                                            <div className="title">Data Storage and Security</div>
                                        </div>
                                        <p>
                                            The data that we collect from you may be transferred to,
                                            and stored at, a destination outside the European
                                            Economic Area (“EEA”). It may also be processed by staff
                                            (such as support) operating outside the EEA who work for
                                            us or for one of our suppliers. By submitting your
                                            personal data, you agree to this transfer, storing or
                                            processing. We will take all steps reasonably necessary
                                            to ensure that your data is treated securely and in
                                            accordance with this privacy policy.
                                        </p>
                                        <p>
                                            Any and all information you provide to us is stored on
                                            our secure servers. Remember that no data transmission
                                            over the internet is ever completely secure. While
                                            DearData will always do our best to protect your data,
                                            we cannot guarantee the security of your data
                                            transmitted to or through our Platform; any transmission
                                            is at your own risk. Once we receive your information,
                                            we follow strict procedures and security protocols to
                                            try to prevent unauthorized access. We may give you, or
                                            you may choose yourself, a password which allows you to
                                            access certain areas of our Platform. You are
                                            responsible for keeping your passwords fully
                                            confidential and secure. Please do not share your
                                            passwords with anyone.
                                        </p>
                                        <p>
                                            We restrict access of your personal information to
                                            DearData employees who have a business reason for
                                            knowing such information. We continuously educate and
                                            train our employees about the importance of
                                            confidentiality and privacy of customer information. We
                                            maintain physical, electronic and procedural safeguards
                                            that comply with the relevant laws and regulations to
                                            protect your personal information from any unauthorized
                                            access.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon blue"></div>
                                            <div className="title">Disclosure of your information</div>
                                        </div>
                                        <p>
                                            We may share your usage information with selected third
                                            parties including analytics and search engine providers
                                            that assist us in the improvement and optimisation of
                                            our Platform.
                                        </p>
                                        <p>
                                            We may disclose your personal information to third
                                            parties:
                                        </p>
                                        <List bulleted>
                                            <List.Item>
                                                in the event that we sell or buy any business or
                                                assets, in which case we may disclose your personal
                                                data to the prospective seller or buyer of such
                                                business or assets;
                                            </List.Item>
                                            <List.Item>
                                                if DearData or substantially all of its assets are
                                                acquired by a third party, in which case personal
                                                data held by it about its customers will be one of
                                                the transferred assets;
                                            </List.Item>
                                            <List.Item>
                                                if we are under a duty to disclose or share your
                                                personal data in order to comply with any legal
                                                obligation, or in order to enforce or apply the
                                                Terms of Service and any other documents referred to
                                                on it; or to protect the rights, property, or safety
                                                of DearData, our users, or others. This includes
                                                exchanging information with other companies and
                                                organisations for the purposes of fraud protection
                                                and credit risk reduction.
                                            </List.Item>
                                        </List>
                                        <p>
                                            We may also disclose your personal data (for example, a
                                            first name, first initial of your surname, and image) to
                                            a third party in order to fulfill a request made by you
                                            in relation to your use of the Platform.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon yellow"></div>
                                            <div className="title">Your rights</div>
                                        </div>
                                        <p>
                                            Depending on the laws applicable to you, you may have
                                            the right to access information held about you. Your
                                            data access and protection rights can be exercised in
                                            accordance with the relevant data protection
                                            legislation.
                                        </p>
                                        <div className="sub-header privacy-list">Data Protection Rights within the European Union</div>
                                        Within the European Union, you have the rights to:
                                        <List bulleted>
                                            <List.Item>
                                                request access to your personal information
                                                (commonly known as a “data subject access request”).
                                                This enables you to receive a copy of the personal
                                                information we hold about you and to check that we
                                                are lawfully processing it.
                                            </List.Item>
                                            <List.Item>
                                                request correction of the personal information that
                                                we hold about you. We may need to verify the
                                                accuracy of the new data you provide to us.
                                            </List.Item>
                                            <List.Item>
                                                request erasure of your personal information. We may
                                                not always be able to comply with your request for
                                                erasure for specific legal reasons which will be
                                                notified to you, if applicable, at the time of your
                                                request.
                                            </List.Item>
                                            <List.Item>
                                                object to processing of your personal information
                                                where we are relying on a legitimate interest (of
                                                our own or of a third-party) and there is something
                                                about your particular situation which makes you want
                                                to object to processing on this ground as you feel
                                                it impacts on your fundamental rights and freedoms.
                                                You also have the right to object where we are
                                                processing your personal information for direct
                                                marketing purposes. In some cases, we may
                                                demonstrate that we have compelling legitimate
                                                grounds to process your information which override
                                                your rights and freedoms.
                                            </List.Item>
                                            <List.Item>
                                                request restriction of processing of your personal
                                                information.
                                            </List.Item>
                                            <List.Item>
                                                request the transfer of your personal information to
                                                you or to a third party. This right applies only to
                                                automated information which you initially provided
                                                consent for us to use or where we used the
                                                information to perform a contract with you.
                                            </List.Item>
                                            <List.Item>
                                                withdraw consent at any time where we are relying on
                                                consent to process your personal information. If you
                                                withdraw your consent, we may not be able to provide
                                                certain products or services to you. We will advise
                                                you if this is the case at the time you withdraw
                                                your consent.
                                            </List.Item>
                                            <List.Item>
                                                you will not have to pay a fee to access your
                                                personal information or exercise your rights. If
                                                your request is clearly unfounded, repetitive,
                                                excessive, or abusive, we may refuse to comply with
                                                your request or charge a fee.
                                            </List.Item>
                                        </List>
                                        <p>
                                            If you feel that we have not handled information
                                            relating to you properly, or if you have contacted us
                                            about how we use that information and are unhappy with
                                            our response, you have the right to lodge a complaint
                                            with the Information Commissioner’s Office.
                                        </p>
                                        <div className="sub-header privacy-list">Data Rights in California related to the California
                                            Consumer Privacy Act (CCPA)
                                        </div>
                                        <p>
                                            To the extent provided for by law and subject to
                                            applicable exceptions, California residents have the
                                            following privacy rights in relation to the Personal
                                            Information we collect:
                                        </p>
                                        <List bulleted>
                                            <List.Item>
                                                the right to know what Personal Information we have
                                                collected and how we have used and disclosed that
                                                Personal Information
                                            </List.Item>
                                            <List.Item>
                                                the right to request deletion of your Personal
                                                Information
                                            </List.Item>
                                            <List.Item>
                                                the right to be free from discrimination relating to
                                                the exercise of any of your privacy rights
                                            </List.Item>
                                        </List>
                                        <p>
                                            California residents can exercise the above privacy
                                            rights by emailing us at:{" "}
                                            <a href="mailto:help@adastronaut.com">
                                                help@adastronaut.com
                                            </a>
                                            .
                                        </p>
                                        <p>
                                            For security purposes, we may request information from
                                            you to help us verify your identity should you choose to
                                            exercise any of your data protection rights. This
                                            ensures that personal information is not disclosed to
                                            unauthorized parties.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon teal"></div>
                                            <div className="title">Changes to our privacy policy</div>
                                        </div>
                                        <p>
                                            We may make changes to our privacy policy in the future.
                                            Any changes will be posted on this page, and where
                                            appropriate, emailed to you. Check here for the latest
                                            updates to our privacy policy.
                                        </p>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Container>
            </section>
        </Visibility>
    );
};

export default PrivacyContainer;
