import createRouter from "router5";
import browserPlugin from "router5-plugin-browser";
import scrollTopPlugin from "./scroll-top-plugin.js";
import dataLoaderMiddleware from "./data-loader-middleware";

import { createBrowserHistory } from "history";

// parses s3 paths
// https://viastudio.com/hosting-a-reactjs-app-with-routing-on-aws-s3/
const history = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
if (path) {
    history.replace(path);
}

export default routes => {
    const router = createRouter(routes, {defaultRoute: "home"});
    router.usePlugin(browserPlugin({useHash: false, preserveHash: false, hashPrefix: "#!/"}));
    router.usePlugin(scrollTopPlugin);
    router.useMiddleware(dataLoaderMiddleware);

    return router;
};