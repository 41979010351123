import { observable, action } from "mobx";


class LoaderStore {
    loaders = observable.array([]);

    constructor(appStore) {
        this.appStore = appStore;
    }

    @action
    reset() {
        this.loaders.clear();
    }

    @action
    start(name, page = false) {
        if (!this.isLoaderActive(name)) {
            this.loaders.push({name, page});
        }
    }

    @action
    stop(name) {
        if (name) {
            const filteredLoaders = this.loaders.filter(loader => loader.name !== name);
            this.loaders.replace(filteredLoaders);
        } else {
            this.reset();
        }
    }

    isLoaderActive(name) {
        if (name) {
            return (this.loaders.some(loader => loader.name === name));
        }
        throw new Error("Loader name required");
    }

    isPageWideLoaderActive() {
        return this.loaders.some(loader => loader.page);
    }
}

export default LoaderStore;