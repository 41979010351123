import {Container, Segment, Grid, Divider, Form, Button} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";
import appStore from "../../../../../stores";

const handleUpdate = () => {
    appStore.userStore.updateName();
};

const handleChange = (field, val) => {
    appStore.userStore.resetName[field] = val;
};

const ProfileContainer = () => (
    <React.Fragment>
        <Form>
            <Form.Field>
                <label>First Name</label>
                <input
                    placeholder="First Name"
                    onChange={(e) => handleChange("first_name", e.target.value)}
                    value={appStore.userStore.user.first_name}
                />
            </Form.Field>
            <Form.Field>
                <label>Last Name</label>
                <input
                    placeholder="Last Name"
                    onChange={(e) => handleChange("last_name", e.target.value)}
                    value={appStore.userStore.user.last_name}
                />
            </Form.Field>
            <Form.Field>
                <label>Email</label>
                <input
                    placeholder="Email"
                    onChange={(e) => handleChange("email", e.target.value)}
                    value={appStore.userStore.user.email}
                    type="email"
                />
            </Form.Field>
            <Button
                fluid
                primary
                onClick={() => handleUpdate()}
            >
                Update
            </Button>

        </Form>
    </React.Fragment>
);

export default observer(ProfileContainer);
