import {action, computed, get, observable, toJS} from "mobx";
import api from "../data-loader/api";
import { routerStore } from "mr-router5";

class SettingsStore {
    @observable profile = false;
    @observable password = null;
    @observable tier = null;
    //@observable billing = {};
    @observable billing = {
        brand: "",
        last4: "",
        exp_month:"",
        exp_year:"",
    };

    @observable shopifyStoreName = null;

    @observable amazonSPUrl = "";
    @observable amazonAdUrl = "";

    get billing() {
        return this.billing;
    }

    set billing(data) {
        this.billing = data;
    }

    get shopifyStoreName() {
        return this.billing;
    }

    set shopifyStoreName(data) {
        this.shopifyStoreName = data;
    }

    @action
    registerWithShopify() {
        return api.user.shopifyAuth(this.shopifyStoreName)
            .then((resp) => {
                window.location.href = resp.data.url;
            },
            (error) => {
                console.log(error);
            });
    }

    @action
    registerWithAmazonAd() {
        return api.user.amazonAdAuth()
            .then((resp) => {
                window.location.href = resp.data.url;
            },
            (error) => {
                console.log(error);
            });
    }

    @action
    async getAmazonAdUrl(region) {
        try {
            const { data } = await api.user.amazonAdAuth(region);
            this.amazonAdUrl = data.url;
        } catch (err) {
            console.log(err);
        }
    }

    // @action
    // async getAmazonSPUrl() {
    //     try {
    //         const { data } = await api.user.amazonSPAuth();
    //         this.amazonSPUrl = data.url;
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    @action
    async preload() {
        try {
            await this.getAmazonAdUrl();
            // await this.getAmazonSPUrl();
        } catch (err) {
            console.log("Unable to retrieve Amazon endpoints");
        }
    }
}

export default SettingsStore;
