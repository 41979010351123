import { observable, action } from "mobx";
import { routerStore } from "mr-router5";

import api from "../data-loader/api";


class RegistrationStore {
    @observable userRegistration = {};

    @observable currentStep = 1;

    @observable selectedRegion = "NA";

    constructor(appStore) {
        this.appStore = appStore;
    }

    @action
    setUserRegistration(data) {
        this.userRegistration = data;
    }

    @action
    setCurrentStep(step) {
        this.currentStep = step;
    }

    @action
    setSelectedRegion(region) {
        this.selectedRegion = region;
    }

    getNewRegion() {
        const region = {
            name: null,
            countries: [],
        };
        return region;
    }

    getRegionOptions() {
        return [
            {
                key: "NA",
                text: "North America (United States, Canada, Mexico, Brazil)",
                value: "NA",
                disabled: false,
            },
            {
                key: "EU",
                text: "Europe (United Kingdom, Germany, France, Spain, Italy, The Netherlands, UAE)",
                value: "EU",
                disabled: false,
            },
            {
                key: "FE",
                text: "Far East (Japan, Australia)",
                value: "FE",
                disabled: false,
            },
        ];
    }

    @action
    async handleRegionSelect(region) {
        console.log(region, "selected region");
        const { settingsStore } = this.appStore;
        this.setSelectedRegion(region);
        await settingsStore.getAmazonAdUrl(region);
    }

    @action
    async activateFreeUser() {
        const { loaderStore } = this.appStore;
        loaderStore.start("loader", true);
        try {
            await api.user.activateNewUser();
            this.setCurrentStep(3);
            loaderStore.stop("loader");
        } catch (err) {
            loaderStore.stop("loader");
            console.log(err);
        }
    }

    @action
    async fetchPaymentKey() {
        const { upgradeStore } = this.appStore;
        try {
            const { data } = await api.subscription.paymentKey();
            upgradeStore.stripePK = data.key;
        } catch (err) {
            console.log(err);
        }
    }

    @action
    async fetchData() {
        try {
            const { data } = await api.user.getActivationProcess();
            this.setUserRegistration(data);
        } catch (err) {
            routerStore.router.navigate("error");
        }
    }

    @action
    async preload() {
        try {
            await this.fetchPaymentKey();
            await this.fetchData();

            if (!this.userRegistration.is_new_activation) {
                this.setCurrentStep(3);
            }
        } catch (err) {
            routerStore.router.navigate("error");
        }
    }

    showNextStep() {
        return this.userRegistration.is_ad_linked;
    }
}

export default RegistrationStore;
