// import { action, computed, get, observable, toJS } from 'mobx';
// import api from '../data-loader/api';
// import { routerStore } from 'mr-router5';

class PartnersStore {
    partners = [
        {
            name: "Ad Badger",
            description:
                "A powerful toolset to manage, automate, and monitor your Amazon campaigns around the clock.",
            button: "Learn more",
            link: "https://adbadger.com",
            image: "",
        },
        {
            name: "Feedvisor",
            description:
                "The Next-Generation, “AI-First” Solution for Amazon Advertising. \n" +
                "Available for Sponsored Products, Product Targeting, Sponsored Brands, Sponsored Display, and Amazon DSP.",
            button: "Learn more",
            link:
                "https://https://feedvisor.com/advertising-optimization-and-intelligence/",
            image: "",
        },
        {
            name: "Flywheel",
            description:
                "Our technology automates our best-in-class methodologies, freeing up colleagues \n" +
                "and clients to deep dive on the business and discover growth opportunities.",
            button: "Learn more",
            link: "https://flywheel.com",
            image: "",
        },
        {
            name: "Kenshoo",
            description:
                "Kenshoo provides powerful digital marketing tools used by the world’s \n" +
                "leading brands and agencies. Our technology delivers the insights and controls \n" +
                "you need to make better decisions, work more efficiently, and realize greater results.",
            button: "Learn more",
            link: "https://kenshoo.com",
            image: "",
        },
        {
            name: "Kinetic",
            description:
                "Save time & build profits by automating your Amazon PPC.",
            button: "Learn more",
            link: "https://viral-launch.com/kinetic/",
            image: "",
        },
        {
            name: "Pacvue",
            description:
                "Marketplace advertising and intelligence. The enterprise platform to \n" +
                "manage ad campaigns at scale on Amazon, Walmart, Instacart, and more.",
            button: "Learn more",
            link: "https://pacvue.com",
            image: "",
        },
        {
            name: "Sellics",
            description:
                "When it comes to automating and growing your Amazon PPC campaigns, Sellics \n" +
                "Advertising software has all the tools, resources and expert support to get you where you want to grow.",
            button: "Learn more",
            link: "https://sellics.com",
            image: "",
        },
        {
            name: "Sellozo",
            description:
                "Sellozo has created a fully-automated Amazon Product Advertising platform \n" +
                "that enables you to massively grow your sales and profitability in weeks.",
            link: "https://sellozo.com",
            image: "",
        },
        {
            name: "Teikametrics",
            description:
                "Teikametrics ensures every advertising dollar spent on Amazon & Walmart goes \n" +
                "to what matters most: growing your business and increasing profits.",
            button: "Learn more",
            link: "https://teikametrics.com",
            image: "",
        },
    ];
}

export default PartnersStore;
