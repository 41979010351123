import appStore from "../stores/index";

const csrfSafeMethod = (method) => {
    const safeMethods = ["GET", "HEAD", "OPTIONS", "TRACE"];
    return safeMethods.indexOf(method) >= 0;
};

const fetchData = (endpoint, method = "GET", params, csrftoken) => {
    // BASE_URL is defined at build time through webpack Define plugin
    // eslint-disable-next-line no-undef
    const resource = `${BASE_URL}/${endpoint}`;

    const settings = (requestType, data) => {
        let options = {
            method: requestType,
            credentials: "include",
        };

        // add data if exists
        if (data) {
            options.headers = {"Content-Type": "application/json"};
            options.body = JSON.stringify(data);
        }

        // add csrf token if PUT, POST or DELETE method
        if (!csrfSafeMethod(requestType)) {
            options.headers = Object.assign(options.headers || {}, {"X-CSRFTOKEN": csrftoken});
        }

        return options;
    };

    return fetch(resource, params ? settings(method, params) : settings(method)).then(response => {
        // if no content sent back then response.json() would throw a syntax error
        if (response.ok && response.status === 204) {return response;}
        return response.json().then(json => {
            return response.ok ? json : Promise.reject(json);
        });
    });
};


const loadData = (fetchPromises) => {
    const uniqueLoaderName = Math.random().toString(36).substring(2, 15);

    appStore.uiStore.add(uniqueLoaderName, "loader");

    return Promise.all(fetchPromises)
        .then((resp) => {
            appStore.uiStore.remove(uniqueLoaderName, "loader");
            return resp;
        })
        .catch((error) => {
            appStore.uiStore.remove(uniqueLoaderName, "loader");
            // throw the error so the error will be caught when the
            // "catch" method is chained
            throw error;
        });

};

const awaitPromises = async (fns = []) => {
    const { loaderStore } = appStore;

    loaderStore.start("preload", true);
    await Promise.all(fns.map(async fn => fn()));
    loaderStore.stop("preload");
};

export default {
    fetchData,
    loadData,
    awaitPromises,
};
