import * as React from "react";
import { Container, Segment, Grid, Divider, Visibility } from "semantic-ui-react";
import appStore from "_/stores";
import "./Terms.less";


const TermsContainer = () => {
    const { homeStore } = appStore;

    const handleVisibility = (calculations) => {
        // looks like 86 is the starting pixel point for the container
        if (calculations.pixelsPassed > 66) {
            homeStore.setShowSolidNav(true);
        } else {
            homeStore.setShowSolidNav(false);
        }
    };

    return (
        // TODO: refactor visibility into a HOC
        <Visibility
            fireOnMount={(e, { calculations }) => handleVisibility(calculations)}
            onUpdate={(e, { calculations }) => handleVisibility(calculations)}
        >
            <section id="terms">
                <Container fluid className="terms-container">
                    <Container textAlign="center" className="section-title">
                        <div className="section-header">
                            <h1>Our Terms and Conditions.</h1>
                        </div>
                    </Container>
                    <Container>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment className="terms-segment">
                                        <div className="title-container">
                                            <div className="circle-icon teal"></div>
                                            <div className="title">Introduction</div>
                                            <div className="effective-date">Effective date: September 10, 2020</div>
                                        </div>
                                        <p>
                                            Ad Astronaut is a software service that provides a
                                            fully-managed cloud repository for your digital
                                            advertising data.
                                        </p>
                                        <p>
                                            DearData Inc. owns, develops, and manages the Ad
                                            Astronaut service. Please read the DearData Terms and
                                            Conditions and our Privacy Policy carefully, as you
                                            agree to consent to our terms and policies by your use
                                            of this website ('Site') or our software applications
                                            ('Platform').
                                        </p>
                                        <p>
                                            DearData ("DearData", “Company,” “We,” “Us,”, “Our”)
                                            provides software to help users selling through
                                            ecommerce channels. By using our Platform, or by
                                            accepting our Terms and Conditions upon user
                                            registration, you agree that your use is subject to our
                                            Terms and Conditions. If you are agree to these Terms
                                            and Conditions on behalf of an entity, you are binding
                                            such entity to these Terms and Conditions, and you
                                            represent that you have the actual authority to bind
                                            such entity to these Terms and Conditions, and
                                            references to “you” refer to such entity. The Site
                                            includes all related domains, subdomains, components,
                                            and applications owned by DearData Inc. If you do not
                                            agree to these Terms and Conditions, you may not use the
                                            Site or the Platform.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon orange"></div>
                                            <div className="title">Our right to modify the Terms and Conditions</div>
                                        </div>
                                        <p>
                                            DearData expressly reserves the right to modify the
                                            Terms and Conditions at any time in its sole discretion
                                            by including such alteration and/or modification in
                                            these Terms and Conditions, along with a notice of the
                                            effective date of the modified Terms and Conditions. If
                                            a revision meaningfully reduces your rights, we will use
                                            reasonable efforts to notify you (by, for example,
                                            sending an email to the billing contact you designate in
                                            your User Account (as defined below), through your User
                                            Account or in the Platform itself). To the extent you
                                            have purchased a subscription to the Platform, the
                                            modified terms will be effective upon your next
                                            subscription renewal. In this case, if you object to the
                                            updated terms, as your exclusive remedy, you may choose
                                            not to renew, including cancelling any subscription set
                                            to auto-renew. In all other cases, any continued use by
                                            you of the Site or the Platform after the posting of
                                            such modified Terms and Conditions shall be deemed to
                                            indicate your irrevocable agreement to such modified
                                            Terms and Conditions. Accordingly, if at any time you do
                                            not agree to be subject to any modified Terms and
                                            Conditions, you may no longer use the Site or Platform.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon blue"></div>
                                            <div className="title">User Account Registration; Passwords</div>
                                        </div>
                                        <p>
                                            (a) Account Registration and Use License: In order to
                                            access and use all of the features of the Platform, you
                                            are required to open an account (“User Account”) by
                                            registering with DearData. When you register for your
                                            User Account you must provide true, accurate, current
                                            and complete information (“Account Information”), and
                                            you agree to update the Account Information in order to
                                            ensure that it is current. Upon proper registration and
                                            opening of a User Account, and subject to fulfillment of
                                            our Terms and Conditions, DearData hereby grants to you
                                            the personal, non-transferable right and license to use
                                            the Platform, solely for your own internal business
                                            purposes, until such time when either you or DearData
                                            elect to terminate such right in accordance with these
                                            Terms and Conditions.
                                        </p>
                                        <p>
                                            (b) Eligibility: As an express condition of being
                                            permitted to open a User Account, you represent and
                                            warrant that you (i) have the legal capacity (including,
                                            without limitation, being of sufficient age) to enter
                                            into contracts under the law of the jurisdiction in
                                            which you reside, (ii) are not on a list of persons
                                            barring you from receiving services under U.S. laws
                                            (including, without limitation, the Denied Persons List
                                            and the Entity List issued by the U.S. Department of
                                            Commerce, Bureau of Industry and Security) or other
                                            applicable jurisdiction and (iii) are not a resident of
                                            Cuba, Iran, North Korea, Sudan or Syria. In addition,
                                            you may not use a third party, a bot, or an automated
                                            method to open a User Account.
                                        </p>
                                        <p>
                                            (c) Passwords: Upon registration on the Website, you
                                            will provide DearData with a password to access your
                                            account. You are responsible for maintaining the
                                            confidentiality of your password and for all of your
                                            activities and those of any third party that occur
                                            through your account, whether or not authorized by you.
                                            You agree to immediately notify DearData of any
                                            suspected or actual unauthorized use of your User
                                            Account. You agree that DearData will not under any
                                            circumstances be liable for any cost, loss, damages or
                                            expenses arising out of a failure by you to maintain the
                                            security of your password.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon yellow"></div>
                                            <div className="title">Site Content</div>
                                        </div>
                                        <p>
                                            (a) DearData Resources: The information, materials
                                            (including, without limitation, HTML, text, audio,
                                            video, white papers, press releases, data sheets,
                                            product descriptions, software and FAQs and other
                                            content) available on the Site and/or the Platform,
                                            excluding Third Party Resources (collectively, “DearData
                                            Resources”), are the copyrighted works of DearData, and
                                            DearData expressly retains all right title and interest
                                            in and to the DearData Resources, including, without
                                            limitation, all intellectual property rights therein and
                                            thereto. Except as expressly permitted in these Terms
                                            and Conditions, any use of the DearData Resources may
                                            violate copyright and/or other applicable laws.
                                        </p>
                                        <p>
                                            (b) Third Party Resources: In addition to DearData
                                            Resources, the Site and/or the Platform may contain
                                            information and materials provided to DearData by third
                                            parties (collectively, “Third Party Resources”). Third
                                            Party Resources are the copyrighted work of its owner,
                                            who expressly retains all right title and interest in
                                            and to the Third Party Resources, including, without
                                            limitation, all intellectual property rights therein and
                                            thereto. In addition to being subject to these Terms and
                                            Conditions, Third Party Resources may also be subject to
                                            different and/or additional Terms and Conditions and/or
                                            privacy policies of such third parties. Please contact
                                            the appropriate third party for further information
                                            regarding any such different and/or additional Terms and
                                            Conditions applicable to Third Party Resources.
                                        </p>
                                        <p>
                                            (c) Limited Site Content License: DearData grants you
                                            the limited, revocable, non-transferable, non-exclusive
                                            right to use the DearData Resources and Third Party
                                            Resources (collectively, “Site Content”) by displaying
                                            the Site Content on your computer, and downloading and
                                            printing pages from the Site containing Site Content,
                                            under the condition that (i) such activity is solely for
                                            your personal, educational or other noncommercial use,
                                            (ii) you do not modify or prepare derivative works from
                                            the Site Content, (iii) you do not obscure, alter or
                                            remove any notice of copyright set forth on any Site
                                            pages or Site Content, (iv) you do not otherwise
                                            reproduce, re-distribute or publicly display any of the
                                            Site Content and (v) you do not copy any Site Content to
                                            any other media or other storage format. No other use of
                                            the Site Content is authorized. Except as specified
                                            above, nothing contained herein shall be construed as
                                            conferring by implication, or otherwise any license or
                                            right under any patent, trademark, copyright or any
                                            other intellectual property right of DearData or any
                                            third party. All Site Content is provided for
                                            informational purposes only.
                                        </p>
                                        <p>
                                            (d) Trademarks: DearData, the DearData Logo, and all of
                                            DearData's product names and product logos are
                                            trademarks and services marks of DearData (collectively
                                            “DearData Trademarks”), and nothing in these Terms or
                                            the Platform shall be construed as granting any license
                                            or right to use the DearData Trademarks without
                                            DearData’s prior written consent. All trademarks,
                                            service marks and logos included on the Site (“Marks”)
                                            are the property of DearData or third parties, and you
                                            may not use such Marks without the express, prior
                                            written consent of DearData or the applicable third
                                            party.
                                        </p>
                                        <p>
                                            (e) Monitoring of Site Content and use of Platform:
                                            DearData reserves the right, but does not undertake the
                                            obligation, to monitor use of the Site and/or the
                                            Platform, and to investigate and take appropriate legal
                                            action against any party that uses the Site in violation
                                            of these Terms and Conditions or applicable law.
                                            DearData reserves the right to accept, reject or modify
                                            any Site Content or User Content, but assumes no
                                            liability based on its acceptance, rejection,
                                            modification or failure to modify any Site Content or
                                            User Content.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon teal"></div>
                                            <div className="title">Your use of the Site and Platform</div>
                                        </div>
                                        <p>
                                            (a) Account and Use of Platform: You may use your User
                                            Account for the Platform and/or Site only in accordance
                                            with these Terms and Conditions and only for lawful
                                            purposes. You are responsible for your own actions on
                                            the site, including the upload, transmission and
                                            posting, and accuracy of information, and are
                                            responsible for any consequences of such actions through
                                            the Platform or Site.
                                        </p>
                                        <p>
                                            (b) Platform Changes: By signing up for the Platform,
                                            you agree that we may make changes related to our
                                            Platform at any time and for any reason, including, but
                                            not limited to, the features provided by our Platform,
                                            the features available in a given DearData subscription
                                            plan, or the choice of Platforms we choose to support.
                                            In addition, the features or availability of our free
                                            use plans (such as our 'Free Forever' subscription plan)
                                            may change at any time and for any reason, for both new
                                            and existing subscribers.
                                        </p>
                                        <p>
                                            (c) Your assumption of risk: We do not make any
                                            guarantees about the effects or usefulness of the
                                            Platform, and your use of the Platform is entirely at
                                            your own risk. You are solely responsible for any and
                                            all fees charged to your account by Third Party
                                            Platforms (such as fees that may be charged to you as a
                                            seller on the Amazon.com marketplace).
                                        </p>
                                        <p>
                                            (d) Interactions with Platforms: The features of our
                                            Platform depends on our ability to access and view your
                                            account data on various seller and social media
                                            platforms, marketplaces, software services, online
                                            portals, and other sources of data (collectively, the
                                            “Third Party Platforms”). Therefore, in order to use the
                                            DearData Platform you are required to provide
                                            authenticated access to your related or relevant
                                            accounts through our application, and by doing so, you
                                            grant us permission to access, view, and export any and
                                            all data available in your accounts, including but not
                                            limited to, all sales data, advertising data, customer
                                            data, and financial transactions. We are not responsible
                                            for your account or performance on any of these Third
                                            Party Platforms, which are governed by their own terms
                                            and policies.
                                        </p>
                                        <p>
                                            In addition, specific reporting and convenience features
                                            of our Platform may depend on our ability to access,
                                            read, and write data to your Google Drive account.
                                            Should you choose to enable these features on our
                                            Platform for your own convenience, you are required to
                                            provide authenticated access to your related or relevant
                                            accounts through our application, and by doing so, you
                                            grant us permission to access, view, and import data to
                                            your Google Drive account. We are not responsible for
                                            any storage charges that may be required to store data
                                            to your Google Drive account.
                                        </p>
                                        <p>
                                            (e) Fees and Payments: The Platform offered is pursuant
                                            to a subscription plan. Some features of the Platform
                                            may only be accessed and used upon the payment of
                                            applicable fees (“Fees”). NOTWITHSTANDING ANYTHING
                                            CONTAINED HEREIN, ANY FREE OR PAID SERVICE PROVIDED BY
                                            OUR PLATFORM IS PROVIDED “AS-IS” WITHOUT ANY
                                            REPRESENTATIONS, WARRANTIES OR INDEMNITIES. Depending on
                                            the subscription plan you select, you will only be able
                                            to access and use those features of the Platform which
                                            are available in that given subscription plan. If you
                                            choose to downgrade your subscription plan, certain
                                            features that were previously available to you may not
                                            be available to you until you upgrade your subscription
                                            plan.
                                        </p>
                                        <p>
                                            Fees may vary in accordance with our current pricing
                                            policy. DearData reserves the right to change the terms
                                            of your subscription, including price, from time to
                                            time, effective as of the beginning of your next Billing
                                            Period following the date of the change. If DearData
                                            changes the subscription fee or other additional charges
                                            and fees related to your usage of our platform, we will
                                            give you advance notice of these changes. However, we
                                            will not be able to notify you of changes in any
                                            applicable taxes. All Fees are exclusive of all taxes,
                                            levies, or duties imposed by taxing authorities, and you
                                            shall be responsible for payment of all such taxes,
                                            levies, or duties, excluding only United States (federal
                                            or state) taxes.
                                        </p>
                                        <p>
                                            As long as you are subscribing to our Platform or have
                                            an outstanding balance with us, you must provide us with
                                            a valid payment source that you are authorized to use
                                            and you authorize us to deduct the due charges against
                                            that payment source including, without limitation, any
                                            applicable taxes. If, for some reason, we are unable to
                                            process your payment, we’ll attempt to contact you by
                                            email so you may provide us with an alternate payment
                                            source. Failure to perform payment shall construe as
                                            material breach of these Terms and we may cancel your
                                            subscription immediately. Payments for the subscription
                                            are due prior to the start of the new term. You must
                                            cancel your subscription at least 24 hours prior to the
                                            start of the new Billing Period or you may be charged
                                            for the next Billing Period.
                                        </p>
                                        <p>
                                            (f) Refunds, Upgrading and Downgrading: We do not offer
                                            refunds. Any upgrade or downgrade in your Platform use
                                            will result in the new Fees being charged at the next
                                            billing cycle. There will be no prorating for downgrades
                                            in between billing cycles. Downgrading your Platform may
                                            cause the loss of features or capacity of your User
                                            Account. DearData does not accept any liability for such
                                            loss.
                                        </p>
                                        <p>
                                            (g) Cancellation and Termination by You: You are solely
                                            responsible for properly cancelling your User Account.
                                            An email or phone request to cancel your User Account is
                                            not considered cancellation. You can cancel your User
                                            Account at any time by logging into your User Account in
                                            the application and accessing your billing settings. The
                                            billing settings screen provides a simple cancellation
                                            link. If you cancel your User Account before the end of
                                            your current paid up month, your cancellation will take
                                            effect immediately, and you will not be charged again.
                                            Please note that we do not provide refunds for unused
                                            time in the last billing cycle.
                                        </p>
                                        <p>
                                            (h) Termination and Suspension by DearData: DearData may
                                            terminate your User Account and/or these Terms and
                                            Conditions at any time and for any reason upon notice to
                                            you. We may also suspend our Platform to you at any
                                            time, with or without cause. If we terminate your User
                                            Account without cause, we will refund a prorated portion
                                            of your monthly prepayment. We will not refund or
                                            reimburse you if we terminate your User Account for
                                            cause, including (without limitation) for a violation of
                                            these Terms and Conditions.
                                        </p>
                                        <p>
                                            (i) Effect of Termination: Once your User Account is
                                            terminated, we may permanently delete your User Account
                                            and any or all User Content associated with it. If you
                                            do not log in to your User Account for 12 or more
                                            months, we may treat your User Account as “inactive” and
                                            permanently delete the User Account and all the data
                                            associated with it. Except where an exclusive remedy may
                                            be specified in this Agreement, the exercise by either
                                            party of any remedy, including termination, will be
                                            without prejudice to any other remedies it may have
                                            under these Terms and Conditions. All sections of this
                                            Agreement which by their nature should survive
                                            termination will survive, including without limitation,
                                            accrued rights to payment, use restrictions and
                                            indemnity obligations, confidentiality obligations,
                                            warranty disclaimers, and limitations of liability.
                                        </p>
                                        <p>
                                            (j) Prohibited Conduct: You agree not to use the Site or
                                            the Platform for: (i) posting any (1) information which
                                            is incomplete, false, inaccurate or not your own, (2)
                                            trade secrets or material that is copyrighted or
                                            otherwise owned by a third party unless you have a valid
                                            license from the owner which permits you to post it, (3)
                                            material that infringes on any other intellectual
                                            property, privacy or publicity right of another, (4)
                                            advertisement, promotional materials or solicitation
                                            related to any product or service that is competitive
                                            with DearData products or services or (5) software or
                                            programs which contain any harmful code, including, but
                                            not limited to, viruses, worms, time bombs or Trojan
                                            horses; (ii) impersonating another person; (iii)
                                            engaging in or encouraging conduct that would constitute
                                            a criminal offense, give rise to civil liability or
                                            otherwise violate any city, state, national or
                                            international law or regulation, or which fails to
                                            comply with accepted Internet protocol; or (iv)
                                            transmitting or transferring (by any means) information
                                            or software derived from the Site or the Platform to
                                            foreign countries or certain foreign nations in
                                            violation of US export control laws. In addition, you
                                            agree not to violate or attempt to violate the security
                                            of the Site, the Platform or DearData’s system or
                                            network security, including, without limitation, the
                                            following: (w) accessing data not intended for users of
                                            the Site or the Platform, or gaining unauthorized access
                                            to an account, server or any other computer system; (x)
                                            attempting to probe, scan or test the vulnerability of a
                                            system or network or to breach security or
                                            authentication measures; (y) attempting to interfere
                                            with the function of the Site, the Platform, host or
                                            network, including, without limitation, via means of
                                            submitting a virus to the Site, overloading, “flooding”,
                                            “mailbombing”, “crashing”, or sending unsolicited
                                            e-mail, including promotions and/or advertising of
                                            products or services; or (z) forging any TCP/IP packet
                                            header or any part of the header information in any
                                            e-mail or newsgroup posting or (6) transmitting,
                                            importing, uploading, or incorporating any financial or
                                            medical information of any nature, or any sensitive
                                            personal information (e.g., Social Security numbers,
                                            driver’s license numbers, birth dates, personal bank
                                            account numbers, passport or visa numbers, credit card
                                            numbers, passwords and security credentials). Violations
                                            of the Site’s, the Platform’s or DearData’s system or
                                            network security may result in civil or criminal
                                            liability.
                                        </p>
                                        <p>
                                            (k) Your Data: You will retain ownership of any data,
                                            information or material originated by you that you
                                            transmit through the Platform ("User Content") – for
                                            example, User Content from your accounts with third
                                            party services (e.g., Amazon or Shopify) that passes
                                            through the Platform. You shall be solely responsible
                                            for the accuracy, quality, content and legality of User
                                            Content, the means by which User Content is acquired and
                                            the transmission of User Content outside of the
                                            Platform. You represent and warrant that you have all
                                            rights necessary to transmit User Content through the
                                            Platform and to otherwise have User Content used as part
                                            of the Platform or as otherwise contemplated herein.
                                        </p>
                                        <p>
                                            (l) Suggestions: You hereby grant to DearData a
                                            royalty-free, worldwide, transferable, sublicensable,
                                            irrevocable, perpetual license to use or incorporate
                                            into the Site, the Platform and/or other DearData
                                            offerings any suggestions, enhancement requests,
                                            recommendations or other feedback provided by you to
                                            DearData that is related to the Site and/or the
                                            Platform.
                                        </p>
                                        <p>
                                            (m) Aggregated and/or Anonymized Data: Notwithstanding
                                            anything to the contrary set forth herein or otherwise,
                                            DearData will have the right to collect and analyze data
                                            and other information relating to the provision, use or
                                            performance of the Site and/or Platform and related
                                            systems and technologies (including information
                                            concerning User Data and data derived therefrom), and to
                                            aggregate and/or anonymize all such data and
                                            information. DearData will be free at any time to: (i)
                                            use such information and data to improve and enhance
                                            DearData’s offerings; and (ii) disclose such data in
                                            aggregate or other de-identified form in connection with
                                            its business.
                                        </p>
                                        <p>
                                            (n) Your Indemnification Obligations: You hereby
                                            irrevocably agree to indemnify, defend and hold
                                            DearData, its affiliates, directors, officers, employees
                                            and agents harmless from and against any and all loss,
                                            costs, damages, liabilities and expenses (including
                                            attorneys’ fees) arising out of or related to (i) any
                                            third party claim resulting from a breach by you of any
                                            of your covenants, representations or warranties
                                            contained in these Terms and Conditions and/or (ii) your
                                            use of the Site and/or the Platform.
                                        </p>
                                        <p>
                                            (o) Team Accounts: Depending on the application, you may
                                            be invited to establish or join a DearData team account
                                            (“Team Account”). Each Team Account will have an
                                            administrator who can accept or remove Team Account
                                            members. By agreeing to join a Team Account, you
                                            acknowledge that (i) your identity, including name,
                                            email address, and avatar (if any), will be disclosed to
                                            other Team Account members, and the Team Account
                                            administrator will also have access to your task usage,
                                            and (ii) your User Content, integrations with third
                                            party applications, and associated data will be viewable
                                            by all Team Account members. In addition, Team Account
                                            members may have read/write access to this information.
                                        </p>
                                        <p>
                                            (p) Export Control: You hereby represent and warrant
                                            that (i) you understand and acknowledge that some Site
                                            Content or components of the Platform may be subject to
                                            export, re-export and import restrictions under
                                            applicable law, (ii) you will not use the Site, any Site
                                            Content or the Platform in a manner that violates the
                                            U.S. Export Administration Act of 1979 and the
                                            regulations of the U.S. Department of Commerce and (iii)
                                            you are not located in, under the control of, or a
                                            national or resident of any country to which the United
                                            States has embargoed goods.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon orange"></div>
                                            <div className="title">Linked Sites and Third Party Platforms</div>
                                        </div>
                                        <p>
                                            The Site and/or Platform may contain links to and
                                            integrations with third party websites and services
                                            (such as Amazon.com), and you agree that DearData
                                            provides links to and integrations with such websites
                                            and services solely as a convenience and has no
                                            responsibility for the content or availability of such
                                            websites or services, and that DearData does not endorse
                                            such websites or services (or any products or other
                                            services associated therewith). Access to any other
                                            internet website linked to the Site and/or Platform is
                                            at your own risk, and DearData is not responsible for
                                            the accuracy or reliability of any information, data,
                                            opinions, advice or statements made on these sites. Your
                                            use of such websites and services will be subject to the
                                            terms applicable to each such website and service. You
                                            may not post a link that directs users to any content or
                                            information that would constitute a violation of these
                                            Terms and Conditions.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon blue"></div>
                                            <div className="title">Platform Warranty</div>
                                        </div>
                                        <p>
                                            If you are a paying subscriber to the Platform, DearData
                                            warrants to you that it will provide the Platform
                                            substantially in accordance with its documentation under
                                            normal use. In the event of any breach of such warranty,
                                            your exclusive remedy will be DearData’s re-performance
                                            of the deficient service in our Platform or, if DearData
                                            cannot re-perform such deficient service as warranted,
                                            you may terminate your User Account as set forth above
                                            and DearData will refund a prorated portion of your
                                            monthly prepayment. You must notify DearData in writing
                                            of any warranty deficiency within 10 days from receipt
                                            of the deficient service in our Platform in order to
                                            receive the foregoing warranty remedy.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon yellow"></div>
                                            <div className="title">Limitation of Liability</div>
                                        </div>
                                        <p>
                                            (a) Warranty Disclaimer: EXCEPT AS EXPRESSLY SET FORTH
                                            IMMEDIATELY ABOVE, THE WEBSITE, WEBSITE CONTENT AND
                                            SERVICE ARE PROVIDED STRICTLY ON AN “AS IS” AND “AS
                                            AVAILABLE” BASIS, AND DEARDATA MAKES NO WARRANTY THAT
                                            THE WEBSITE, SERVICE OR WEBSITE CONTENT ARE COMPLETE,
                                            SUITABLE FOR YOUR PURPOSE, RELIABLE, USEFUL OR ACCURATE,
                                            AND ON BEHALF OF ITSELF AND ITS LICENSORS, DearData
                                            HEREBY EXPRESSLY DISCLAIMS ANY AND ALL IMPLIED,
                                            STATUTORY OR OTHER WARRANTIES WITH RESPECT TO THE
                                            WEBSITE, WEBSITE CONTENT AND SERVICE, OR THE
                                            AVAILABILITY OF THE FOREGOING, INCLUDING, WITHOUT
                                            LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                                            FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                                            NONINFRINGEMENT. THE ENTIRE RISK AS TO RESULTS OBTAINED
                                            THROUGH USE OF THE WEBSITE, SERVICE AND/OR THE WEBSITE
                                            CONTENT RESTS WITH YOU. DearData AND ITS SUPPLIERS WILL
                                            NOT BE LIABLE OR RESPONSIBLE IN ANY WAY FOR ANY LOSSES
                                            OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
                                            OR RELIANCE ON ANY MATERIAL CONTAINED ON THE WEBSITE OR
                                            SERVICE. DearData MAKES NO REPRESENTATION OR WARRANTY
                                            THAT THE AVAILABILITY OF THE WEBSITE AND OR THE SERVICE
                                            WILL BE UNINTERRUPTED, OR THAT THE WEBSITE, SERVICE
                                            AND/OR THE WEBSITE CONTENT WILL BE ERROR FREE OR THAT
                                            ALL ERRORS WILL BE CORRECTED.
                                        </p>
                                        <p>
                                            (b) Limitation of Liability: TO THE FULLEST EXTENT
                                            PERMITTED BY APPLICABLE LAW, YOU AGREE THAT DearData
                                            SHALL NOT BE LIABLE TO YOU FOR ANY (A) INDIRECT,
                                            INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, EXEMPLARY
                                            OR STATUTORY DAMAGES (INCLUDING, WITHOUT LIMITATION,
                                            LOSS OF BUSINESS, LOSS OR PROFITS, LOSS OF REVENUE, LOSS
                                            OF DATA, LOSS OF GOODWILL OR FOR ANY COST OF COVER OR
                                            COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES),
                                            EVEN IF DEARDATA HAS BEEN ADVISED OF THE POSSIBILITY OF
                                            SUCH DAMAGES, AND REGARDLESS OF THE LEGAL THEORY UNDER
                                            WHICH DAMAGES ARE SOUGHT, WHETHER IN BREACH OF CONTRACT
                                            OR IN TORT, INCLUDING NEGLIGENCE OR (B) AMOUNTS IN THE
                                            AGGREGATE THAT EXCEED THE FEES PAID BY YOU TO DearData
                                            HEREUNDER IN THE SIX (6) MONTHS PRECEDING THE DATE THE
                                            CLAIM AROSE.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon teal"></div>
                                            <div className="title">Privacy Policy</div>
                                        </div>
                                        <p>
                                            You acknowledge that you have read, understand and agree
                                            to DearData’s Privacy Policy located at
                                            <a
                                                href="http://adastronaut.com/privacy"
                                                target="_blank"
                                            >
                                                {" "}
                                                http://adastronaut.com/privacy
                                            </a>
                                            , which is hereby incorporated into and made a part of
                                            these Terms and Conditions by this reference.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon orange"></div>
                                            <div className="title">Location of the Site and your Use</div>
                                        </div>
                                        <p>
                                            DearData operates or controls the operation of this Site
                                            and the Platform from offices in the United States.
                                            DearData makes no representation or warranty that all of
                                            the features of this Site or Platform will be available
                                            to you outside of the United States, or that they are
                                            permitted to be accessed outside of the United States.
                                            Additionally, the Site may not be helpful or relevant to
                                            all international users. You acknowledge that you are
                                            solely responsible for any decision by you to use this
                                            Site and/or the Platform from other locations, and that
                                            such use may be subject to, and that you are responsible
                                            for, compliance with applicable local laws.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon orange"></div>
                                            <div className="title">Electronic Communications</div>
                                        </div>
                                        <p>
                                            When you use the Platform, or send emails, text
                                            messages, and other communications from your computer or
                                            mobile device to us, you are communicating with us
                                            electronically. You consent to receive communications
                                            from us electronically. We will communicate with you in
                                            a variety of ways, including but not limited to email,
                                            text, or by posting notices and messages on this Site.
                                            Communications will be directed to the billing contact
                                            information you provide in your User Account. You agree
                                            that all agreements, notices, disclosures, and other
                                            communications that we provide to you electronically
                                            satisfy any legal requirement that such communications
                                            be in writing. If you have a dispute with DearData or
                                            wish to provide a notice under these Terms and
                                            Conditions, you must promptly send written notice to
                                            DearData at
                                            <a href="mailto:help@adastronaut.com">
                                                {" "}
                                                help@adastronaut.com
                                            </a>
                                            .
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon blue"></div>
                                            <div className="title">Arbitration</div>
                                        </div>
                                        <p>
                                            At Company’s sole discretion, it may require you to
                                            submit any disputes arising from the use of these Terms
                                            or the Platform, including disputes arising from or
                                            concerning their interpretation, violation, invalidity,
                                            non-performance, or termination, to final and binding
                                            arbitration under the Rules of Arbitration of the
                                            American Arbitration Association applying Delaware law.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon yellow"></div>
                                            <div className="title">General</div>
                                        </div>
                                        <p>
                                            These Terms and Conditions constitute the entire
                                            agreement and understanding between the parties
                                            concerning the subject matter hereof, notwithstanding
                                            any different or additional terms that may be contained
                                            in the form of purchase order or other document used by
                                            you to place orders or otherwise effect transactions
                                            hereunder, which such terms are hereby rejected. Neither
                                            party may assign these Terms and Conditions without the
                                            prior written approval of the other, such approval not
                                            to be unreasonably withheld or delayed, provided that
                                            such approval shall not be required in connection with
                                            an assignment to an affiliate or to a successor to
                                            substantially all of such party’s assets or business
                                            related to these Terms and Conditions. These Terms and
                                            Conditions supersede all prior or contemporaneous
                                            discussions, proposals and agreements between you and
                                            DearData relating to the subject matter hereof. No
                                            amendment, modification or waiver of any provision of
                                            these Terms and Conditions will be effective unless in
                                            writing and signed by an authorized representative of
                                            both parties. If any provision of these Terms and
                                            Conditions is held to be invalid or unenforceable, the
                                            remaining portions will remain in full force and effect
                                            and such provision will be enforced to the maximum
                                            extent possible so as to effect the intent of the
                                            parties and will be reformed to the extent necessary to
                                            make such provision valid and enforceable. No waiver of
                                            rights by either party may be implied from any actions
                                            or failures to enforce rights under these Terms and
                                            Conditions. These Terms and Conditions are intended to
                                            be and are solely for the benefit of DearData and you
                                            and do not create any right in favor of any third party.
                                            These Terms and Conditions will be governed by and
                                            construed in accordance with the laws of the State of
                                            Delaware, without reference to its conflict of laws
                                            principles. The Uniform Computer Information
                                            Transactions Act will not apply to this Agreement. All
                                            disputes arising out of or relating to these Terms and
                                            Conditions will be submitted to the exclusive
                                            jurisdiction of a court of competent jurisdiction
                                            located in Los Angeles, California, and each party
                                            irrevocably consents to such personal jurisdiction and
                                            waives all objections to this venue.
                                        </p>
                                        <Divider />
                                        <div className="title-container">
                                            <div className="circle-icon teal"></div>
                                            <div className="title">Your Comments and Concerns</div>
                                        </div>
                                        <p>
                                            All feedback, comments, requests for technical support
                                            and other communications relating to the Platform should
                                            be directed to:
                                            <a href="mailto:help@adastronaut.com">
                                                {" "}
                                                help@adastronaut.com
                                            </a>
                                            .
                                        </p>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>

                </Container>
            </section>
        </Visibility>
    );
};

export default TermsContainer;
