import { Segment, Grid, Button, Card, Icon, Image, Dropdown, Form, Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import {observer} from "mobx-react-lite";
import * as React from "react";
import appStore from "_/stores";

import "./AmazonSync.less";
import RegistrationStore from "../../../stores/RegistrationStore";


/**
 * @component
 * @description Component for syncing with Amazon APIs
 */
const RegionAuth = ({region}) => {
    const { settingsStore, registrationStore } = appStore;

    // const regionSelection = () => {
    //     const options = registrationStore.getRegionOptions();
    //     let authenticated = false;
    //
    //     registrationStore.userRegistration.regions.forEach(region => {
    //         authenticated = region.countries.some(country => {
    //             return country.auth.length > 0;
    //         });
    //         if (authenticated) {
    //             options.forEach(option => {
    //                 if (option.key === region.name) {
    //                     option.disabled = true;
    //                 }
    //             });
    //         }
    //     });
    //
    //     const disabled = region.countries.some(country => {
    //         return country.auth.length > 0;
    //     });
    //
    //     const displayedRegion = () => {
    //         if (region.name) {return region.name;} else if (registrationStore.selectedRegion) {
    //             return registrationStore.selectedRegion;
    //         }
    //         registrationStore.handleRegionSelect(options.find(option => !option.disabled).key);
    //         return registrationStore.selectedRegion;
    //     };
    //
    //     return (
    //         <Grid>
    //             <Grid.Row>
    //                 <Grid.Column width={3} />
    //                 <Grid.Column width={10}>
    //                     <Form>
    //                         <Form.Field inline>
    //                             <label>Your Region</label>
    //                             <Dropdown
    //                                 placeholder="Select your Amazon Region"
    //                                 selection
    //                                 fluid
    //                                 onChange={(e, data) => registrationStore.handleRegionSelect(data.value)}
    //                                 value={displayedRegion()}
    //                                 options={options}
    //                                 disabled={disabled}
    //                             />
    //                         </Form.Field>
    //                     </Form>
    //                 </Grid.Column>
    //             </Grid.Row>
    //         </Grid>
    //     );
    // };

    const isAdLinked = () => {
        if (region.hasOwnProperty("name")) {
            return region.countries.some(country => {
                return country.auth.includes("amazon_ad");
            });
        }
        return false;
    };

    // const isSpLinked = () => {
    //     if (region.hasOwnProperty("name")) {
    //         return region.countries.some(country => {
    //             return country.auth.includes("amazon_sp");
    //         });
    //     }
    //     return false;
    // };
    // const testing_function = () => {
    //     console.log(RegistrationStore.selectedRegion, "registration store data");
    // };
    return (
        <React.Fragment>
            {/*{regionSelection()}*/}
            {/*<Button*/}
            {/*    onClick={testing_function}>*/}
            {/*    click test*/}
            {/*</Button>*/}
            <Grid stackable>
                <Grid.Row textAlign="center">
                    <Grid.Column width={1} />
                    <Grid.Column width={10} textAlign="center" className="div-alignment">
                        <Card fluid className="link-card">
                            <Card.Content>
                                <Card.Header className="link-card-header">
                                    <Grid>
                                        <Grid.Column verticalAlign="middle" textAlign="left" className="link-name" width={12}>
                                            Amazon Advertising
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            { isAdLinked() ? (
                                                <Image src="../../assets/images/icons/green-check.svg" />
                                            ) : <Image src="../../assets/images/icons/white-check.svg" /> }
                                        </Grid.Column>
                                    </Grid>
                                </Card.Header>
                            </Card.Content>
                            <Card.Content className="link-card-body">
                                <p>
                                    Ad Astronaut will securely access and store your Amazon Advertising data, giving you complete ownership over your ad data.
                                </p>
                                <p>
                                    <Icon name="exclamation circle" className="orange" />This step is required.
                                </p>

                                <Button
                                    as={"a"}
                                    fluid
                                    href={settingsStore.amazonAdUrl}
                                    referrerPolicy="no-referrer"
                                    size="large"
                                    disabled={isAdLinked()}
                                    className={!isAdLinked() ? "blue" : ""}
                                >
                                    Link with Amazon Advertising
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                    {/*<Grid.Column width={7} textAlign="center">*/}
                    {/*    <Card fluid className="link-card">*/}
                    {/*        <Card.Content>*/}
                    {/*            <Card.Header className="link-card-header">*/}
                    {/*                <Grid>*/}
                    {/*                    <Grid.Column verticalAlign="middle" textAlign="left" className="link-name" width={12}>*/}
                    {/*                        Amazon Seller Central*/}
                    {/*                    </Grid.Column>*/}
                    {/*                    <Grid.Column width={4}>*/}
                    {/*                        { isSpLinked() ? (*/}
                    {/*                            <Image src="../../assets/images/icons/green-check.svg" />*/}
                    {/*                        ) : <Image src="../../assets/images/icons/white-check.svg" /> }*/}
                    {/*                    </Grid.Column>*/}
                    {/*                </Grid>*/}
                    {/*            </Card.Header>*/}
                    {/*        </Card.Content>*/}
                    {/*        <Card.Content className="link-card-body">*/}
                    {/*            <p>*/}
                    {/*                Ad Astronaut will securely access and store Amazon Seller Central data relevant and complementary to your advertising data.*/}
                    {/*            </p>*/}
                    {/*            <p>*/}
                    {/*                <Icon name="exclamation circle" className="orange" />This step is required.*/}
                    {/*            </p>*/}
                    {/*            <Button*/}
                    {/*                as={"a"}*/}
                    {/*                fluid*/}
                    {/*                href={settingsStore.amazonSPUrl}*/}
                    {/*                referrerpolicy="no-referrer"*/}
                    {/*                size="large"*/}
                    {/*                disabled={isSpLinked()}*/}
                    {/*                className={!isSpLinked() ? "blue" : ""}*/}
                    {/*            >*/}
                    {/*                Link with Amazon Seller Central*/}
                    {/*            </Button>*/}
                    {/*        </Card.Content>*/}
                    {/*    </Card>*/}
                    {/*</Grid.Column>*/}
                    {/*<Grid.Column width={1} />*/}
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
};

RegionAuth.propTypes = {
    region: PropTypes.object.isRequired,
};

export default observer(RegionAuth);
