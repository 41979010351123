import * as React from "react";
import {Container, Grid, Divider, Message} from "semantic-ui-react";
// import appStore from "../../../stores";
// import  "./ResetEmailConfirm.less";
import api from "../../../../../data-loader/api";
import { routerStore } from "mr-router5";
import appStore from "../../../../../stores";

// getting data from template
const url = window.location.href.split("link=")[1];
// calling backend api for email update
const saveEmailBackend = () => {
    // splitting url for data
    if (url) {
        var temp = url.split("new_email=")[1];
        var email = temp.split("&user_id=")[0];
        var user_id = temp.split("&user_id=")[1];
        const data = {
            new_email:email,
            user_id:user_id,
        };
        try {
            api.user.saveNewEmail(data);
            appStore.userStore.credentials.email_reset = true;
            routerStore.router.navigate("login");
        } catch (err) {
            console.log(err);
            alert("there is some error in updating your email try again later.");
        }
    } else {
        alert("Invalid request");
    }
};
const ResetConfirmContainer = () => (
    <section id="email-confirm">
        {saveEmailBackend()}
        <Container fluid textAlign="center" className="">
            <Grid>
                <Grid.Row>
                    <Grid.Column >
                        <Message success style={{textAlign: "center", marginBottom: "3em"}}>
                        Verification email sent successfully.
                        </Message>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </section>
);

export default ResetConfirmContainer;
