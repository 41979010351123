import {action, computed, observable} from "mobx";
import api from "../data-loader/api";
import { routerStore } from "mr-router5";

class PartnerStore {
    @observable token = {};

    constructor(appStore) {
        this.appStore = appStore;
    }

    userObj = {
        first_name: "",
        last_name: "",
        is_software_partner:"",
        email: "",
        plan: {
            name: "",
            frequency: "",
            is_active: "",
        },
        regions: {},
    };

    @observable user = Object.assign({}, this.userObj);

    credentials = {
        email: "",
        password: "",
        password_reset: false,
    };

    registration = {
        first_name: "",
        last_name: "",
        software_partner:"",
        email: "",
        password: "",
        password_confirm: "",
    };


    @observable resetEmail = {
        email: "",
        sent: false,
    };

    @observable newPassword = {
        ddpr: "",
        password: "",
        password_confirm: "",
    };

    @observable registrationErrors = [];
    @observable invalidLogin = false;
    @observable newPasswordErrors = [];

    @computed
    get isAuthenticated() {
        return this.user.email !== "";
    }

    @computed
    get isNewActivation() {
        return this.user.is_new_activation;
    }

    @computed
    get fullName() {
        return `${this.user.first_name} ${this.user.last_name}`;
    }
    @computed
    get id() {
        return `${this.user.email}`;
    }
    @action
    setUser(userData) {
        this.user = userData;
    }

    @action
    register() {
        const { loaderStore } = this.appStore;
        this.registrationErrors = [];
        if (this.validateForm()) {
            loaderStore.start("register", true);
            return api.softwarepartner.register(this.registration)
                .then((resp) => {
                    this.setUser(resp.data);
                    loaderStore.stop("register");
                    alert(resp.data);
                    routerStore.router.navigate("login");
                },
                (error) => {
                    loaderStore.stop("register");
                    this.registrationErrors.push("this email is already taken try different one");
                    console.log(error);
                    // Do nothing if use is not authenticated
                });
        }
    }

    @action
    resetPasswordEmail() {
        return api.softwarepartner.resetPasswordEmail(this.resetEmail)
            .then((resp) => {
                this.resetEmail.sent = true;
            },
            (error) => {
                this.resetEmail.sent = true;
            });
    }

    @action
    resetPassword() {
        if (this.validatePassword()) {
            return api.softwarepartner.resetPassword(this.newPassword)
                .then((resp) => {
                    this.setUser(resp.data);
                    this.credentials.password_reset = true;
                    routerStore.router.navigate("login");
                },
                (error) => {
                    console.log(error);
                    // Do nothing if use is not authenticated
                });
        }
    }

    @action
    googleAuth() {
        return api.softwarepartner.googleAuth()
            .then((resp) => {
                window.location.href = resp.data.url;
            },
            (error) => {
                console.log(error);
                // Do nothing if use is not authenticated
            });
    }

    // Validation
    validateForm() {
        this.registrationErrors = [];
        let isValid = true;

        if (!this.registration.first_name) {
            this.registrationErrors.push("First name is required.");
            isValid = false;
        }
        if (!this.registration.last_name) {
            this.registrationErrors.push("Last name is required.");
            isValid = false;
        }
        if (!this.registration.software_partner) {
            this.registrationErrors.push("Partner name is required.");
            isValid = false;
        }
        if (!this.registration.email) {
            this.registrationErrors.push("Email is required.");
            isValid = false;
        }
        if (!this.registration.password) {
            this.registrationErrors.push("Password is required");
            isValid = false;
        }
        if (this.registration.password !== this.registration.password_confirm) {
            this.registrationErrors.push("Passwords do not match");
            isValid = false;
        }
        if (this.registration.password.length < 8) {
            this.registrationErrors.push("Password has to be at least 8 characters");
            isValid = false;
        }
        if (this.registration.password.includes(" ")) {
            this.registrationErrors.push("Password cannot contain spaces");
            isValid = false;
        }
        return isValid;
    }

    validatePassword() {
        this.newPasswordErrors = [];
        let isValid = true;


        if (!this.newPassword.password) {
            this.newPasswordErrors.push("Password is required");
            isValid = false;
        }
        if (this.newPassword.password !== this.newPassword.password_confirm) {
            this.newPasswordErrors.push("Passwords do not match");
            isValid = false;
        }
        return isValid;
    }

    @action
    async preload() {
        try {
            await this.fetchSignedUrl();
        } catch (err) {
            console.log(err);
        }
    }
}

export default PartnerStore;
