import * as React from "react";
import { observer } from "mobx-react-lite";
import {RouteComponent} from "mr-router5";

import Header from "./Header/Header.jsx";
import Footer from "./Footer/Footer.jsx";

const routeNodeName = "";

const Layout = observer(() => {
    return (
        <div className="main-wrapper">
            <div className="main-content">
                <Header />
                <RouteComponent routeNodeName={routeNodeName} />
            </div>
            <Footer />
        </div>
    );
});

export default Layout;
