import * as React from "react";
import { Container, Grid, Visibility } from "semantic-ui-react";

import appStore from "_/stores";
import "./EmailConfirm.less";

const EmailConfirmContainer = () => {
    const { homeStore } = appStore;

    const handleVisibility = (calculations) => {
        // increase the pixel passed for this page so the black header always shows
        if (calculations.pixelsPassed > 100) {
            homeStore.setShowSolidNav(true);
        } else {
            homeStore.setShowSolidNav(false);
        }
    };

    return (
        <Visibility
            fireOnMount={(e, { calculations }) => handleVisibility(calculations)}
            onUpdate={(e, { calculations }) => handleVisibility(calculations)}
        >
            <section id="email-confirm">
                <Container fluid className="email-confirm-container">
                    <Container textAlign="center" className="section-title">
                        <div className="section-header">
                            <h1>Almost done!</h1>
                        </div>
                    </Container>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={5} />
                            <Grid.Column width={6} textAlign="center">
                                <div className="email-confirm-message">
                                    <p>
                                        Please check your email ({ appStore.userStore.registration.email }) to confirm your account.
                                    </p>
                                    <p>
                                        If you haven't received our email in 15 minutes, <br />
                                        please check your spam folder.
                                    </p>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </section>
        </Visibility>
    );
};

export default EmailConfirmContainer;
