// import { action, computed, get, observable, toJS } from 'mobx';
// import api from '../data-loader/api';
// import { routerStore } from 'mr-router5';

import { observable, action } from "mobx";

class HomeStore {
    @observable showSolidNav = true;

    @action
    setShowSolidNav(value) {
        this.showSolidNav = value;
    }

    items = [
        {
            header: "Super fast setup",
            description:
                "It takes less than 3 minutes to take ownership of your ad data. \n" +
                "Simply create an account and add your store.",
            meta: "Just authenticate your account",
            image:
                "https://react.semantic-ui.com/images/wireframe/white-image.png",
            color: "teal",
        },
        {
            header: "Fully automated",
            description:
                "We store your data daily and manage all system updates and data changes \n" +
                "from Amazon. You don't have to do a thing.",
            meta: "Zero management required",
            image:
                "https://react.semantic-ui.com/images/wireframe/white-image.png",
            color: "teal",
        },
        {
            header: "Compatible and portable",
            description:
                "We store all your data using Amazon's original data format. \n" +
                "That means any AdTech provider can ingest your data.",
            meta: "See our Certified Partners",
            image:
                "https://react.semantic-ui.com/images/wireframe/white-image.png",
            color: "teal",
        },
        {
            header: "Apocalypse-ready",
            description:
                "99.999999999% data durability means your data is secure from any threats, \n" +
                "physical or digital.",
            meta: "Best-in-class reliability and redundancy",
            image:
                "https://react.semantic-ui.com/images/wireframe/white-image.png",
            color: "teal",
        },
    ];
}

export default HomeStore;
