import * as React from "react";
import { observer } from "mobx-react-lite";
import { Container, Menu, Button, Dropdown, Responsive } from "semantic-ui-react";
import { routerStore } from "mr-router5";

import Logo from "_/components/Common/Logo/Logo.jsx";

import appStore from "_/stores";
import "./header.less";

export default observer(() => {
    const { homeStore, userStore } = appStore;
    const handleSignOut = () => {
        userStore.logout();
    };

    const handleAccount = () => {
        routerStore.router.navigate("account");
    };

    const displaySignIn = () => {
        if (routerStore.route.name === "login") {
            return (
                <React.Fragment>
                    <span className={`${getHeaderClass()}-menu-text`}>Don't have an account?</span>
                    {getMenuItem("Get Started", "subscribe", "button")}
                </React.Fragment>
            );
        }
        if (routerStore.route.name === "subscribe") {
            return (
                <React.Fragment>
                    <span className={`${getHeaderClass()}-menu-text`}>Already have an account?</span>
                    {getMenuItem("Log In", "login", "button")}
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                {getMenuItem("Log In", "login")}
                {getMenuItem("Get Started", "subscribe", "button")}
            </React.Fragment>
        );
    };

    const displayAccount = () => {
        return (
            <React.Fragment>
                <Dropdown item text={userStore.fullName}>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            icon="setting"
                            text="Account Settings"
                            onClick={() => handleAccount()}
                        />
                        <Dropdown.Item
                            icon="hand peace"
                            text="Sign Out"
                            onClick={() => handleSignOut()}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </React.Fragment>
        );
    };

    const getMenuItem = (title, routeName, type) => {
        const displayItem = () => {
            return type === "button" ? (
                <Button size="huge" className={homeStore.showSolidNav ? "blue hide" : "white hide"}>{title}</Button>
            ) : (
                title
            );
        };


        // black magic to fix the Login text coloration on a light theme home page
        if (title === "Log In" && !homeStore.showSolidNav) {
            return (
                <Menu.Item
                    as={Button}
                    size="huge"
                    className={`transparent-menu-item mobile`}
                    name={routeName}
                    active={routerStore.route.name === routeName}
                    onClick={() => {
                        routerStore.router.navigate(routeName);
                    }}
                >
                    {displayItem()}
                </Menu.Item>
            );
        }

        // mobile className add to remove certain elements from header
        if (title === "Log In" || title === "Pricing" || title === "About") {
            return (
                <Menu.Item
                    as={Button}
                    size="huge"
                    className={`${getHeaderClass()}-menu-item mobile`}
                    name={routeName}
                    active={routerStore.route.name === routeName}
                    onClick={() => {
                        routerStore.router.navigate(routeName);
                    }}
                >
                    {displayItem()}
                </Menu.Item>
            );
        }

        return (
            <Menu.Item
                as={Button}
                size="huge"
                className={`${getHeaderClass()}-menu-item`}
                name={routeName}
                active={routerStore.route.name === routeName}
                onClick={() => {
                    routerStore.router.navigate(routeName);
                }}
            >
                {displayItem()}
            </Menu.Item>
        );
    };

    //TODO: Website vs Webapp links

    const getHeaderClass = () => {
        const darkPages = ["subscribe", "terms", "privacy", "emailconfirm", "welcome"];
        if (!darkPages.includes(routerStore.route.name) && routerStore.route.name !== "home" ) {
            return "solid";
        }

        // added an exception for light theme home page that requires a partial option
        if (routerStore.route.name === "home") {
            return homeStore.showSolidNav ? "solid" : "partial";
        }

        return homeStore.showSolidNav ? "solid" : "transparent";
    };

    const displayStaticHeader = () => {
        const displayGoToDashboard = () => {
            return (
                <React.Fragment>
                    { getMenuItem("Go to Dashboard", "app", "button") }
                </React.Fragment>
            );
        };

        return (
            <React.Fragment>
                {getMenuItem("Pricing", "pricing")}
                {getMenuItem("About", "about")}
                <Menu.Menu position="right">
                    {
                        userStore.isAuthenticated && !userStore.isNewActivation ? displayGoToDashboard() : displaySignIn()
                    }
                </Menu.Menu>
            </React.Fragment>
        );
    };

    const displayAppHeader = () => {
        return (
            <React.Fragment>
                { routerStore.route.name !== "setup" ? (
                    <React.Fragment>
                        {getMenuItem("Dashboard", "dashboard")}
                        <Menu.Menu position="right">
                            { displayAccount() }
                        </Menu.Menu>
                    </React.Fragment>
                ) : null }
            </React.Fragment>
        );
    };

    return (
        <Menu borderless className={`site-header ${getHeaderClass()}-background`}>
            <Container>
                <Menu.Item
                    as={Button}
                    onClick={() => routerStore.router.navigate("home")}
                    className={`${getHeaderClass()}-menu-item`}
                >
                    <Logo />
                </Menu.Item>
                {/*{*/}
                {/*    routerStore.route.path.includes("app") ? displayAppHeader() : displayStaticHeader()*/}
                {/*}*/}
                <Responsive as={Menu.Menu} maxWidth={687}  position="right">
                    <Dropdown
                        item
                        icon="bars"
                        className={`${getHeaderClass()}-hamburger`}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item text="Home" onClick={() => routerStore.router.navigate("home")}/>
                            <Dropdown.Item text="About" onClick={() => routerStore.router.navigate("about")}/>
                            <Dropdown.Item text="Pricing" onClick={() => routerStore.router.navigate("pricing")}/>
                            <Dropdown.Item text="Get Started" onClick={() => routerStore.router.navigate("subscribe")}/>
                            <Dropdown.Item text="Login" onClick={() => routerStore.router.navigate("login")}/>
                        </Dropdown.Menu>
                    </Dropdown>
                </Responsive>
            </Container>
        </Menu>
    );
});
