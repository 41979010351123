import React from "react";
import {observer} from "mobx-react-lite";
import { Accordion, Icon, Grid, Transition } from "semantic-ui-react";
import appStore from "../../../stores";

const Faqs = () => {
    const { pricingStore } = appStore;

    const buildPanels = () => {
        return pricingStore.faqs.map((faq, index) => {
            const isActive = pricingStore.faqActiveIndex === index;
            return (
                <React.Fragment key={faq.key}>
                    <Accordion.Title
                        active={isActive}
                        index={index}
                        onClick={(e, data) => pricingStore.setFaqActiveIndex(data.index)}
                        className={!isActive ? "accordion-title" : ""}
                    >
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <h3 className="light" style={{ display: "inline" }}>{faq.title}</h3>
                                </Grid.Column>
                                <Grid.Column width={1} verticalAlign="middle" textAlign="left">
                                    <Icon size="big" color="blue" name={isActive ? "minus" : "plus"} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Accordion.Title>
                    <Accordion.Content className={isActive ? "accordion-content" : ""} active={isActive}>
                        <Transition animation="fade in" visible={isActive}>
                            <p>{faq.content}</p>
                        </Transition>
                    </Accordion.Content>
                </React.Fragment>
            );
        });
    };

    return (
        <div className="faqs">
            {/* <h2>Frequently asked questions</h2> */}
            <Accordion className="faq-accordion">
                {buildPanels()}
            </Accordion>
        </div>
    );
};

export default observer(Faqs);
