import * as React from "react";
import { routerStore } from "mr-router5";
import { Container, Grid, Button, Link, Image, Card, Divider, Visibility } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import PlanetFooter from "../../Common/PlanetFooter/PlanetFooter.jsx";
import AnnouncementBar from "../../Common/AnnouncementBar/AnnouncementBar.jsx";

import "./home.less";
import appStore from "../../../stores";


const HomeContainer = () => {
    const { homeStore } = appStore;

    const buildCardGroup = () => {
        return (
            <Grid.Column textAlign="left" width={12} verticalAlign="middle">
                <Card.Group items={homeStore.items} itemsPerRow={2} />
            </Grid.Column>
        );
    };

    const handleVisibility = (calculations) => {
        // looks like 86 is the starting pixel point for the container
        if (calculations.pixelsPassed > 67) {
            homeStore.setShowSolidNav(true);
        } else {
            homeStore.setShowSolidNav(false);
        }
    };

    const handleCallToAction = () => {
        routerStore.router.navigate("subscribe");
    };

    return (
        <Visibility
            fireOnMount={(e, { calculations }) => handleVisibility(calculations)}
            onUpdate={(e, { calculations }) => handleVisibility(calculations)}
        >
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <section id="home">
                <Container fluid className="jumbo-section-wrapper intro">
                    <div style={{top: "146px", position:"absolute", width: "100%"}}>
                        <AnnouncementBar />
                    </div>
                    <Container className="intro-container">
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <h1>Move your Amazon ad data to any platform with zero data loss.</h1>
                                    <p>
                                        Automatic backups of your complete ad history so you can port your data to any advertising software.
                                    </p>
                                    <Button
                                        size="huge"
                                        as="a"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="on-hover blue call-to-action-btn"
                                        onClick={handleCallToAction}
                                    >
                                        Start with Free Forever
                                    </Button>
                                </Grid.Column>
                                <Grid.Column width={1}/>
                                <Grid.Column width={7} className="hero-image" verticalAlign="middle">
                                    <Image src="../../../assets/images/home/light-hero-1.svg" />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Container>

                <Container fluid className="light-spacer" />

                <Container fluid className=" jumbo-section top-brands-wrapper">
                    <Container className="top-brands">
                        <Grid columns={4} doubling centered>
                            <Grid.Row>
                                <h2>Trusted by Top Brands</h2>
                            </Grid.Row>
                            <Divider hidden />
                            <Grid.Row>
                                <Grid.Column>
                                    <Image className="logo-farm" src="../../../assets/images/logos/logo-1.png" centered />
                                </Grid.Column>
                                <Grid.Column textAlign="center">
                                    <Image className="logo-farm" src="../../../assets/images/logos/logo-2.png" centered />
                                </Grid.Column>
                                <Grid.Column textAlign="center">
                                    <Image className="logo-farm" src="../../../assets/images/logos/logo-3.png" centered />
                                </Grid.Column>
                                <Grid.Column textAlign="center">
                                    <Image className="logo-farm" src="../../../assets/images/logos/logo-4.png" centered />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row textAlign="center">
                                <Grid.Column>
                                    <Image className="logo-farm" src="../../../assets/images/logos/logo-5.png" centered />
                                </Grid.Column>
                                <Grid.Column>
                                    <Image className="logo-farm" src="../../../assets/images/logos/logo-6.png" centered />
                                </Grid.Column>
                                <Grid.Column>
                                    <Image className="logo-farm" src="../../../assets/images/logos/logo-7.png" centered />
                                </Grid.Column>
                                <Grid.Column>
                                    <Image className="logo-farm" src="../../../assets/images/logos/logo-8.png" centered />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Container>

                <Container fluid className="light-dark">
                    <Container className="problem-statements">
                        <Grid stackable className="problem-grid">
                            <Grid.Column width={7} className="problem-left">
                                <div className="arrow-left" />
                                <h2>Ad Astronaut prevents catastrophes before they happen.</h2>
                                <a target="_blank" rel="noopener noreferrer" onClick={handleCallToAction} className="call-to-action-link">Protect your data</a>
                            </Grid.Column>
                            <Grid.Column width={9} className="problem-right">
                                <div className="block-quote">
                                    <p>We wanted to upgrade to a leading ad automation software last year, but we would have lost 4 years of campaign data if we switched.<Image className="block-icon" src="../../../assets/images/icons/block-quote.svg" /></p>
                                </div>
                                <div className="block-quote">
                                    <p>We're investing over half a million each month for keyword and product target discovery. It's impossible to harvest or store the amount of data coming in.<Image className="block-icon" src="../../../assets/images/icons/block-quote.svg" /></p>
                                </div>
                                <div className="block-quote">
                                    <p>Our team developed an in-house system to help manage spend. Too bad we couldn't include most of our historical ad data due to API date limits.<Image className="block-icon" src="../../../assets/images/icons/block-quote.svg" /></p>
                                </div>
                            </Grid.Column>
                        </Grid>

                        {/*<Grid stackable className="problem-grid">*/}
                        {/*    <Grid.Column width={7} className="problem-left" stretched>*/}
                        {/*        <div className="arrow-left"></div>*/}
                        {/*        <Grid.Row>*/}
                        {/*            <h2>Ad Astronaut prevents catastrophes before they happen.</h2>*/}
                        {/*        </Grid.Row>*/}
                        {/*        <Grid.Row className="link-spacer">*/}
                        {/*            <p*/}
                        {/*                as="a"*/}
                        {/*                className="link"*/}
                        {/*                target="_blank"*/}
                        {/*                rel="noopener noreferrer"*/}
                        {/*                onClick={handleCallToAction}*/}
                        {/*            >*/}
                        {/*            Protect your data.*/}
                        {/*            </p>*/}
                        {/*        </Grid.Row>*/}
                        {/*    </Grid.Column>*/}
                        {/*    <Grid.Column width={9} className="problem-right" stretched>*/}
                        {/*        <Grid.Row className="block-quote">*/}
                        {/*            <p>We wanted to upgrade to a leading ad automation software last year, but we would have lost 4 years of campaign data if we switched.*/}
                        {/*                <Image className="block-icon" src="../../../assets/images/icons/block-quote.svg" />*/}
                        {/*            </p>*/}
                        {/*        </Grid.Row>*/}
                        {/*        <Grid.Row className="block-quote mid-spacer">*/}
                        {/*            <p>We're investing over half a million each month for keyword and product target discovery. It's impossible to harvest or store the amount of data coming in.*/}
                        {/*                <Image className="block-icon" src="../../../assets/images/icons/block-quote.svg" />*/}
                        {/*            </p>*/}
                        {/*        </Grid.Row>*/}
                        {/*        <Grid.Row className="block-quote">*/}
                        {/*                */}
                        {/*            <p>*/}
                        {/*                    Our team developed an in-house system to help manage spend. Too bad we couldn't include most of our historical ad data due to API date limits.*/}
                        {/*                <Image className="block-icon" src="../../../assets/images/icons/block-quote.svg" />*/}
                        {/*            </p>*/}
                        {/*        </Grid.Row>*/}
                        {/*    </Grid.Column>*/}
                        {/*</Grid>*/}
                    </Container>
                </Container>

                <Container fluid className="light-wrapper jumbo-section">
                    <Container className="reasons">
                        <Grid stackable columns="equal">
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <h2>3 massive reasons to own your data</h2>
                                </Grid.Column>
                            </Grid.Row>
                            <Divider hidden />
                            <Grid.Row>
                                <Grid.Column>
                                    <Image src="../../../assets/images/home/icon-1.svg" height="50px" />
                                    <h4>Supercharge your ROAS</h4>
                                    <p>
                                        Giving your ad software your full history means smarter bids and targeting, and lower ACoS, especially during holidays.
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <Image src="../../../assets/images/home/icon-2.svg" height="50px" />
                                    <h4>Switch platforms with zero loss</h4>
                                    <p>
                                        The best ad software changes fast, so stay flexible. We help you avoid wiping your ad history when you migrate systems.
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <Image src="../../../assets/images/home/icon-3.svg" height="50px" />
                                    <h4>Own and protect your data</h4>
                                    <p>
                                        Amazon cuts you off from ad data older than 60 days. We save every data point in original form so your data is always compatible.
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Container>

                <Container fluid className="jumbo-section light2-wrapper" >
                    <Container className="the-best-service">
                        <Grid stackable>
                            <Grid.Row centered textAlign="center" className="spacer"><h2>Data ownership, fully managed</h2></Grid.Row>
                            <Grid.Row verticalAlign="middle" reversed="computer tablet">
                                <Grid.Column width={8}>
                                    <Image className="best-service-icon" src="../../../assets/images/home/icon-4.svg" />
                                </Grid.Column>
                                <Grid.Column width={1}/>
                                <Grid.Column width={6}>
                                    <h3>Super fast setup</h3>
                                    <p>
                                            You are less than 5 minutes away from owning your data
                                            permanently. Create an account, connect your
                                            Amazon store, done.
                                    </p>
                                </Grid.Column>
                                <Grid.Column width={1}/>
                            </Grid.Row>
                            <Grid.Row verticalAlign="middle">
                                <Grid.Column width={8}>
                                    <Image className="best-service-icon" src="../../../assets/images/home/icon-5b.svg" />
                                </Grid.Column>
                                <Grid.Column width={1}/>
                                <Grid.Column width={6}>
                                    <h3>Fully automated</h3>
                                    <p>
                                            We store your data daily and manage all API updates, downtime, and changes. You never have to do a thing.
                                    </p>
                                </Grid.Column>
                                <Grid.Column width={1}/>
                            </Grid.Row>
                            <Grid.Row verticalAlign="middle"  reversed="computer tablet">
                                <Grid.Column width={8}>
                                    <Image className="best-service-icon" src="../../../assets/images/home/icon-6.svg" />
                                </Grid.Column>
                                <Grid.Column width={1}/>
                                <Grid.Column width={6}>
                                    <h3>Compatible and portable</h3>
                                    <p>
                                            We store all your data using Amazon's original data
                                            format. That means any AdTech provider can ingest
                                            your data without compatibility concerns.
                                    </p>
                                </Grid.Column>
                                <Grid.Column width={1}/>
                            </Grid.Row>
                            <Grid.Row verticalAlign="middle">
                                <Grid.Column width={8}>
                                    <Image className="best-service-icon" src="../../../assets/images/home/icon-7.svg" />
                                </Grid.Column>
                                <Grid.Column width={1}/>
                                <Grid.Column width={6}>
                                    <h3>Apocalypse-ready</h3>
                                    <p>
                                            99.99999999% data durability means your data is
                                            secure from any threats, physical or digital.
                                    </p>
                                </Grid.Column>
                                <Grid.Column width={1}/>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Container>
                <PlanetFooter bg="gray" />
            </section>
        </Visibility>
    );
};

export default HomeContainer;
