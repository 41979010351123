import * as React from "react";
import { observer } from "mobx-react-lite";
import { routerStore} from "mr-router5";
import { Container, Grid, Form, Button, Segment, Message, Input, Divider, Visibility } from "semantic-ui-react";
import appStore from "_/stores";
import "./subscribe.less";



const SubscribeContainer = () => {
    const { homeStore } = appStore;

    const handleSubmit = () => {
        appStore.userStore.register();
    };

    const handleChange = (field, val) => {
        appStore.userStore.registration[field] = val;
    };

    const handleVisibility = (calculations) => {
        // looks like 86 is the starting pixel point for the container
        if (calculations.pixelsPassed > 66) {
            homeStore.setShowSolidNav(true);
        } else {
            homeStore.setShowSolidNav(false);
        }
    };

    const handleLinkClick = (e, route) => {
        e.preventDefault();
        routerStore.router.navigate(route);
    };

    return (
        <Visibility
            fireOnMount={(e, { calculations }) => handleVisibility(calculations)}
            onUpdate={(e, { calculations }) => handleVisibility(calculations)}
        >
            <section id="subscribe">
                <Container fluid textAlign="center" className="subscribe-container">
                    <Container textAlign="center" className="section-title">
                        <div className="section-header">
                            <h1>Create your account.</h1>
                            <p>Own your data. Take it anywhere.</p>
                        </div>
                    </Container>
                    <Container className="container-gutters">
                        <Grid className="subscribe-grid">
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Segment className="subscribe-segment">
                                        {
                                            appStore.userStore.registrationErrors.length > 0 ? (
                                                <Message
                                                    error
                                                    list={appStore.userStore.registrationErrors}
                                                    style={{marginBottom: "3em"}}
                                                />
                                            ) : null
                                        }
                                        <Form>
                                            <Form.Field>
                                                <label>First Name</label>
                                                <Input
                                                    placeholder="first name"
                                                    onChange={(e) => handleChange("first_name", e.target.value)}
                                                    size="huge"
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Last Name</label>
                                                <Input
                                                    placeholder="last name"
                                                    onChange={(e) => handleChange("last_name", e.target.value)}
                                                    size="huge"
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Store Name</label>
                                                <Input
                                                    placeholder="store name"
                                                    onChange={(e) => handleChange("store_name", e.target.value)}
                                                    size="huge"
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Email</label>
                                                <Input
                                                    placeholder="yours@example.com"
                                                    onChange={(e) => handleChange("email", e.target.value)}
                                                    type="email"
                                                    size="huge"
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Password</label>
                                                <Input
                                                    placeholder="password"
                                                    type="password"
                                                    onChange={(e) => handleChange("password", e.target.value)}
                                                    size="huge"
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Password Confirmation</label>
                                                <Input
                                                    placeholder="confirm your password"
                                                    type="password"
                                                    onChange={(e) => handleChange("password_confirm", e.target.value)}
                                                    size="huge"
                                                />
                                            </Form.Field>
                                            <Button
                                                className="blue create-account"
                                                onClick={() => handleSubmit()}
                                                size="huge"
                                            >
                                                Create my account
                                            </Button>
                                            <Divider />
                                            <div className="terms">
                                                <p>
                                                    By signing up you agree to our <a href="/terms" onClick={(e) => handleLinkClick(e, "terms")}>terms of service </a> and <a href="/privacy" onClick={(e) => handleLinkClick(e, "privacy")}>privacy policy</a>.
                                                </p>
                                            </div>
                                        </Form>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Container>
                <Container fluid className="planet" />
            </section>
        </Visibility>
    );
};

export default observer(SubscribeContainer);
