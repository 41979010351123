import * as React from "react";
import {Container, Grid, Divider} from "semantic-ui-react";
import appStore from "../../../stores";
import "./EmailConfirm.less";

const ResetConfirmContainer = () => (
    <section id="email-confirm">
        <Container fluid textAlign="center" className="email-confirm-container">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={5} />
                    <Grid.Column width={6} center>
                        <div className="header">
                            Email sent...
                        </div>
                        <p>Please check your email ({ appStore.userStore.resetEmail.email }) and follow the instructions to reset your password.</p>
                        <Divider />
                        <p>If you haven't received our email in 15 minutes, please check your spam folder.</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </section>
);

export default ResetConfirmContainer;
