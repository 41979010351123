import * as React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { Transition, Dimmer, Loader } from "semantic-ui-react";

import appStore from "_/stores";


const AppLoader = ({ active }) => {
    const { loaderStore } = appStore;
    const showLoader = (active || loaderStore.isPageWideLoaderActive());

    return (
        <Transition
            visible={showLoader}
            duration={200}
        >
            <Dimmer active={showLoader} inverted page className="app-loader">
                <Loader active={showLoader}>
                    Loading
                </Loader>
            </Dimmer>
        </Transition>
    );
};

AppLoader.propTypes = {
    active: PropTypes.bool,
};

AppLoader.defaultProps = {
    active: false,
};

export default observer(AppLoader);


