import ConstantsStore from "_/stores/ConstantsStore";
import DashboardStore from "_/stores/DashboardStore";
import HomeStore from "_/stores/HomeStore";
import LoaderStore from "_/stores/LoaderStore";
import PartnersStore from "_/stores/PartnersStore";
import PricingStore from "_/stores/PricingStore";
import RegistrationStore from "_/stores/RegistrationStore";
import ReportStore from "_/stores/ReportStore";
import SettingsStore from "_/stores/SettingsStore";
import UIStore from "_/stores/UIStore";
import UpgradeStore from "_/stores/UpgradeStore";
import UserStore from "_/stores/UserStore";
import PartnerStore from "_/stores/PartnerStore";

class AppStore {
    constructor() {
        this.constantsStore = new ConstantsStore(this);
        this.dashboardStore = new DashboardStore(this);
        this.homeStore = new HomeStore(this);
        this.loaderStore = new LoaderStore(this);
        this.partnersStore = new PartnersStore(this);
        this.pricingStore = new PricingStore(this);
        this.registrationStore = new RegistrationStore(this);
        this.reportStore = new ReportStore(this);
        this.settingsStore = new SettingsStore(this);
        this.uiStore = new UIStore(this);
        this.upgradeStore = new UpgradeStore(this);
        this.userStore = new UserStore(this);
        this.partnerStore = new PartnerStore(this);

    }
}

export default AppStore;
