import React from "react";
import { observer } from "mobx-react-lite";
// import { RouteComponent } from "mr-router5";

const AppContainer = () => {
    return (
        <div>
        </div>
    );
};

const ObsAppContainer = observer(AppContainer);
export default ObsAppContainer;
