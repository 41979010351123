import {Table, Icon, Button, Grid, GridRow, GridColumn, Segment, Form, Card} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";
import StripeModal from "../Account/Settings/Billing/StripeModal.jsx";
import appStore from "../../../stores";
import api from "../../../data-loader/api";
import {useEffect, useState} from "react";
import "./account.less";
import {Elements} from "@stripe/react-stripe-js";
import {v4 as uuidv4} from "uuid";
import {loadStripe} from "@stripe/stripe-js";


const buildFeaturesRow = (plan) => {
    const getFeatures = (features) => {
        return features.map(feature => {
            return (
                <div className="feature-item">
                    <Icon name="check" color="green" />{feature}
                </div>
            );
        });
    };
    return (
        <Table.Cell>
            {getFeatures(plan.features)}
        </Table.Cell>
    );
};

// set qualified tier
const getTier = (plan) => {
    if (plan === "standard") {
        return appStore.upgradeStore.standardTier;
    } else if (plan === "premium") {
        return appStore.upgradeStore.premiumTier;
    } else if (plan === "enterprise") {
        return appStore.upgradeStore.enterpriseTier;
    } else if (plan === "free") {
        return appStore.upgradeStore.freeTier;
    }
};

const getUpFrontCost = (tier, schedule) => {
    return tier.cost[schedule] * 12;
};
const getSavings = (tier) => {
    const monthlyCost = getUpFrontCost(tier, "monthly");
    const yearlyCost = getUpFrontCost(tier, "yearly");
    return ((monthlyCost - yearlyCost) / monthlyCost * 100).toFixed(2);
};


const UpgradePlanContainer =  () => {

    // setting stripe key
    appStore.upgradeStore.setPaymentKey();

    // using state variables for maintaining plan state and frequency
    const [qualifiedplan, setQualifiedPlan] = useState(null);
    const [currentplan, setCurrentPlan] = useState(appStore.upgradeStore.freeTier);

    // setting qualified plan for customer
    api.subscription.getqualifiedplan().then(response => {
        const {data} = response;
        setQualifiedPlan(getTier(data[1]));
        if (data[0]) {
            setCurrentPlan(getTier(data[0]));
        }
        console.log(data[0]);
    }).catch(error => {
        console.log(error);
    });


    const handleUpgrade = async (e, { value }) => {
        appStore.upgradeStore.selectedFrequency = value;
        appStore.upgradeStore.selectedTier = qualifiedplan;
        appStore.upgradeStore.togglePaymentModal(true);
    };

    const buildPlanCard = () => {
        return (
            <Card key={uuidv4()} className="setup-tier-card" fluid>
                <Card.Content className="card-header-container">
                    <Card.Header className="card-header">
                        { qualifiedplan ? (<h1><strong>{ qualifiedplan?.name }</strong></h1>) :
                            (<h1><strong>{ currentplan?.name }</strong></h1>)}
                    </Card.Header>
                </Card.Content>
                <Card.Content className="card-body-container">
                    <Grid stackable columns="equal">
                        <Grid.Column width={7} className="plan-fees-col">
                            <div className="feature-list">
                                { qualifiedplan ? (buildFeaturesRow(qualifiedplan)) :
                                    (buildFeaturesRow(currentplan))}

                            </div>
                        </Grid.Column>
                        {
                            qualifiedplan == null ? (
                                <Grid.Column className="plan-fees" width={9} textAlign="center">
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <div className="frequency">You are on qualified plan</div>
                                    <p>&nbsp;</p>

                                </Grid.Column>
                            ) : (
                                <React.Fragment>
                                    {
                                        currentplan.name !== "Free Forever" ? (
                                            <Grid.Column className="plan-fees" width={9} textAlign="center">
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <div className="frequency">Your plan will be update on billing date.</div>
                                                <p>&nbsp;</p>

                                            </Grid.Column>
                                        ) : (

                                            <Grid.Column className="plan-fees">
                                                <div className="frequency">Monthly</div>
                                                <div className="fees">${qualifiedplan.cost.monthly}<span>/mo</span></div>
                                                <p>&nbsp;</p>
                                                <Button
                                                    fluid size="large"
                                                    className="blue select-tier"
                                                    value="monthly"
                                                    onClick={handleUpgrade}
                                                >
                                            Select
                                                </Button>
                                            </Grid.Column>)}

                                    { currentplan.name === "Free Forever" ? (
                                        <Grid.Column className="plan-fees">
                                            <div className="frequency">Yearly</div>
                                            <div className="fees">${qualifiedplan.cost.yearly * 12}<span>/yr</span></div>
                                            <p>save <strong>{getSavings(qualifiedplan)}%</strong></p>
                                            <Button
                                                fluid size="large"
                                                className="blue select-tier"
                                                onClick={handleUpgrade}
                                                value="yearly"
                                            >
                                            Select
                                            </Button>
                                        </Grid.Column>) : null}
                                </React.Fragment>
                            )
                        }
                    </Grid>
                </Card.Content>
            </Card>
        );
    };


    return (
        <React.Fragment>
            <Segment id="plan-tier">
                <Grid stackable>

                    <GridRow>
                        <Form.Field className="inline_button" width={6}>
                            <Grid >
                                <Grid.Row>
                                    <Grid.Column width={5} />
                                    <Grid.Column width={10}>
                                        <div className="sub-header">
                                            <h3>Your Plan</h3>
                                            {qualifiedplan ? (
                                                <p>
                                                    Based on your average monthly ad spend from the last 60 days, you qualify for the
                                                    <strong> { qualifiedplan?.name }</strong> plan.
                                                </p>
                                            ) :  (
                                                <p>
                                                       Based on your average monthly ad spend from the last 60 days, you Current Plan is
                                                    <strong> { currentplan?.name }</strong> .
                                                </p>
                                            ) }
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={1} />
                                </Grid.Row>
                            </Grid>

                            <Grid>
                                <Grid.Row >
                                    <Grid.Column width={3} />
                                    <Grid.Column width={13}>
                                        { buildPlanCard() }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form.Field>
                    </GridRow>
                </Grid>
                <StripeModal/>
            </Segment>
        </React.Fragment>
    );
};
export default observer(UpgradePlanContainer);
