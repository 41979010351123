import {Grid, Segment, Button, Table, Form} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";
import appStore from "../../../../../stores";
import helpers from "../../../../../utils/helpers";
import "./billing.less";
import {DateTime} from "luxon";
import api from "../../../../../data-loader/api";

const { settingsStore } = appStore;

const BillingContainer = () => (

    <React.Fragment>
        <Segment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8}>
                        This account is billed to
                        <p>
                            <strong>
                                {helpers.capitalizeFirstLetter(settingsStore.billing.brand)} card  ending in {settingsStore.billing.last4}
                            </strong>
                        </p>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Button color="red">
                            Remove credit card
                        </Button>
                        <Button primary>
                            Change credit card
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
        <Grid className="billing-summary">
            <Grid.Row>
                <Grid.Column width={8}>
                    <p style={{marginBottom: "5px"}}><strong>Current Plan</strong></p>
                    Free Tier
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                    <br />
                    <strong>$0</strong>/mo
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <Table basic="very">
            <Table.Body>
                <Table.Row>
                    <Table.Cell><a href="">January</a></Table.Cell>
                    <Table.Cell textAlign="right">$0</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell><a href="">February</a></Table.Cell>
                    <Table.Cell textAlign="right">$0</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell><a href="">March</a></Table.Cell>
                    <Table.Cell textAlign="right">$0</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell><a href="">April</a></Table.Cell>
                    <Table.Cell textAlign="right">$0</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    </React.Fragment>
);

export default observer(BillingContainer);
