import {Form, Button, Message} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";
import appStore from "../../../../../stores";
import {useState} from "react";

const handleUpdate = () => {
    appStore.userStore.resetEmail();

};

const handleChange = (field, val) => {
    appStore.userStore.newEmail[field] = val;
};

// change email success message function
const emailChangeAlert = () => {
    return appStore.userStore.profileSetting.email_update ? (
        <Message success style={{textAlign: "center", marginBottom: "3em"}}>
            Verification email sent successfully.
        </Message>
    ) : null;
};

const ProfileContainer = () => {
    const {loaderStore} = appStore;
    return (
        <React.Fragment>
            {
                appStore.userStore.newEmailErrors.length > 0 ? (
                    <Message
                        error
                        header="Please correct the following errors"
                        list={appStore.userStore.newEmailErrors}
                    />
                ) : null
            }
            {emailChangeAlert()}
            <Form>
                <Form.Field>

                    <label>Email</label>
                    <input
                        placeholder="Email"
                        onChange={(e) => handleChange("email", e.target.value)}
                        type="email"
                        value={appStore.userStore.newEmail.email}
                    />
                </Form.Field>
                <Button
                    fluid
                    primary
                    loading={loaderStore.isLoaderActive("emailUpdateLoader")}
                    onClick={() => handleUpdate()}
                >
                    Update
                </Button>

            </Form>
        </React.Fragment>
    );
};

export default observer(ProfileContainer);
