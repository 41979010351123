import * as React from "react";
import { observer } from "mobx-react-lite";
import { Container, Grid, Form, Button, Segment, Message } from "semantic-ui-react";
import appStore from "../../../stores";
import "./newpassword.less";

const handleSuccess = () => {
    appStore.userStore.googleAuth();
};
const handleSubmit = () => {
    appStore.userStore.resetPassword();
};
const handleChange = (field, val) => {
    appStore.userStore.newPassword[field] = val;
};
const NewPasswordContainer = () => (
    <section id="new-password">
        <Container fluid className="new-password-container">

            <Grid className="subscribe-grid">
                <Grid.Row>
                    <Grid.Column width={5} />
                    <Grid.Column width={6}>
                        <Segment className="new-password-segment">
                            <div className="header">
                                Reset Password
                            </div>
                            {
                                appStore.userStore.newPasswordErrors.length > 0 ? (
                                    <Message
                                        error
                                        header="Please correct the following errors"
                                        list={appStore.userStore.newPasswordErrors}
                                    />
                                ) : null
                            }
                            <Form>
                                <Form.Field>
                                    <label>New Password</label>
                                    <input
                                        placeholder="Password" type="password"
                                        onChange={(e) => handleChange("password", e.target.value)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Password Confirmation</label>
                                    <input
                                        placeholder="Password" type="password"
                                        onChange={(e) => handleChange("password_confirm", e.target.value)}
                                    />
                                </Form.Field>
                                <Button
                                    loading={appStore.loaderStore.isLoaderActive("newPasswordLoader")}
                                    fluid
                                    primary
                                    onClick={() => handleSubmit()}
                                >
                                    Submit New Password
                                </Button>
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </section>
);

export default observer(NewPasswordContainer);
