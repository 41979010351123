import * as React from "react";
import { Container, Grid, Button, Divider } from "semantic-ui-react";
import { routerStore } from "mr-router5";

import appStore from "_/stores";
import "./Error.less";

const ErrorContainer = () => {
    const { homeStore } = appStore;

    const handleClick = () => {
        routerStore.router.navigate("home");
    };

    return (
        <section id="error-container">
            <Container fluid className="error-container">
                <Container textAlign="center">
                    <h1>
                        Uh oh! Failure to launch!
                    </h1>

                </Container>
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column width={4} />
                        <Grid.Column width={8} textAlign="center">
                            <div className="error-message">
                                Looks like there was an error attempting to complete your registration.
                                <br />
                                <br />
                                We were unable to find any active campaigns associated with your account.
                                <br />
                                Please try again after creating an active campaign through Seller Central.
                                <Divider hidden />
                                If your account has active campaigns, please click the button below to go back to the
                                homepage to try again or reach out to us at <a href="mailto:help@adastronaut.com">help@adastronaut.com.</a>
                            </div>
                            <Button
                                className="blue"
                                size="huge"
                                onClick={() => handleClick()}
                            >
                                Back to home
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </section>
    );
};

export default ErrorContainer;
