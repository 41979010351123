export default () => ({
    onTransitionSuccess(toState, fromState) {
        if (fromState && toState) {
            if (toState.name !== fromState.name) {
                window.scrollTo(0, 0);
            }
        } else {
            window.scrollTo(0, 0);
        }
    },
});

