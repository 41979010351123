import * as React from "react";
import { Container, Grid, Button, Visibility } from "semantic-ui-react";
import { routerStore } from "mr-router5";

import appStore from "_/stores";
import "./Welcome.less";

const WelcomeContainer = () => {
    const { homeStore } = appStore;

    const handleClick = () => {
        routerStore.router.navigate("login");
    };

    const handleVisibility = (calculations) => {
        // increase the pixel passed for this page so the black header always shows
        if (calculations.pixelsPassed > 100) {
            homeStore.setShowSolidNav(true);
        } else {
            homeStore.setShowSolidNav(false);
        }
    };

    return (
        <Visibility
            fireOnMount={(e, { calculations }) => handleVisibility(calculations)}
            onUpdate={(e, { calculations }) => handleVisibility(calculations)}
        >
            <section id="welcome">
                <Container fluid className="welcome-container">
                    <Container textAlign="center" className="section-title">
                        <div className="section-header">
                            <h1>Welcome to Ad Astronaut</h1>
                        </div>
                    </Container>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={5} />
                            <Grid.Column width={6} textAlign="center">
                                <div className="get-started">
                                    <p>Ready to take ownership of your data?</p>
                                    <Button
                                        className="blue"
                                        size="huge"
                                        onClick={() => handleClick()}
                                    >
                                        Start using Ad Astronaut
                                    </Button>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </section>
        </Visibility>
    );
};

export default WelcomeContainer;
