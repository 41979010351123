import {action, computed, observable} from "mobx";
import api from "../data-loader/api";
import { routerStore } from "mr-router5";
import axios from "axios";
// import validator from "validator";
class UserStore {
    constructor(appStore) {
        this.appStore = appStore;
    }
    userObj = {
        first_name: "",
        last_name: "",
        email: "",
        plan: {
            name: "",
            frequency: "",
            is_active: "",
        },
        regions: {},
    };
    @observable user = Object.assign({}, this.userObj);
    credentials = {
        email: "",
        password: "",
        password_reset: false,
        email_reset:false,
    };
    registration = {
        first_name: "",
        last_name: "",
        store_name:"",
        email: "",
        password: "",
        password_confirm: "",
    };

    @observable profileSetting={
        password_update: false,
        email_update:false,
        name_update:false,
    };
    @observable newEmail = {
        email: "",
        sent: false,
    };
    @observable newName = {
        first_name: "",
        last_name: "",
    };
    @observable newPassword = {
        current_password:"",
        ddpr: "",
        password: "",
        password_confirm: "",
    };
    @observable registrationErrors = [];
    @observable invalidLogin = false;
    @observable newPasswordErrors = [];
    @observable newEmailErrors = [];
    @observable newNameErrors = [];

    @computed
    get isAuthenticated() {
        return this.user.email !== "";
    }
    @computed
    get isNewActivation() {
        return this.user.is_new_activation;
    }
    @computed
    get fullName() {
        return `${this.user.first_name} ${this.user.last_name}`;
    }
    @computed
    get id() {
        return `${this.user.email}`;
    }
    @action
    setUser(userData) {
        this.user = userData;
    }
    @action
    setNewUser() {
        this.newName.first_name = this.user.first_name;
        this.newName.last_name = this.user.last_name;
        this.newEmail.email = this.user.email;
    }

    @action
    setNewEmail() {
        this.newEmail.email = this.user.email;
    }

    @action
    resetProfileMessages() {
        this.profileSetting.name_update =
            this.profileSetting.password_update =
                this.profileSetting.email_update = false;
    }
    @action
    async login() {
        try {
            const { data } = await api.user.login(this.credentials);
            this.setUser(data);
            this.credentials.password_reset = false;
            this.invalidLogin = false;
            routerStore.router.navigate("app");
        } catch (err) {
            this.invalidLogin = true;
        }
    }
    @action
    async logout() {
        try {
            await api.user.logout();
            this.user = Object.assign({}, this.userObj);
            routerStore.router.navigate("home");
        } catch (err) {
            console.log(err);
        }
    }

    // update name from account setting page
    @action
    resetName() {
        const {loaderStore} = this.appStore;
        if (this.validateName()) {
            loaderStore.start("nameUpdateLoader");
            return api.user.updateName(this.newName)
                .then((resp) => {
                    // setting name for current user
                    this.user.first_name = this.newName.first_name;
                    this.user.last_name = this.newName.last_name;
                    this.profileSetting.name_update = true;
                    loaderStore.stop("nameUpdateLoader");
                    this.resetSuccessMessages();
                },
                (error) => {
                    console.log(error);
                    loaderStore.stop("nameUpdateLoader");
                });
        }
    }

    // update email from account setting page
    @action
    async resetEmail() {
        const {loaderStore} = this.appStore;
        if (this.validateEmail()) {
            loaderStore.start("emailUpdateLoader");
            return  api.user.updateEmail(this.newEmail)
                .then((resp) => {
                    this.profileSetting.email_update = true;
                    loaderStore.stop("emailUpdateLoader");
                    this.resetSuccessMessages();
                },
                (error) => {
                    console.log(error);
                    this.newEmailErrors.push("Email already taken choose different one.");
                    // Do nothing if use is not authenticated
                    loaderStore.stop("emailUpdateLoader");
                });
        }

    }

    // to reset success messages from account setting page
    @action
    async resetSuccessMessages() {
        const {loaderStore} = this.appStore;
        // setting delay for success message display
        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(5000);
        this.resetProfileMessages();
        loaderStore.reset();
    }

    @action
    register() {
        const { loaderStore } = this.appStore;
        // console.log(this.registration, "registration data");
        if (this.validateForm()) {
            loaderStore.start("register", true);
            return api.user.register(this.registration)
                .then((resp) => {
                    this.setUser(resp.data);
                    loaderStore.stop("register");
                    routerStore.router.navigate("emailconfirm");
                },
                (error) => {
                    loaderStore.stop("register");
                    console.log(error);
                    // Do nothing if use is not authenticated
                });
        }
    }
    @action

    resetPasswordEmail() {
        // console.log(this.newEmail);
        return api.user.resetPasswordEmail(this.newEmail)
            .then((resp) => {
                this.newEmail.sent = true;
                routerStore.router.navigate("passwordreset");
            },
            (error) => {
                this.newEmail.sent = true;
            });
    }

    // update password from account settings
    @action
    updatePassword() {
        if (this.validatePassword()) {
            const { loaderStore } = this.appStore;
            loaderStore.start("updatePasswordLoader");
            return api.user.updatePassword(this.newPassword)
                .then((resp) => {
                    this.profileSetting.password_update = true;
                    this.resetSuccessMessages();
                },
                (error) => {
                    console.log(error);
                    loaderStore.stop("updatePasswordLoader");
                    // Do nothing if use is not authenticated
                });
        }
    }

    // reset password through email
    @action
    resetPassword() {
        console.log(this.credentials.password);
        if (this.validatePassword()) {
            const { loaderStore } = this.appStore;
            loaderStore.start("newPasswordLoader");
            return api.user.resetPassword(this.newPassword)
                .then((resp) => {
                    this.credentials.password_reset = true;
                    routerStore.router.navigate("login");
                },
                (error) => {
                    console.log(error);
                    loaderStore.stop("newPasswordLoader");
                    // Do nothing if use is not authenticated
                });
        }
    }
    @action
    googleAuth() {
        return api.user.googleAuth()
            .then((resp) => {
                window.location.href = resp.data.url;
            },
            (error) => {
                console.log(error);
                // Do nothing if use is not authenticated
            });
    }
    // Validation
    validateForm() {
        this.registrationErrors = [];
        let isValid = true;
        if (!this.registration.first_name) {
            this.registrationErrors.push("First name is required.");
            isValid = false;
        }
        if (!this.registration.last_name) {
            this.registrationErrors.push("Last name is required.");
            isValid = false;
        }
        if (!this.registration.store_name) {
            this.registrationErrors.push("Store name is required.");
            isValid = false;
        }

        if (!this.registration.email) {
            this.registrationErrors.push("Email is required.");
            isValid = false;
        }
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(this.registration.email) && this.registration.email) {
            this.registrationErrors.push("invalid Email Address \n");
            isValid = false;
        }
        if (!this.registration.password) {
            this.registrationErrors.push("Password is required");
            isValid = false;
        }
        if (this.registration.password !== this.registration.password_confirm) {
            this.registrationErrors.push("Passwords do not match");
            isValid = false;
        }
        if (this.registration.password.length < 8) {
            this.registrationErrors.push("Password has to be at least 8 characters");
            isValid = false;
        }
        if (this.registration.password.includes(" ")) {
            this.registrationErrors.push("Password cannot contain spaces");
            isValid = false;
        }
        return isValid;
    }

    // password change and new account validations
    validatePassword() {
        this.newPasswordErrors = [];
        let isValid = true;
        if (!this.newPassword.password) {
            this.newPasswordErrors.push("Password is required \n");
            isValid = false;
        }
        if (this.newPassword.password !== this.newPassword.password_confirm) {
            this.newPasswordErrors.push("Passwords do not match \n");
            isValid = false;
        }
        if (this.newPassword.password.length < 8 && this.newPassword.password) {
            this.newPasswordErrors.push("Password has to be at least 8 characters \n");
            isValid = false;
        }
        if (this.newPassword.password.includes(" ")) {
            this.newPasswordErrors.push("Password cannot contain spaces \n");
            isValid = false;
        }
        if (!this.newPassword.current_password && this.newPassword.ddpr === "" ) {
            this.newPasswordErrors.push("Old password required \n");
            isValid = false;
        }
        if (this.newPassword.current_password !== this.credentials.password && this.newPassword.current_password && this.newPassword.ddpr === "") {
            this.newPasswordErrors.push("Incorrect old password \n");
            isValid = false;
        }
        return isValid;
    }

    // change email validations
    validateEmail() {
        this.newEmailErrors = [];
        let isValid = true;
        if (!this.newEmail.email) {
            this.newEmailErrors.push("Email is required \n");
            isValid = false;
        }
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(this.newEmail.email)) {
            this.newEmailErrors.push("invalid Email Address \n");
            isValid = false;
        }
        if (this.newEmail.email.includes(" ")) {
            this.newEmailErrors.push("Email cannot contain spaces \n");
            isValid = false;
        }
        return isValid;
    }

    // change name validation
    validateName() {
        this.newNameErrors = [];
        let isValid = true;
        if (!this.newName.first_name) {
            this.newNameErrors.push("First Name is required \n");
            isValid = false;
        }
        if (!this.newName.last_name) {
            this.newNameErrors.push("Last Name is required \n");
            isValid = false;
        }
        return isValid;
    }

}
export default UserStore;