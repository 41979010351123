import { observable, action } from "mobx";


class UIStore {

    @observable loader = [];
    @observable modal = [];

    constructor() {
        this.attrMapping = {
            loader: this.loader,
            modal: this.modal,
        };
    }

    @action
    add(name, attr) {
        const attribute = this.attrMapping[attr];
        if (!attribute.includes(name)) {
            attribute.push(name);
        }
    }


    @action
    remove(name, attr) {
        const attribute = this.attrMapping[attr];
        if (name && attribute.includes(name)) {
            attribute.remove(name);
        } else if (!name) {
            attribute.replace([]);
        }
    }

    contains(name, attr) {
        const attribute = this.attrMapping[attr];
        if (name) {
            return (attribute.includes(name));
        }
        return (attribute.length > 0);

    }

    @observable expandRow = {
        activeElements: [],
        toggle: action("toggle", (index, callback = () => {}) => {
            if (this.expandRow.activeElements.includes(index)) {
                const updatedElements = this.expandRow.activeElements.filter(e => e !== index);
                this.expandRow.activeElements.replace(updatedElements);
            } else {
                this.expandRow.activeElements.push(index);
                callback();
            }
        }),
        isActiveElement: (index) => {
            return (this.expandRow.activeElements.includes(index));
        },
        clear: action("clear", () => {
            this.expandRow.activeElements.replace([]);
        }),
    };
}

export default UIStore;
