import React from "react";
import PropTypes from "prop-types";
import {observer} from "mobx-react-lite";
import { routerStore} from "mr-router5";
import { Card, List, Icon, Divider, Button, Label } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";

import "./Tier.less";


const Tier = ({tier, showCallToAction, highlight}) => {

    const buildFeaturesList = (features) => {
        return features.map(feature => {
            return (
                <List.Item key={uuidv4()} className="feature-item">
                    <Icon name="check" color="teal" /> {feature}
                </List.Item>
            );
        });
    };

    const buildCallToActionButtons = () => {
        return (
            <Card.Content extra>
                <Button
                    fluid
                    size="huge"
                    className={`${tier.name === "Premium" ? "blue" : "blue"}`}
                    onClick={() => routerStore.router.navigate("subscribe")}
                >
                    {tier.callToAction}
                </Button>
            </Card.Content>
        );
    };

    const displayCardLabel = (tier) => {
        if (tier.name === "Free Forever") {
            return (
                <Label color="red" ribbon style={{width: "3em", marginBottom: "2px"}}>
                    New!
                </Label>
            );
        }
        return (
            <Divider hidden />
        );
    };

    return (
        <Card key={uuidv4()} className={`tier-card ${highlight ? "highlight" : ""}`}>
            { displayCardLabel(tier) }
            <Card.Content textAlign="center" style={{borderTop: "none"}}>

                <h3>
                    {tier.name}
                </h3>
                <Divider />
                <div className="sub-section">
                    <h3>{`$${tier.cost.yearly.toFixed(0)}/mo`}</h3>
                    <div className={`${tier.cost.term ? "term" : ""}`}>{tier.cost.term ? `(${tier.cost.term})` : null}</div>
                    <div className="description">
                        {tier.description}
                    </div>
                </div>
                <Divider />
                <div className="feature-title">
                    {tier.featureTitle}:
                </div>
                <div className="feature-list">
                    {buildFeaturesList(tier.features)}
                </div>
            </Card.Content>
            {showCallToAction ? buildCallToActionButtons() : ""}
        </Card>
    );
};

Tier.propTypes = {
    tier: PropTypes.object.isRequired,
    showCallToAction: PropTypes.bool,
    highlight: PropTypes.bool,
};

Tier.defaultProps = {
    showCallToAction: true,
    highlight: false,
};

export default observer(Tier);
