import {
    Segment,
    Grid,
    Modal,
    Button,
    Header,
    List,
    Icon,
    Message, Table,
} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";
import appStore from "../../../../../stores";
import "./PaymentDetail.less";
import { Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {v4 as uuidv4} from "uuid";
import ChangeCard from "./ChangeCard.jsx";



const PaymentDetail = () => {

    const {upgradeStore, settingsStore } = appStore;
    // test stripe key
    // const stripePromise = loadStripe("pk_test_51IsQe6IqpwAWNQV0K4sV6Vv441tSwBTh0BPK22kmiINItqRe6OZdNklN0UAVyXDFXYzRtHJ7RFulRnOgKiO7g4Ox00N5R3LPqW");
    const stripePromise = loadStripe(upgradeStore.stripePK);
    // wrapping stripe variable in elemets to pass stripe promise
    const stripe =
    <Elements stripe={stripePromise}>
        useStripe()
    </Elements>;
    const elements =
    <Elements stripe={stripePromise}>
            useElements()
    </Elements>;


    const openpaymentmodel = () => {
        upgradeStore.showPaymentModal = true;
    };

    const displayFeatures = (features) => {
        return features.map(feature => {
            return (
                <List.Item key={uuidv4()} className="feature-item">
                    <Icon name="check" color="teal" /> {feature}
                </List.Item>
            );
        });
    };

    const displayPaymentError = () => {
        if (upgradeStore.paymentErrors.length > 0) {
            return (
                <Message negative>
                    {upgradeStore.paymentErrors[0]}
                </Message>
            );
        }
        return null;
    };

    return (
        <React.Fragment>
            <Segment loading={!stripe}>
                {/*card detail table*/}
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Ending on</Table.HeaderCell>
                            <Table.HeaderCell>Expires</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Cell> {settingsStore.billing.brand} </Table.Cell>
                        <Table.Cell> {settingsStore.billing.last4}</Table.Cell>
                        <Table.Cell> {settingsStore.billing.exp_month}/
                            {settingsStore.billing.exp_year}</Table.Cell>
                    </Table.Body>
                </Table>
                <Button
                    className="bottom-aligned"
                    primary
                    fluid
                    onClick={() => openpaymentmodel()}
                >
                Change Card
                </Button>
                {/*change card modal*/}
                <Modal
                    id="stripe-modal"
                    size="small"
                    closeOnDimmerClick={false}
                    open={upgradeStore.showPaymentModal}
                    onClose={() => close()}
                >
                    <Header content="Payment" className="payment-header" />
                    <Modal.Content className="payment-body">
                        <Grid stackable className="payment-grid">
                            <Grid.Column  className="stripe-col">
                                { displayPaymentError() }
                                <Elements stripe={stripePromise}>
                                    <ChangeCard />
                                </Elements>
                            </Grid.Column>
                        </Grid>
                    </Modal.Content>
                </Modal>
            </Segment>
        </React.Fragment>
    );
};

export default observer(PaymentDetail);
