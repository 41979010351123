import * as React from "react";
import {
    Container,
    Segment,
    Button,
    Grid,
    Placeholder,
} from "semantic-ui-react";
import appStore from "../../../stores";
import "./Partners.less";

const PartnersContainer = () => {
    const { partnersStore } = appStore;

    const buildPartnersHeader = () => {
        return partnersStore.partners.map(partner => {
            return (
                <Grid.Column textAlign="center" verticalAlign="middle">
                    <Placeholder
                        className="image"
                        style={{ height: 50, width: 70 }}
                    >
                        <Placeholder.Image rectangular />
                    </Placeholder>
                    <h5>{partner.name}</h5>
                </Grid.Column>
            );
        });
    };

    const buildPartnerProfile = () => {
        return partnersStore.partners.map(partner => {
            return (
                <Grid.Row
                    columns="equal"
                    verticalAlign="middle"
                    textAlign="center"
                    centered
                    className="spacer"
                >
                    <Grid.Column centered textAlign="left">
                        <div className="partner-profile-left">
                            <h2>{partner.name}</h2>
                            <p>{partner.description}</p>
                        </div>
                    </Grid.Column>

                    <Grid.Column centered textAlign="right">
                        <div className="partner-profile-right">
                            <Placeholder
                                className="image"
                                style={{ height: 150, width: 270 }}
                            >
                                <Placeholder.Image />
                            </Placeholder>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            );
        });
    };

    return (
        <section id="partners">
            <Container className="jumbo-section">
                <div className="page-header">
                    <div className="spacer-bottom"></div>
                    <h1>Meet our Certified Partners</h1>
                    <h4>
                        We partner with the best AdTech providers to certify
                        data portability and seamless integration
                    </h4>
                </div>

                <Grid relaxed columns="equal">
                    <Grid.Row centered className="spacer-top">
                        Click a provider to learn more
                    </Grid.Row>

                    <Grid.Row
                        centered
                        width="equal"
                        verticalAlign="middle"
                        className="spacer"
                    >
                        {buildPartnersHeader()}
                    </Grid.Row>
                    <div className="spacer-top"></div>

                    {buildPartnerProfile()}
                </Grid>
            </Container>
            <Container className="jumbo-section page-footer">
                <Grid relaxed>
                    <Grid.Row>
                        <Grid.Column width={11} verticalAlign="middle">
                            <h1>Our AdTech partners are best-in-class</h1>
                            <p>
                                And they work best when they can use your full
                                historical ad data
                            </p>
                        </Grid.Column>
                        <Grid.Column
                            width={5}
                            verticalAlign="middle"
                            textAlign="right"
                        >
                            <Button color="teal" size="huge">
                                Start Now
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </section>
    );
};

export default PartnersContainer;
