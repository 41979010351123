import * as React from "react";
import { Menu, Container, Button } from "semantic-ui-react";
import { DateTime } from "luxon";
import { routerStore } from "mr-router5";

import Logo from "../../Common/Logo/Logo.jsx";

import "./footer.less";

const FooterContainer = () => (
    <footer>
        <Menu stackable borderless className="site-footer">
            <Container>
                <Menu.Item>
                    Copyright &copy; Ad Astronaut
                </Menu.Item>
                <Menu.Menu position="right">
                    <Menu.Item as={Button} onClick={() => routerStore.router.navigate("terms")}>
                        Terms
                    </Menu.Item>
                    <Menu.Item as={Button} className="pipe-divider">
                        |
                    </Menu.Item>
                    <Menu.Item as={Button} onClick={() => routerStore.router.navigate("privacy")}>
                        Privacy
                    </Menu.Item>
                </Menu.Menu>
            </Container>
        </Menu>
    </footer>
);

export default FooterContainer;