import { action, observable } from "mobx";
import api from "../data-loader/api";
import { routerStore } from "mr-router5";

import { DateTime } from "luxon";
import appStore from "./index";


class UpgradeStore {
    @observable showPaymentModal = false;
    @observable selectedTier=null ;
    @observable selectedFrequency = null;
    @observable paymentMethod = "";
    @observable stripePK = null;
    @observable cardName = "";
    @observable paymentErrors = [];

    constructor(appStore) {
        this.appStore = appStore;
    }

    @action
    setCardName(name) {
        this.cardName = name;
    }
    @action
    setpaymentMethod(val) {
        this.paymentMethod = val;
    }
    @action
    setselectedtier(data) {
        this.setselectedtier = data;
    }

    freeTier = {
        name: "Free Forever",
        description: "Up to $20,000 in monthly ad spend",
        cost: {
            monthly: 0,
            yearly: 0,
            term: "",
        },
        featureTitle: "For entrepreneurs starting out",
        features: [
            "Fully-automated data collection",
            "Intelligent data cleaning",
            "Unlimited data storage",
        ],
        fees: {
            exportFee: {
                name: "Bulk data exports",
                primaryText: "1 free export per year",
                secondaryText: "",
                description:
                    "When you request a bulk data export, we retrieve your complete data archive, decompress it, \n" +
                    "and provide it to you or your solution provider. Exports are available for $4.99 each. Most customers \n" +
                    "do not require more than 1 export per year.",
            },
            highCapacityFee: {
                name: "",
                primaryText: "",
                secondaryText: "",
                description: "",
            },
        },
        callToAction: "Start with Free Forever",
    };

    standardTier = {
        name: "Standard",
        description: "Up to $10,000 in monthly ad spend",
        cost: {
            monthly: 12,
            yearly: 10,
            term: "billed yearly",
        },
        featureTitle: "For smaller advertisers",
        features: [
            "Fully-automated data collection",
            "Intelligent data cleaning",
            "Unlimited data storage",
        ],
        fees: {
            exportFee: {
                name: "Bulk data exports",
                primaryText: "1 free export per year",
                secondaryText: "",
                description:
                    "When you request a bulk data export, we retrieve your complete data archive, decompress it, \n" +
                    "and provide it to you or your solution provider. Exports available for $4.99 each. Most customers \n" +
                    "do not require more than 1 export per year.",
            },
            highCapacityFee: {
                name: "",
                primaryText: "",
                secondaryText: "",
                description: "",
            },
        },
        callToAction: "Get Started",
    };

    premiumTier = {
        name: "Premium",
        description: "Up to $20,000 in monthly ad spend",
        cost: {
            monthly: 24,
            yearly: 20,
            term: "billed yearly",
        },
        featureTitle: "For fast-growing advertisers",
        features: [
            "Fully-automated data collection",
            "Intelligent data cleaning",
            "Unlimited data storage",
        ],
        fees: {
            exportFee: {
                name: "Bulk data exports",
                primaryText: "3 free exports per year",
                secondaryText: "",
                description:
                    "When you request a bulk data export, we retrieve your complete data archive, \n" +
                    "decompress it, and provide it to you or your solution provider. Exports \n" +
                    "available for $0.09 per GB. Most customers do not require more than 1 export per year.",
            },
            highCapacityFee: {
                name: "",
                primaryText: "",
                secondaryText: "",
                description: "",
            },
        },
        callToAction: "Get Started",
    };

    enterpriseTier = {
        name: "Enterprise",
        description: "Unlimited monthly ad spend",
        cost: {
            monthly: 72,
            yearly: 60,
            term: "billed yearly",
        },
        featureTitle: "For large-scale advertisers",
        features: [
            "Fully-automated data collection",
            "Intelligent data cleaning",
            "Unlimited data storage",
            "White glove data migrations",
            "Complementary consultations",
        ],
        fees: {
            exportFee: {
                name: "Bulk data exports",
                cost: "$0.09 per GB",
                subText: "",
                description:
                    "When you request a bulk data export, we retrieve your complete data archive, decompress it, \n" +
                    "and provide it to you or your authorized solution provider. Most customers do not require more \n" +
                    "than 1 export per year.",
            },
            highCapacityFee: {
                name: "High capacity fee",
                cost: "$0.01 per GB after 1 TB",
                subText: "",
                description:
                    "This fee will typically not apply to most users since we compact ad data for ultra-high \n" +
                    "space efficiency. In edge cases, some accounts may generate significantly larger-than-average \n" +
                    "amounts of data, so a $0.01 per GB per month fee allows us to guarantee high data durability, \n" +
                    "reliability, and security. This fee is waived for your first TB of data.",
            },
        },
        callToAction: "Get Started",
    };

    feeTransparency =
        "To keep our service affordable or free, a High Capacity Fee is applied to a small \n" +
        "percentage of our Enterprise customers who have significantly higher-than-normal \n" +
        "requirements. In these cases, a $0.01 per GB per month fee allows us to guarantee the \n" +
        "highest standard of data durability, reliability, and security. This fee is only assessed \n" +
        "if the size of your compacted ad data exceeds 1 TB, and the fee is only applied to the  \n" +
        "data in excess of the 1 TB threshold.";


    tiers = [
        this.freeTier,
        // this.standardTier,
        // this.premiumTier,
        this.enterpriseTier,
    ];
    get_tier() {
        return this.myplan;
    }


    tiersDict = {
        free: this.freeTier,
        standard: this.standardTier,
        premium: this.premiumTier,
        enterprise: this.enterpriseTier,
    };

    getMainTiers() {
        return this.tiers;
        // return this.tiers.filter((tier, index) => {
        //     return tier.name !== "Enterprise";
        // });
    }

    getBillingFeatures(tier) {
        return tier.features;
    }

    parsePayload(result, tier, frequency) {
        return {
            tier: tier,
            frequency: frequency,
            payment_method: result.paymentMethod.id,
        };
    }
    parsePayloadOverload( tier, frequency) {
        return {
            tier: tier,
            frequency: frequency,
        };
    }

    resetPayment() {
        this.paymentId = null;
        this.selectedTier = null;
        this.selectedFrequency = null;
        this.paymentErrors.replace([]);
    }



    @action
    async changeCard(result) {
        const { userStore, loaderStore, registrationStore } = this.appStore;
        try {
            loaderStore.start("paymentLoader");
            const data = {
                payment_method:result.paymentMethod.id,
            };
            await api.subscription.updatePaymentMethod(data);
            loaderStore.stop("paymentLoader");
            this.paymentErrors.replace([]);
            this.togglePaymentModal(false);
        } catch (err) {
            console.log(err);
            // this.resetPayment();
            this.paymentErrors.push(
                "Unable to change payment method. Please try again or contact us for help."
            );
            loaderStore.stop("paymentLoader");
        }
    }


    @action
    async checkout(result) {
        const { userStore, loaderStore, registrationStore } = this.appStore;
        try {
            loaderStore.start("paymentLoader");

            // in case of free to paid customer
            await api.subscription.customer(
                this.parsePayload(
                    result,
                    this.selectedTier.name,
                    this.selectedFrequency
                )
            );

            this.resetPayment();
            loaderStore.stop("paymentLoader");
            if (userStore.user.is_new_activation) {
                this.togglePaymentModal(false);
                try {
                    await api.user.activateNewUser();
                } catch (err) {
                    console.log(err);
                }
                registrationStore.setCurrentStep(3);
            } else {
                routerStore.router.navigate("app.account");
            }
            this.paymentErrors.replace([]);
        } catch (err) {
            console.log(err);
            // this.resetPayment();
            this.paymentErrors.push(
                "Unable to complete payment. Please try again or contact us for help."
            );
            loaderStore.stop("paymentLoader");
        }
    }

    get stripePK() {
        return this.stripePK;
    }

    set stripePK(key) {
        this.stripePK = key;
    }
    @action
    setPaymentKey  = async () => {
        try {
            const {data} = await  api.subscription.paymentKey();
            this.stripePK = data.key;
            // this.stripePK=
        } catch (err) {
            console.log(err);
        }
    }


    @action
    togglePaymentModal(val) {
        this.showPaymentModal = val;
    }


}

export default UpgradeStore;
