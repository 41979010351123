import {Card, Segment, Button, Modal, Header, Dropdown, Container, Form, Input} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";
import { routerStore } from "mr-router5";
import appStore from "../../../../../stores";
import "../Tier/tier.less";
import api from "../../../../../data-loader/api";
import {useState} from "react";

const TierContainer =  () => {

    // get user current plan and frequency in case of stripe customer
    const handleUpgrade = async () => {
        try {
            const {data} = await api.subscription.getcurrentplan();
            appStore.upgradeStore.currentplan = data[0];
            appStore.upgradeStore.currentfrequency = data[1];
        } catch (err) {
            console.log(err);
        }
  
        routerStore.router.navigate("upgrade");
    };

    // state varibale to handle typed confirmation in cancel subscription
    const [confirmation, setConfirmation] = useState("cancel account");
    const handleinput = (event, data) => {
        const input_data = data.value;
        setConfirmation(input_data);
    };

    // cancel upgrade api
    const handleCancelUpgrade = async () => {
        if (confirmation === "cancel subscription") {
            await api.subscription.cancelSubscription();
            setOpen(false);
        }
    };

    // account reactivation api
    const handleReactivation = async () => {
        try {
            await api.subscription.reactivePlan();
            alert("You Subscription has been reactivated");
        } catch (err) {
            alert("Your Subscription is already active.");
        }

    };


    // cancel subscription model
    const [open, setOpen] = React.useState(false);
    const cancel_subscription = () => {


        return (

            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
            >
                <Modal.Header >Are you sure?</Modal.Header>
                <Modal.Content>
                    <Form.Field>
                        <label>Type to Confirm  </label>
                        <Input
                            autoFocus
                            onChange={ handleinput}
                            placeholder="cancel subscription"
                            size="huge"
                        />
                    </Form.Field>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="black" onClick={() => setOpen(false)}>
                    Close
                    </Button>
                    <Button color="red" onClick={() => handleCancelUpgrade()}>
                    Confirm
                    </Button>

                </Modal.Actions>
            </Modal>
        );
    };



    return (
        <React.Fragment>
            <Card fluid>
                <Card.Content as={Segment} inverted color="black">
                    {cancel_subscription()}

                    <h3>Free Tier</h3>
                </Card.Content>
                <Card.Content extra>
                    <Button primary fluid onClick={() => handleUpgrade()}>
                        Upgrade
                    </Button>
                    <br></br>
                    <Button primary fluid onClick={() => setOpen(true)}>
                        Cancel Subscription
                    </Button>
                    <br/>
                    <Button primary fluid onClick={() => handleReactivation()}>
                        Reactive Plan
                    </Button>
                </Card.Content>
            </Card>
        </React.Fragment>
    );
};



export default observer(TierContainer);
