import api from "../data-loader/api";
import apiUtils from "../data-loader/api-utils";
import appStore from "../stores";
import { routerStore } from "mr-router5";

const staticPages = [
    "home",
    "partners",
    "pricing",
    "about",
    "login",
    "register",
    "subscribe",
    "passwordreset",
    "resetconfirm",
    "emailconfirm",
    "welcome",
    "newpassword",
    "terms",
    "privacy",
    "emailresetconfirm",
];

const dataLoaderMiddleware = router => async (toState, fromState, done) => {
    const {
        userStore,
        PartnerStore,
        upgradeStore,
        dashboardStore,
        registrationStore,
        settingsStore,
        loaderStore,
        constantsStore,
    } = appStore;

    let preloadResources = [
        () => constantsStore.fetchConstants(),
    ];

    if (staticPages.includes(toState.name)) {
        try {
            const { data } = await api.user.checkAuth();
            userStore.setUser(data);
        } catch (err) {}

        switch (toState.name) {
        case "passwordreset": {
            appStore.userStore.resetEmail.email = "";
            appStore.userStore.resetEmail.sent = false;
        }
        case "newpassword": {
            const params = toState.params;
            appStore.userStore.newPassword.ddpr = params["ddpr"];
            done();
            break;
        }
        default: {
            done();
            break;
        }
        }
    } else if (toState.name === "newpassword") {
        const params = toState.params;
        appStore.userStore.newPassword.ddpr = params["ddpr"];
        done();
    } else {
        try {
            const userRes = await api.user.checkAuth();
            userStore.setUser(userRes.data);
            // set preload resources
            if (preloadResources.length > 0) {
                await apiUtils.awaitPromises(preloadResources);
            }
            switch (toState.name) {
            case "app": {
                if (userStore.user.is_new_activation) {
                    done({ redirect: { name: "setup" } });
                } else {
                    done({ redirect: { name: "dashboard" } });
                }
                break;
            }
            case "setup": {
                const resources = [
                    () => registrationStore.preload(),
                    // () => settingsStore.getAmazonSPUrl(registrationStore.selectedRegion),
                    () => settingsStore.getAmazonAdUrl(registrationStore.selectedRegion),
                ];

                await apiUtils.awaitPromises(resources);
                done();
                break;
            }
            case "account": {
                const { settingsStore } = appStore;
                userStore.setNewUser();
                userStore.setNewEmail();
                apiUtils
                    .loadData([
                        api.subscription.getCard(),
                    ])
                    .then(resp => {
                        if (resp[0].data) {
                            appStore.settingsStore.billing =
                                    resp[0].data.card;
                        }
                        done();
                    })
                    .catch();
                apiUtils
                    .loadData([
                        api.subscription.paymentKey(),
                    ])
                    .then(resp => {
                        if (resp[0].data) {
                            appStore.upgradeStore.stripePK = resp[0].data.key;
                        }
                        done();
                    })
                    .catch();
                break;
            }
            case "dashboard": {
                done();
                const resources = [() => dashboardStore.preload()];

                await apiUtils.awaitPromises(resources);
                break;
            }
            case "error": {
                done();
                break;
            }
            // case "account.upgrade": {
            //     apiUtils
            //         .loadData([api.subscription.paymentKey()])
            //         .then(resp => {
            //             upgradeStore.stripePK =
            //                         resp[0].data.key;
            //             done();
            //         })
            //         .catch();
            //     break;
            // }
            default: {
                done();
                break;
            }
            }
        } catch (err) {
            routerStore.router.navigate("login");
        }
    }
};

export default dataLoaderMiddleware;
