import {Segment, Grid, Divider, Message} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";
import Profile from "../Settings/Profile/Profile.jsx";
import Password from "../Settings/Password/Password.jsx";
import Tier from "../Settings/Tier/Tier.jsx";
import Billing from "../Settings/Billing/Billing.jsx";
import AmazonAd from "../Settings/Amazon/AmazonAd.jsx";
import AmazonSP from "../Settings/Amazon/AmazonSP.jsx";
import "../../Account/account.less";
import appStore from "../../../../stores";
import Delete from "./Delete/Delete.jsx";
import Name from "./Name/Name.jsx";
import Email from "./Email/Email.jsx";
import PaymentDetail from "./PaymentDetail/PaymentDetail.jsx";

const AccountSettingsContainer = () => (
    <React.Fragment>
        <h3>Account Settings</h3>
        <Segment>
            <Grid>
                {/*<Grid.Row>*/}
                {/*    <Grid.Column width={5}>*/}
                {/*        <h4>Profile</h4>*/}
                {/*        <span className="subtext">Your email is your username in Ad Astronaut and is used for login and notifications.</span>*/}
                {/*    </Grid.Column>*/}
                {/*    <Grid.Column width={6}>*/}
                {/*        <Profile />*/}
                {/*    </Grid.Column>*/}
                {/*</Grid.Row>*/}
                {/*<Divider/>*/}
                <Grid.Row>
                    <Grid.Column width={5}>
                        <h4>Change Name</h4>
                        <span className="subtext">Your Name in Ad Astronaut is used in emails.</span>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Name />
                    </Grid.Column>
                </Grid.Row>
                <Divider/>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <h4>Change Email</h4>
                        <span className="subtext">Your email is your username in Ad Astronaut and is used for login and notifications.</span>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Email />
                    </Grid.Column>
                </Grid.Row>
                <Divider/>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <h4>Password</h4>
                        <span className="subtext">Your password should be at least 8 characters.</span>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Password />
                    </Grid.Column>
                </Grid.Row>
                <Divider/>
                {/*<Grid.Row>*/}
                {/*    <Grid.Column width={5}>*/}
                {/*        <h4>Plan</h4>*/}
                {/*        <span className="subtext">Different reporting plans are available to suit your business needs. Upgrade if you feel your business has outgrown your current plan.</span>*/}
                {/*    </Grid.Column>*/}
                {/*    <Grid.Column width={6}>*/}
                {/*        <Tier />*/}
                {/*    </Grid.Column>*/}
                {/*</Grid.Row>*/}
                {/*<Divider />*/}
                {/*<Grid.Row>*/}
                {/*    <Grid.Column width={5}>*/}
                {/*        <h4>Billing</h4>*/}
                {/*        <span className="subtext">Charges are billed monthly starting from date of subscription.</span>*/}
                {/*    </Grid.Column>*/}
                {/*    <Grid.Column width={11}>*/}
                {/*        <Billing />*/}
                {/*    </Grid.Column>*/}
                {/*</Grid.Row>*/}
                {/*<Divider />*/}
                <Grid.Row>
                    <Grid.Column width={5}>
                        <h4>Marketplace Regions</h4>
                        <span className="subtext">
                            The Amazon marketplace regions that are connected to your Ad Astronaut account.
                        </span>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <AmazonAd />
                    </Grid.Column>
                </Grid.Row>
                <Divider />

                <Grid.Row>
                    <Grid.Column width={5}>
                        <h4>Payment Information</h4>
                        <span className="subtext">
                            <p>Your current credit card on file.</p>
                        </span>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <PaymentDetail/>
                    </Grid.Column>
                </Grid.Row>
                {/*<Grid.Row>*/}
                {/*    <Grid.Column width={5}>*/}
                {/*        <h4>Amazon Selling Partner</h4>*/}
                {/*        <span className="subtext">*/}
                {/*            <p>The Amazon Selling Partner account that will link with Ad Astronaut.</p>*/}
                {/*            <p>Grant access to Ad Astronaut to access the Amazon Selling Partner APIs on your behalf.</p>*/}
                {/*        </span>*/}
                {/*    </Grid.Column>*/}
                {/*    <Grid.Column width={6}>*/}
                {/*        <AmazonSP />*/}
                {/*    </Grid.Column>*/}
                {/*</Grid.Row>*/}
                {/*<Divider/>*/}
                <Grid.Row>
                    <Grid.Column width={5}>
                        <h4>Delete Account</h4>
                        <span className="subtext">We will retain your data for 30 days after account deletion should you change your mind.<br /><br />
                        After 30 days all data will be purged from our servers.</span>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Delete />
                    </Grid.Column>
                </Grid.Row>
                <Divider hidden />
            </Grid>
        </Segment>
    </React.Fragment>
);

export default observer(AccountSettingsContainer);