import { observable, action } from "mobx";
import api from "../data-loader/api";

class ConstantsStore {
    @observable data = null;

    constructor(appStore) {
        this.appStore = appStore;
    }

    @action
    setData(constants) {
        this.data = constants;
    }

    async fetchConstants() {
        if (!this.data) {
            try {
                const { data } = await api.constants.getConstants();
                this.setData(data);
            } catch (err) {
                console.log(err);
            }
        }
    }
}

export default ConstantsStore;