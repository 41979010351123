import * as React from "react";
import {Container, Segment, Table, Image, Grid, Button, Modal, Label, Dropdown, Message} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {Helmet} from "react-helmet";
import {DateTime} from "luxon";
import appStore from "../../../stores";
import "./Dashboard.less";
import {useEffect, useState} from "react";
import api from "../../../data-loader/api";

/**
 * @component
 * @description Component for displaying dashboard metrics
 */
const DashboardContainer = () => {


    // use state varibale to maintain drop down value when changes
    const {dashboardStore, dashboardStore: {dashboard, sofwarePartner, checkplan}} = appStore;

    const [software_partners, setSoftwarePartner] = useState("Select Software Partner");
    const handleDropdown = (event, data) => {
        const softwarepartner = data.value;
        setSoftwarePartner(softwarepartner);
    };

    // calling post api to update market_export table
    const delay = ms => new Promise(res => setTimeout(res, ms));


    const handleExport = async () => {
        // const data = {
        //     software_partner:software_partners,
        // };
        try {
            await api.metric.exportData();
            alert("The data export process has been initiated and make take several hours and up to a day to complete. " +
            "You will receive an email once the entire process has been finished.");
            await dashboardStore.fetchExports();
        } catch (err) {
            alert("We're unable to accept your export request at this time. There is currently an export in progress or active export that has not expired yet.\n\n Contact help@adastronaut.com if you have any questions.");
        }
        setOpen(false);
        await delay(100);
    };

    // deconstruct object properties

    // console.log(dashboard, "dashboard in dashboard.jsx");

    const hasData = (report) => {
        // console.log(dashboard[report].newest_sync);
        return Object.prototype.hasOwnProperty.call(dashboard, report);
    };


    const buildTable = (report) => (
        <Table basic="very">
            <Table.Body>
                <Table.Row>
                    <Table.Cell>Days of Ad Data {checkplan}</Table.Cell>
                    <Table.Cell>{hasData(report) ? dashboard[report].days : "N/A"}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Number of Files</Table.Cell>
                    <Table.Cell>{hasData(report) ? dashboard[report].files : "N/A"}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Last Synced</Table.Cell>
                    <Table.Cell>{hasData(report) && dashboard[report].newest_sync ? DateTime.fromISO(dashboard[report].newest_sync).toLocaleString() : "N/A"}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>First Synced</Table.Cell>
                    <Table.Cell>{hasData(report) && dashboard[report].oldest_sync ? DateTime.fromISO(dashboard[report].oldest_sync).toLocaleString() : "N/A"}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );

    const buildReportHeader = (report) => {
        return (
            <Grid>
                <Grid.Column verticalAlign="middle" textAlign="left" width={14}>
                    <h3>{dashboardStore.reports[report].name} Summary</h3>
                </Grid.Column>
                <Grid.Column width={2}>
                    {hasData(report) ? (!dashboard[report].synced ? (
                        <Image src="../../assets/images/icons/green-check.svg"/>
                    ) : <Image src="../../assets/images/icons/white-check.svg"/>) : (
                        <Image src="../../assets/images/icons/white-check.svg"/>
                    )}
                </Grid.Column>
            </Grid>
        );
    };

    // ## this is the export button and modal function ##

    // dummy data


    const [open, setOpen] = React.useState(false);
    const exportButton = () => {
        return (
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={<Button secondary >Export Data</Button>}
            >
                <Modal.Header>Export data</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>Requesting data export from Ad Astronaut.</p>

                        <p>
                            Once the data is ready, you will receive an email with instructions on how to download your
                            data. Please note that this can take a few hours and up to a day to complete.
                        </p>


                        {/*<Header>Select software partner</Header>*/}
                        {/*<Dropdown placeholder={software_partners} onChange={handleDropdown} search selection options={sofwarePartner}/>*/}

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="black" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                    <Button color="black" onClick={() => handleExport()}>
                        Export Data
                    </Button>

                </Modal.Actions>
            </Modal>
        );
    };

    const buildExportTable = (exports) => {
        return (
            <Table className="export-table">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date Requested</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Marketplace</Table.HeaderCell>
                        <Table.HeaderCell>Link</Table.HeaderCell>
                        <Table.HeaderCell>Expiration Date</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { buildExportTableRow(exports) }
                </Table.Body>

            </Table>
        );
    };

    const statusLabel = (status) => {
        if (status === "active") {
            return (
                <Label color="green" basic>Active</Label>
            );
        } else if (status === "expired") {
            return (
                <Label color="red" basic>Expired</Label>
            );
        } else if (status === "in_progress") {
            return (
                <Label color="orange" basic>In Progress</Label>
            );
        } else if (status === "pending") {
            return (
                <Label color="gray" basic>Pending</Label>
            );
        }
        return null;
    };

    const buildExportTableRow = exports => exports.map(data => (
        <Table.Row>
            <Table.Cell>{ DateTime.fromISO(data.request_date).toLocaleString() }</Table.Cell>
            <Table.Cell>{ statusLabel(data.status) }</Table.Cell>
            <Table.Cell>{ data.marketplace.country }</Table.Cell>
            <Table.Cell>
                { data.status === "active" ? (
                    <a href={data.signed_url} className="link">Download Data Export</a>
                ) : "N/A" }
            </Table.Cell>
            <Table.Cell>{ data.expiration_date ? DateTime.fromISO(data.expiration_date).toLocaleString() : null }</Table.Cell>
        </Table.Row>
    ));

    return (
        <section id="dashboard">
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=0"/>
            </Helmet>
=            <Container fluid className="dashboard-container">
                <Container className="dashboard-segment">
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <h2 className="page-header">Dashboard</h2>
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                {exportButton()}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {
                        dashboardStore.exports.length > 0 ? (
                            <Grid className="export-table">
                                <Grid.Row>
                                    <Grid.Column>
                                        <h3>Export History</h3>
                                        { buildExportTable(dashboardStore.exports)}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        ) : null
                    }
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Segment className="report-segment">
                                    {buildReportHeader("sb")}
                                    {buildTable("sb")}
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment className="report-segment">
                                    {buildReportHeader("sp")}
                                    {buildTable("sp")}
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Segment className="report-segment">
                                    {buildReportHeader("sd")}
                                    {buildTable("sd")}
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment className="report-segment">
                                    {buildReportHeader("sb_video")}
                                    {buildTable("sb_video")}
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Container>
        </section>
    );
};

export default observer(DashboardContainer);
