import {Container, Segment, Grid, Divider, Form, Button, Message} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";
import appStore from "../../../../../stores";

const handleUpdate = () => {
    appStore.userStore.resetName();

};

// change password success message function
const nameChangeAlert = () => {
    return appStore.userStore.profileSetting.name_update ? (
        <Message success style={{textAlign: "center", marginBottom: "3em"}}>
            Your name has been updated successfully.
        </Message>
    ) : null;
};

const handleChange = (field, val) => {
    appStore.userStore.newName[field] = val;
};

const {loaderStore} = appStore;

const ProfileContainer = () => (
    <React.Fragment>
        {
            appStore.userStore.newNameErrors.length > 0 ? (
                <Message
                    error
                    header="Please correct the following errors"
                    list={appStore.userStore.newNameErrors}
                />
            ) : null
        }
        {nameChangeAlert()}

        <Form>
            <Form.Field>
                <label>First Name</label>
                <input
                    placeholder="First Name"
                    onChange={(e) => handleChange("first_name", e.target.value)}
                    value={appStore.userStore.newName.first_name}
                />
            </Form.Field>
            <Form.Field>
                <label>Last Name</label>
                <input
                    placeholder="Last Name"
                    onChange={(e) => handleChange("last_name", e.target.value)}
                    value={appStore.userStore.newName.last_name}
                />
            </Form.Field>
            <Button
                loading={loaderStore.isLoaderActive("nameUpdateLoader")}
                fluid
                primary
                onClick={() => handleUpdate()}
            >
                Update
            </Button>

        </Form>
    </React.Fragment>
);

export default observer(ProfileContainer);
