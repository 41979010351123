import {observer} from "mobx-react-lite";
import * as React from "react";
import { routerStore } from "mr-router5";
import {Button, Segment, Grid} from "semantic-ui-react";

import appStore from "_/stores";

import "./Confirmation.less";


/**
 * @component
 * @description Component for syncing with Amazon APIs
 */
const Confirmation = () => {
    const { settingsStore, registrationStore } = appStore;

    return (
        <Segment id="confirmation">
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={3} />
                    <Grid.Column width={10}>
                        <div className="sub-header">
                            <h3>Confirmation</h3>
                            <p>
                                You’re all set! Ad Astronaut will now securely and automatically collect, clean, and store your ad data every day.
                            </p>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={3} />
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <Button
                            className="blue"
                            size="huge"
                            onClick={() => routerStore.router.navigate("dashboard")}
                        >
                            View Your Dashboard
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>


        </Segment>
    );
};

export default observer(Confirmation);
