import { action, computed, get, observable, toJS } from "mobx";
// import api from '../data-loader/api';
// import { routerStore } from 'mr-router5';

class PricingStore {
    @observable faqActiveIndex = 0;

    @action.bound
    setFaqActiveIndex(index) {
        this.faqActiveIndex = index;
    }

    faqs = [
        {
            key: "what-is-ad-astronaut",
            title: "What is Ad Astronaut?",
            content:
                "Ad Astronaut is the first data ownership platform for advertisers.\n" +
                "We automate your ad data collection, cleaning, and storage, so you\n" +
                "no longer have to be limited by a platform's API data access limit.\n" +
                "With Ad Astronaut, you can move your data as many times as you want \n" +
                "between in-house or external platforms, with zero data loss or friction.\n" +
                "Your ad data is expensive and valuable. So why don't you own your data?",
        },
        {
            key: "what-platforms",
            title: "What data sources do you support?",
            content:
                "Currently, we support Amazon Advertising, including Sponsored Products, \n" +
                "Sponsored Brands, Sponsored Brands Video, and Sponsored Display.\n" +
                "We plan to support Amazon Attribution and Amazon DSP.",
        },
        {
            key: "calculate-billing",
            title: "How do you calculate monthly ad spend?",
            content:
                "We calculate a running 60-day average of closed ad spend invoices and divide by two.\n" +
                "If your monthly ad spend exceeds your plan, we'll notify you of any required changes \n" +
                "prior to your billing date.\n",
        },
        {
            key: "refund-policy",
            title: "What is your cancellation policy?",
            content:
                "If you choose not to renew your monthly or annual subscription, your \n" +
                "subscription will expire at the end of the billing term and you will not \n" +
                "be charged again. We do not offer refunds.",
        },
        {
            key: "need-help",
            title: "I'm new to advertising. Can I get help?",
            content:
                "You can reach us anytime by emailing hi@adastronaut.com. We respond to most \n" +
                "inquiries same day. We're here to help!",
        },
    ];
}

export default PricingStore;
