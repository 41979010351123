import {Container, Segment, Grid, Divider, Form, Button, Header, Message} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";
import appStore from "../../../../../stores";

const handleUpdate = () => {
    appStore.userStore.updatePassword();

};
const handleChange = (field, val) => {
    appStore.userStore.newPassword[field] = val;
};

// change password success message function
const passwordChangeAlert = () => {
    return appStore.userStore.profileSetting.password_update ? (
        <Message success style={{textAlign: "center", marginBottom: "3em"}}>
            Your password has been updated successfully.
        </Message>
    ) : null;
};

const PasswordContainer = () => (
    <React.Fragment>
        {
            appStore.userStore.newPasswordErrors.length > 0 ? (
                <Message
                    error
                    header="Please correct the following errors"
                    list={appStore.userStore.newPasswordErrors}
                />
            ) : null
        }
        {passwordChangeAlert()}
        <Form>
            <Form.Field>
                <label>Current Password</label>
                <input
                    placeholder="Password" type="password"
                    onChange={(e) => handleChange("current_password", e.target.value)}
                />
            </Form.Field>
            <Form.Field>
                <label>New Password</label>
                <input
                    placeholder="Password" type="password"
                    onChange={(e) => handleChange("password", e.target.value)}
                />
            </Form.Field>
            <Form.Field>
                <label>Password Confirmation</label>
                <input
                    placeholder="Password" type="password"
                    onChange={(e) => handleChange("password_confirm", e.target.value)}
                />
            </Form.Field>
            <Button
                fluid
                primary
                onClick={() => handleUpdate()}
            >
                Update
            </Button>

        </Form>
    </React.Fragment>
);

export default observer(PasswordContainer);
