import {Button, Form, Input, Divider} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import * as React from "react";
import appStore from "_/stores";


/**
 * @component
 * @description Displays Amazon Seller account info; allows user to link Seller account to Ad Astronaut
 */
const AmazonSPContainer = () => {
    const { settingsStore } = appStore;

    return (
        <Form>
            <Form.Field>
                <label>Grant Ad Astronaut access</label>
                <a href={settingsStore.amazonSPUrl} referrerPolicy="no-referrer">
                    Grant Access
                </a>
            </Form.Field>
        </Form>
    );
};

export default observer(AmazonSPContainer);