import * as React from "react";
import { observer } from "mobx-react-lite";
import {Button, Container, Grid, Form, Segment, Input, Message, Divider} from "semantic-ui-react";
import { Helmet } from "react-helmet";

import appStore from "_/stores";
import "./login.less";


const LoginContainer = () => {
    const { userStore } = appStore;

    const login = async (e) => {
        e.preventDefault();
        await appStore.userStore.login();
        // .then(() => {
        //     console.log("logged in");
        // },
        // (error) => {
        //     console.log(error);
        // });
    };

    const handleChange = (field, val) => {
        userStore.credentials[field] = val;
    };

    const AccountSettingAlerts = () => {
        if (userStore.credentials.email_reset) {
            return (
                <Message success style={{textAlign: "center", marginBottom: "3em"}}>
                    Your email has been reset.
                </Message>
            );
        }
    };
    const displayLoginError = () => {
        return userStore.invalidLogin ? (
            <Message negative style={{textAlign: "center", marginBottom: "3em"}}>
                Unable to log in. Please check your email and password and try again.
            </Message>
        ) : null;
    };


    return (
        <section id="login">
            <Container fluid textAlign="center" className="login-container">
                <Container textAlign="center" className="section-title">
                    <div className="section-header">
                        <h1>Log in with your account.</h1>
                    </div>
                </Container>
                <Container className="container-gutters">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Segment className="login-segment">
                                    {AccountSettingAlerts()}
                                    { displayLoginError() }
                                    <Form onSubmit={(e) => login(e)}>
                                        <Form.Field>
                                            <label>Email</label>
                                            <Input
                                                autoFocus
                                                onChange={(e) => handleChange("email", e.target.value)}
                                                placeholder="yours@example.com"
                                                size="huge"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Password</label>
                                            <Input
                                                type="password"
                                                onChange={(e) => handleChange("password", e.target.value)}
                                                placeholder="your password"
                                                size="huge"
                                            />
                                            <div className="forgot-password">
                                                <a href="/passwordreset" className="link">Forgot your password?</a>
                                            </div>
                                        </Form.Field>
                                        <Button size="huge" type="submit" className="blue log-in">Log In </Button>
                                    </Form>
                                    <Divider />
                                    <div className="new-section">
                                        New to Ad Astronaut? <a className="signup-link" href="/subscribe">Create an account</a>
                                    </div>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Container>
        </section>
    );
};

export default observer(LoginContainer);
