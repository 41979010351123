import * as React from "react";

import ddLogo from "../../../assets/images/logos/aa_logo.png";

const Logo = () => (
    <React.Fragment >
        <img src={ddLogo} />
        <span style={{fontWeight: 900, marginLeft: "10px", fontSize: "1.4rem"}}>Ad Astronaut</span>
    </React.Fragment>
);

export default Logo;