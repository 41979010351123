import * as React from "react";
import PropTypes from "prop-types";
import {observer} from "mobx-react-lite";

import "./Steps.less";


/**
 * @component
 * @description Common component for 
 */
const Steps = ({activeStep}) => {

    return (
        <div className="steps">
            <ol>
                <li className={activeStep === 1 ? "current" : ""} />
                <li className={activeStep === 2 ? "current" : ""} />
                <li className={activeStep === 3 ? "current" : ""} />
            </ol>
        </div>
    );
};

Steps.propTypes = {
    activeStep: PropTypes.number,
};

Steps.defaultProps = {
    activeStep: false,
};

export default observer(Steps);
